// Models
import { IAnamneseAnswer } from 'storage/assessmentAnamneseAnswer/models'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'
import {
  EWorkoutDifficulty,
  EWorkoutGoal,
  TRoutineStatus,
  TWorkoutPDF,
} from 'models'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC, useState } from 'react'

// Libs
import { generatePath, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { pdf } from '@react-pdf/renderer'
import momentBR from 'lib/moment'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cn } from 'utils/helpers/classess'
import { triggerCreateAnamneseAnswers } from 'storage/assessmentAnamneseAnswer/duck'
import { triggerListWorkoutsModel } from 'storage/workoutModel/duck'
import { triggerToastSuccess } from 'storage/general/duck'
import {
  triggerDeleteWorkoutRoutine,
  triggerLoadListWorkoutRoutines,
  triggerLoadWorkoutRoutine,
} from 'storage/workoutRoutine/duck'
import { useModal } from 'hooks'
import { urls } from 'routes/paths'

// Components
import { Tabs, LinkButton } from 'heeds-ds'
import { ModalDelete, WorkoutPDF } from 'components'
import TabsInfoCard from 'components/TabsInfoCard'
import ProfileTabsLoader from './loader'

const dayInMs = 86400000

const TABS = {
  0: 'Rotinas',
  1: 'Anamnese',
  2: 'Comentários',
}

type Props = {
  athleteId: number
  routines?: IWorkoutRoutine[]
  comments?: string
  anamneses?: IAnamneseAnswer[]
  loading?: boolean
}

const ProfileTabs: FC<Props> = ({
  athleteId,
  comments,
  routines,
  anamneses,
  loading,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { openModal, isVisible } = useModal()

  const [activeTab, setActiveTab] = useState(TABS[0])
  const [selected, setSelected] = useState<number | undefined>()

  const handleRoutineOptions = (id: string) => {
    const options: TMenuDropdownOption[] = [
      {
        icon: 'eye',
        label: 'Ver rotina',
        onClick: () =>
          navigate(
            generatePath(urls.workoutRoutine, {
              routine_id: id,
              id: athleteId,
            }),
          ),
      },
      {
        icon: 'edit',
        label: 'Editar',
        onClick: () =>
          navigate(
            generatePath(urls.workoutRoutineEdit, {
              routine_id: id,
              id: athleteId,
            }),
          ),
      },
      {
        icon: 'pictureAsPdf',
        label: 'PDF da rotina',
        onClick: () => downloadPDFModel(id),
      },
      {
        icon: 'delete',
        label: 'Excluir',
        onClick: () => {
          setSelected(Number(id)), openModal('delete-routine-modal')
        },
        color: 'critical',
      },
    ]

    return options
  }

  const downloadPDFModel = (routineId: string) => {
    dispatch(
      triggerListWorkoutsModel({
        routine_pk: Number(routineId),
        successCallback: (models) => {
          dispatch(
            triggerLoadWorkoutRoutine({
              routine_pk: Number(routineId),
              successCallback: async (workoutRoutine) => {
                if (workoutRoutine) {
                  const pdfData: TWorkoutPDF = {
                    routine: workoutRoutine,
                    workouts: models?.results,
                  }
                  const blobPDF = await pdf(
                    <WorkoutPDF data={pdfData} />,
                  ).toBlob()
                  const element = document.createElement('a')
                  element.href = URL.createObjectURL(blobPDF)
                  element.download = `${workoutRoutine?.name || 'fileName'}.pdf`
                  document.body.appendChild(element)
                  element.click()
                  document.body.removeChild(element)
                }
              },
            }),
          )
        },
      }),
    )
  }

  const handleAnamnesesOptions = (anamneses: IAnamneseAnswer) => {
    const options: TMenuDropdownOption[] = [
      {
        icon: 'eye',
        label: 'Visualizar respostas',
        onClick: () =>
          navigate(
            generatePath(urls.athleteAssessmentAnamneseAnswer, {
              anamnese_id: anamneses.id,
              id: athleteId,
            }),
          ),
      },
      {
        icon: 'topic',
        label: 'Reenviar formulário',
        disabled: !anamneses.answer_date,
        onClick: () => {
          handleResendForm(anamneses)
        },
      },
    ]

    return options
  }

  const copyAnamneseAnswer = (anamnese: IAnamneseAnswer) =>
    dispatch(
      triggerCreateAnamneseAnswers({
        anamnesis_template_ids: [anamnese.template_id],
        athlete_id: anamnese.athlete,
        successCallback: () =>
          dispatch(
            triggerToastSuccess({
              message: 'Formulário reenviado.',
            }),
          ),
      }),
    )

  const handleResendForm = (anamnese: IAnamneseAnswer) => {
    if (!anamnese.answer_date) return

    const today = new Date()
    const answeredDate = new Date(anamnese.answer_date)
    const diffInMs = Math.abs(today.getTime() - answeredDate.getTime())
    const diffInDays = Math.ceil(diffInMs / dayInMs)

    if (diffInDays > 180) return copyAnamneseAnswer(anamnese)

    openModal('modal-not-resend')
  }

  const onRemoveRoutine = (routineId: number) => {
    dispatch(
      triggerDeleteWorkoutRoutine({
        routine_pk: routineId,
        successCallback: () => {
          const query = {
            athlete: String(athleteId),
            routine_status: 'current' as TRoutineStatus,
          }
          dispatch(triggerLoadListWorkoutRoutines(query))
        },
      }),
    )
    setSelected(undefined)
  }

  const tabOptions = [
    {
      tab: 'Rotinas',
      children: (
        <div
          className={cn('flex w-full flex-col gap-2 md:flex-row md:flex-wrap')}
        >
          {routines?.map((routine) => {
            return (
              <TabsInfoCard
                key={routine.id}
                title={routine.name}
                description={[
                  `${
                    routine.start_date
                      ? momentBR(routine.start_date).format('DD [de] MMM')
                      : ''
                  } ${routine.start_date && routine.end_date ? '-' : ''}${
                    routine.end_date
                      ? momentBR(routine.end_date).format('DD [de] MMM / YYYY')
                      : ''
                  }`,
                  EWorkoutGoal[routine.goal],
                  EWorkoutDifficulty[routine.difficulty],
                ]}
                options={handleRoutineOptions(String(routine.id))}
              />
            )
          })}
        </div>
      ),
      emptyText: 'Ainda não existe rotina adicionada.',
      isEmpty: !(routines && routines.length > 0),
      path: generatePath(urls.athleteRoutines, { id: athleteId }),
      emptyPath: generatePath(urls.workoutRoutineCreate, { id: athleteId }),
      trackName: 'athlete_profile_view_routines',
      emptyTackName: 'athlete_profile_create_new_routine',
      buttonLabel: 'Visualizar todas as rotinas',
      emptyButtonLabel: '+ Cadastrar rotina',
    },
    {
      tab: 'Anamnese',
      children: (
        <div
          className={cn('flex w-full flex-col gap-2 md:flex-row md:flex-wrap')}
        >
          {anamneses?.map((item) => {
            return (
              <TabsInfoCard
                key={item.id}
                title={item.title}
                description={[
                  `${
                    item.creation_date
                      ? momentBR(item.creation_date).format('DD [de] MMM')
                      : ''
                  }`,
                  item?.description as string,
                ]}
                options={handleAnamnesesOptions(item)}
              />
            )
          })}
        </div>
      ),
      emptyText: 'Ainda não existe Anamnese adicionada.',
      isEmpty: !(anamneses && anamneses.length > 0),
      path: generatePath(urls.athleteAssessmentAnamneseAnswers, {
        id: athleteId,
      }),
      emptyPath: generatePath(urls.libraryAnamneseCreate),
      trackName: 'athlete_profile_view_anamneses',
      emptyTackName: 'athlete_profile_create_new_anamneses',
      buttonLabel: 'Visualizar todas as avaliações',
      emptyButtonLabel: '+ Cadastrar anamnese',
    },
    {
      tab: 'Comentários',
      children: (
        <div className="w-full overflow-scroll break-words rounded-1 border border-border-input p-4 text-copy4">
          {comments}
        </div>
      ),
      emptyText: 'Ainda não existe comentário.',
      isEmpty: !comments,
      path: generatePath(urls.athleteComments, { id: athleteId }),
      emptyPath: generatePath(urls.athleteComments, { id: athleteId }),
      trackName: 'athlete_profile_view_comments',
      emptyTackName: 'athlete_profile_view_comments',
      buttonLabel: 'Ir para comentários',
      emptyButtonLabel: 'Ir para comentários',
    },
  ]

  return (
    <div className="relative flex flex-col overflow-y-hidden rounded-2 border border-border-input bg-surface">
      <div>
        <Tabs
          setActive={setActiveTab}
          tabs={Object.values(TABS)}
          active={activeTab}
        />
      </div>
      {tabOptions.map((option, index) => {
        const empty = option.isEmpty
        return (
          <div
            key={index}
            className={cn('hidden flex-col', {
              flex: activeTab === option.tab,
            })}
          >
            {loading ? (
              <ProfileTabsLoader />
            ) : (
              <div
                className={cn(
                  'flex px-4 pb-11 pt-6 md:h-[218px] md:overflow-y-scroll md:p-4',
                )}
              >
                {empty ? (
                  <p className="m-auto font-roboto text-copy3 text-text-subdued">
                    {option.emptyText}
                  </p>
                ) : (
                  option.children
                )}
              </div>
            )}
            <div className="flex h-[64px] w-full items-center justify-center rounded-b-2 border-t border-border-input bg-surface md:justify-end">
              <LinkButton
                to={empty ? option.emptyPath : option.path}
                size="xsmall"
                variation="borderless"
                track={buttonClickTracking}
                trackName={empty ? option.emptyTackName : option.trackName}
              >
                {empty ? option.emptyButtonLabel : option.buttonLabel}
              </LinkButton>
            </div>
          </div>
        )
      })}
      {isVisible === 'delete-routine-modal' && (
        <ModalDelete
          confirmTitle="Excluir"
          description="Tem certeza que deseja excluir esta rotina?"
          onConfirm={() => selected && onRemoveRoutine(selected)}
          title={`Excluir Rotina`}
        />
      )}
    </div>
  )
}

export default ProfileTabs
