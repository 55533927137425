// Models
import { IAuthState } from 'storage/auth/models'
import { IFinancialExtraPlan } from 'services/financial/@types'
import { IFinancialState } from 'storage/financial/models'
import IStore from 'lib/redux/models'

// React
import { FC, useEffect, useLayoutEffect, useState } from 'react'

// Libraries
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// Misc
import { prepareExtraPlansToDisplay } from 'filters/profile'
import { triggerLoadProfileExtraPlans } from 'storage/financial/duck'
import { urls } from 'routes/paths'

// Components
import * as Blocks from 'blocks/dashboard/profile/extraWorkouts'
import * as Styled from './styled'
import { Aligner, Loading } from 'heeds-ds'
import { ExtraPlanTag, FooterActionButton, NewContentBox } from 'components'

// Constants
const DESCRIPTION =
  'Espaço reservado para os treinos que você oferece para o seu aluno além do horário contratado. Podendo ser pago ou gratuito.'

const ProfileExtraWorkouts: FC = () => {
  const { extraPlans, loading } = useSelector<IStore, IFinancialState>(
    ({ financial }) => financial,
  )
  const { userData } = useSelector<IStore, IAuthState>(({ auth }) => auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [extraPlansState, setExtraPlans] = useState<
    IFinancialExtraPlan<string>[]
  >([])
  const [extraWorkout, setNewExtraWorkout] = useState(false)
  const [onEdit, setOnEdit] = useState<number | undefined>()

  const formMethods = useForm()
  const { handleSubmit } = formMethods

  const onSubmit = () => {
    navigate(urls.profileGeneral)
  }

  const handleAddExtraWorkout = () => {
    setOnEdit(undefined)
    setNewExtraWorkout(true)
  }

  const onEditCancel = () => {
    setNewExtraWorkout(false)
  }

  useEffect(() => {
    if (extraPlans) setExtraPlans(prepareExtraPlansToDisplay(extraPlans || []))
  }, [extraPlans])

  useLayoutEffect(() => {
    if (!extraPlans) {
      userData?.profileId &&
        dispatch(
          triggerLoadProfileExtraPlans({ profile_pk: userData.profileId }),
        )
    }
  }, [dispatch, extraPlans, userData])

  if (loading) {
    return <Loading active />
  }

  return (
    <div>
      <FormProvider {...formMethods}>
        <Styled.Form onSubmit={handleSubmit(onSubmit)} id="extraPlansForm" />
      </FormProvider>

      <NewContentBox title="Treinos Extras" description={DESCRIPTION}>
        <Styled.FormContent>
          {!extraWorkout && (
            <Aligner flex="column" gap="1.6rem">
              <Blocks.ProfileNewExtraWorkoutsTag
                onClick={handleAddExtraWorkout}
              />

              <Aligner flex="column" gap="1.6rem">
                {extraPlansState.map((item, index) => (
                  <ExtraPlanTag
                    extraWorkout={item}
                    key={item.name}
                    onCancel={() => setOnEdit(undefined)}
                    onEdit={onEdit === index}
                    setOnEdit={() => setOnEdit(index)}
                  />
                ))}
              </Aligner>
            </Aligner>
          )}

          {extraWorkout && (
            <Blocks.ProfileExtraWorkoutsForm
              list={extraPlansState}
              onCancel={onEditCancel}
            />
          )}
        </Styled.FormContent>
      </NewContentBox>

      <FooterActionButton
        disabled={extraWorkout || onEdit !== undefined}
        form="extraPlansForm"
        loading={loading}
        primaryTitleButton="Salvar"
      />
    </div>
  )
}

export default ProfileExtraWorkouts
