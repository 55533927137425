// Models
import { EPlanModelToPortuguese, EPlanPeriodicityToPortuguese } from 'models'
import { IAthletePlan } from 'services/athletePlans/@types'

// React
import React, { FC, useContext } from 'react'

// Libraries
import { BRL } from 'lib/currency'
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cn } from 'utils/helpers/classess'
import { dateFormatEnglishToPortuguese } from 'utils/functions'

// Components
import { Button, Icon } from 'heeds-ds'

type SubscriptionCardProps = {
  subscription?: IAthletePlan
  onClick?: () => void
  className?: string
  empty?: boolean
}

const SubscriptionCard: FC<SubscriptionCardProps> = ({
  subscription,
  onClick,
  className,
  empty,
}) => {
  const theme = useContext(ThemeContext)

  const disabled = !empty && subscription?.status !== 'active'
  const planInfo = [
    {
      label: 'Modelo do plano',
      value:
        EPlanModelToPortuguese?.[
          subscription?.plan?.plan_model as keyof typeof EPlanModelToPortuguese
        ] || '-',
    },
    {
      label: 'Tipo do plano',
      value:
        EPlanPeriodicityToPortuguese?.[
          subscription?.plan
            ?.plan_periodicity as keyof typeof EPlanPeriodicityToPortuguese
        ] || '-',
    },
    {
      label: 'Data início',
      value: dateFormatEnglishToPortuguese(subscription?.start_date) || '-',
    },
    {
      label: 'Data fim',
      value: dateFormatEnglishToPortuguese(subscription?.end_date) || '-',
    },
    {
      label: 'Pagamento',
      value: subscription?.spread ? 'Parcelado' : 'A vista',
    },
    {
      label: 'Renovação automática',
      value: subscription?.auto_renew ? 'Sim' : 'Não',
    },
  ]

  return (
    <div
      className={cn(
        'w-full rounded-4 border border-border-input pt-6',
        {
          'text-text-disabled cursor-not-allowed select-none': disabled,
        },
        className,
      )}
    >
      {empty ? (
        <div className="px-6 py-[117px]">
          <p className="text-center text-copy4">
            Não possui plano ativo no momento. Clique no botão abaixo para criar
            um novo.
          </p>
        </div>
      ) : (
        <div>
          <div className="mx-6 flex justify-between">
            <p className="text-copy3 font-semibold">
              {disabled ? 'Histórico' : 'Plano atual'}
            </p>
            <div className="flex flex-col items-end gap-2">
              <p className="text-copy3 font-normal text-text-subdued">
                Valor total
              </p>
              <p className="text-copy1 font-semibold">
                {BRL(subscription?.total_price).format()}
              </p>
            </div>
          </div>
          <div className="mx-6 mb-8 mt-6">
            <div className="grid grid-cols-2 gap-x-6 gap-y-4">
              {planInfo.map((item, index) => {
                return (
                  <div key={index}>
                    <p className="mb-1 text-copy4 text-text-subdued">
                      {item.label}
                    </p>
                    <p className="text-copy4 font-semibold">{item.value}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )}

      {(!disabled || empty) && (
        <div
          className={cn(
            'flex justify-end border-t border-border-input px-4 py-3',
            { 'justify-center': empty },
          )}
        >
          <Button
            onClick={onClick}
            variation="borderless"
            size="xsmall"
            track={buttonClickTracking}
            trackName="subscription_card_on_click_button"
          >
            {!empty && (
              <Icon iconName="edit" color={theme.colors.interactive.default} />
            )}
            {empty ? 'Adicionar plano' : 'Mudar plano'}
          </Button>
        </div>
      )}
    </div>
  )
}

export default SubscriptionCard
