// Components
import { IconsPath, IconsType } from './values'

type Props = {
  iconName: IconsType
  size?: number
  viewBox?: number
  className?: string
}

const StylizedIcons = ({
  iconName,
  size = 42,
  viewBox = 42,
  className,
}: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${viewBox} ${viewBox}`}
      fill="none"
    >
      <g>
        {IconsPath[iconName].iconPaths.map((item, index) => {
          return (
            <path
              key={index}
              d={item}
              fill={IconsPath[iconName].colors[index]}
              className={className}
            />
          )
        })}
      </g>
    </svg>
  )
}

export default StylizedIcons
