// React
import { useContext, useRef } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { cn } from '../../utils/classes'

// Misc
import { IconsType } from '../UI/Icons/values'

// Components
import Icon from '../UI/Icons'

export type Action = {
  icon?: IconsType
  color?: string
  iconSize?: number
  onClick: () => void
}

type Props = {
  actions?: Action[]
  active?: boolean
  children?: React.ReactNode
  maxHeight?: string
  shadow?: boolean
  className?: string
}

const Swipe = ({
  actions,
  active,
  children,
  maxHeight,
  shadow,
  className,
}: Props) => {
  const refName = useRef<HTMLDivElement>(null)
  const theme = useContext(ThemeContext)

  const resetScrollPosition = () => {
    refName.current?.scrollTo?.({
      left: 0,
      behavior: 'smooth',
    })
  }

  const setFocus = () => {
    refName.current?.focus()
  }

  return (
    <div
      data-testid="swipe"
      onBlur={resetScrollPosition}
      onMouseLeave={resetScrollPosition}
      onTouchStart={setFocus}
      ref={refName}
      tabIndex={-1}
      className={cn(
        'no-scrollbar flex w-full snap-x snap-mandatory gap-2 overflow-x-hidden outline-none',
        {
          'overflow-x-scroll': active,
          'shadow-[0px_4px_20px_rgba(99,90,108,0.1)]': shadow,
        },
        className,
      )}
    >
      <div className="min-w-full snap-start">{children}</div>
      {actions && actions.length > 0 && (
        <div
          data-testid="actions-container"
          className={cn('hidden snap-start', { flex: active }, maxHeight)}
        >
          {actions.map((item, index) => {
            const { color, icon, iconSize, onClick } = item
            return (
              <div
                data-testid={icon}
                key={(icon || 'moreVertical') + index}
                onClick={() => {
                  onClick()
                  resetScrollPosition()
                }}
                className={cn(
                  'flex h-full w-[72px] items-center justify-center bg-interactive first:rounded-l-[8px]',
                  color,
                )}
              >
                <Icon
                  color={theme.colors.icon.onPrimary}
                  iconName={icon || 'moreVertical'}
                  size={iconSize || 24}
                />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default Swipe
