// Libraries
import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
`

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.surface.default};
  border-radius: 15px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    min-height: calc(100vh - 28.5rem);
  }
`

export const InputsGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: grid;
    width: 75%;
    align-items: unset;
    justify-content: unset;
  }
`
