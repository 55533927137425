// React
import { FC } from 'react'

// Components
import * as Styled from './styled'
import { Skeleton } from 'heeds-ds'

const LoaderExerciseItem: FC = () => {
  return (
    <Styled.Container>
      <div className="flex gap-4">
        <Skeleton className="h-10 w-10 min-w-10 rounded-[6px]" />

        <div className="flex flex-col justify-center gap-1">
          <Skeleton className="h-4 w-40" />

          <Skeleton className="h-[14px] w-20" />
        </div>
      </div>

      <Skeleton className="h-9 w-9" />
    </Styled.Container>
  )
}

export default LoaderExerciseItem
