// React
import { FC, useContext } from 'react'

import { ThemeContext } from 'styled-components'

import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import * as Styled from './styled'
import { Skeleton } from 'heeds-ds'

const Loader: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  return (
    <Styled.LoadContainer data-testid="row">
      {isDesktop ? (
        <>
          <div className="mx-6 my-4 flex gap-4">
            <Skeleton className="h-10 w-10 rounded-5" />
            <Skeleton className="h-9 w-72" />
          </div>

          <Skeleton className="mx-6 my-4 h-9 w-20" />
          <Skeleton className="mx-6 my-4 h-9 w-[60px]" />
          <Skeleton className="mx-6 my-4 h-9 w-[60px]" />
          <Skeleton className="mx-6 my-4 h-9 w-40" />
        </>
      ) : (
        <>
          <Skeleton className="h-10 w-10 shrink-0 rounded-5" />
          <div className="flex flex-col gap-2">
            <Skeleton className="h-4 w-64" />
            <Skeleton className="h-[14px] w-40" />
          </div>
        </>
      )}
    </Styled.LoadContainer>
  )
}

export default Loader
