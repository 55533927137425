// Models
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import { IExerciseData } from 'storage/exercise/models'
import { IFormInputs, IModelInput } from 'models'
import { IWorkoutModelState } from 'storage/workoutModel/models'
import { IWorkoutRoutineState } from 'storage/workoutRoutine/models'
import { TMenuDropdownOption } from 'heeds-ds/src/models'
import IStore from 'lib/redux/models'

// React
import { FC, Fragment, useCallback, useContext, useMemo, useState } from 'react'

// Libraries
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { getModelName, getWorkoutRoutineFilter, uid } from 'utils/functions'
import { prepareModelsToPayload } from 'filters/workouts'
import {
  success as successModel,
  triggerCreateWorkoutModelTemplate,
  triggerCreateWorkoutsModel,
  triggerDeleteWorkoutsModel,
  triggerListWorkoutsModel,
  triggerUpdateWorkoutsModel,
} from 'storage/workoutModel/duck'
import { triggerToastSuccess } from 'storage/general/duck'
import { urls } from 'routes/paths'
import { useModal } from 'hooks'
import { workoutModelsSchema } from 'schemas'
import useMediaQuery from 'hooks/useMediaQuery'

// Components
import * as Styled from './styled'
import { Aligner, Button, FormButton, Icon, InputText, Loading } from 'heeds-ds'
import {
  Modal,
  ModalConfirmation,
  ModalDelete,
  ModalExerciseImage,
  ModalRenameWorkoutModel,
  RoutineInfo,
  WorkoutModelReviewCard,
} from 'components'

// Assets

const WorkoutReview: FC = () => {
  const { id = '', routine_id = '' } = useParams()
  const { loading } = useSelector<IStore, IAthleteInfoState>(
    (state) => state.athleteInfo,
  )
  const { models, workoutModels } = useSelector<IStore, IWorkoutModelState>(
    (state) => state.workoutModel,
  )
  const { workoutRoutine } = useSelector<IStore, IWorkoutRoutineState>(
    (state) => state.workoutRoutine,
  )
  const { breakpoints, colors } = useContext(ThemeContext)
  const { closeModal, openModal, isVisible } = useModal()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)
  const navigate = useNavigate()

  const [selectedModel, setSelectedModel] = useState('')
  const [selectedExercise, setSelectedExercise] = useState<IExerciseData>()

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(workoutModelsSchema),
    delayError: 800,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { models: workoutModels || [] },
  })
  const { control } = methods
  const { append, fields } = useFieldArray({
    control,
    name: `models`,
  })

  const modalMethods = useForm({
    defaultValues: { name: '' },
  })

  const handleAddToLibrary = useCallback(
    (modelId: string) => {
      setSelectedModel(modelId)
      openModal('add-workout-model-to-library')
    },
    [openModal],
  )

  const addWorkoutModelToLibrary = ({ name }: { name: string }) => {
    if (workoutModels) {
      const modelIndex = workoutModels.findIndex(
        ({ id }) => id === selectedModel,
      )
      const { workout_set } = workoutModels[modelIndex]
      const field = fields[modelIndex]
      const fieldCopy = {
        ...field,
        name,
      }

      const updatedModels = [{ ...fieldCopy, workout_set: { ...workout_set } }]

      const updatedData = prepareModelsToPayload(updatedModels)[0]

      const successCallback = () => {
        dispatch(
          triggerToastSuccess({
            customTitle: 'Seu treino foi salvo na biblioteca!',
            message:
              'Para visualizar e editar seu treino, acesse a biblioteca pelo menu',
          }),
        )
      }

      dispatch(
        triggerCreateWorkoutModelTemplate({
          ...updatedData,
          successCallback,
        }),
      )
    }

    closeModal()
  }

  const handleDeleteWorkoutModel = useCallback(
    (modelId: string) => {
      setSelectedModel(modelId)
      openModal('delete-exercise-modal')
    },
    [openModal],
  )

  const newWorkoutModel = useCallback(() => {
    if (workoutModels) {
      const newIndex = workoutModels.length
      const newModel = {
        id: 'NEW' + uid(),
        name: getModelName(newIndex),
        workout_set: {},
        workout_routine: Number(routine_id),
      }
      const updatedModels = [...workoutModels, newModel]

      append(newModel)

      dispatch(successModel({ workoutModels: updatedModels }))

      navigate({
        pathname: generatePath(urls.workoutRoutineModels, { id, routine_id }),
        search: `treino-selecionado=${newIndex}`,
      })
    }
  }, [append, dispatch, id, navigate, routine_id, workoutModels])

  const handleDelete = () => {
    if (workoutModels) {
      const updatedModels = workoutModels.filter(
        (stateModel) => stateModel.id !== selectedModel,
      )

      dispatch(successModel({ workoutModels: updatedModels }))
    }
    closeModal()
  }

  const openExerciseGifModal = useCallback(
    (exercise: IExerciseData) => {
      setSelectedExercise(exercise)
      openModal('mobile-exercise-gif-modal')
    },
    [openModal],
  )

  const duplicateWorkoutModel = useCallback(
    (modelId: string) => {
      if (workoutModels) {
        const modelIndex = workoutModels.findIndex(
          (model) => model.id === modelId,
        )
        const { name, workout_set } = workoutModels[modelIndex]
        const field = fields[modelIndex]
        const fieldCopy = {
          ...field,
          id: 'NEW' + uid(),
          name: `Cópia de ${name}`,
        }

        append(fieldCopy)

        const updatedModels = [
          ...workoutModels,
          { ...fieldCopy, workout_set: { ...workout_set } },
        ]

        dispatch(successModel({ workoutModels: updatedModels }))
      }
    },
    [append, dispatch, fields, workoutModels],
  )

  const updateWorkoutModel = (modelId: string, updatedModel: IModelInput) => {
    if (workoutModels) {
      const index = workoutModels.findIndex((model) => model.id === modelId)
      const updatedModels = [...workoutModels]
      updatedModels[index] = { ...updatedModel }

      dispatch(successModel({ workoutModels: updatedModels }))
    }
  }

  const onEditModel = useCallback(
    (modelIndex: number) => {
      navigate({
        pathname: generatePath(urls.workoutRoutineModels, { id, routine_id }),
        search: `treino-selecionado=${modelIndex}`,
      })
    },
    [id, navigate, routine_id],
  )

  const renameWorkoutModel = useCallback(
    (modelId: string) => {
      setSelectedModel(modelId)
      openModal('rename-workout-model')
    },
    [openModal],
  )

  const onSubmit = (dataForm: IFormInputs) => {
    if (workoutModels && workoutRoutine) {
      let changed = false
      const updatedData = prepareModelsToPayload(dataForm.models)

      updatedData.forEach((workoutModel) => {
        if (workoutModel.id) {
          changed = true
          dispatch(
            triggerUpdateWorkoutsModel({
              ...workoutModel,
              model_pk: workoutModel.id,
              workout_routine: workoutRoutine.id,
            }),
          )
        } else {
          dispatch(
            triggerCreateWorkoutsModel({
              ...workoutModel,
              routine_pk: workoutRoutine.id,
            }),
          )
        }
      })

      models?.results.forEach((model) => {
        const index = workoutModels.findIndex(
          (stateModel) => stateModel.id === `${model.id}`,
        )
        if (index === -1) {
          dispatch(triggerDeleteWorkoutsModel({ model_pk: model.id }))
        }
      })

      dispatch(triggerListWorkoutsModel({ routine_pk: workoutRoutine.id }))

      const filter = getWorkoutRoutineFilter(workoutRoutine)

      // TODO: rever esse fluxo, e error handling
      window.postMessage('CREATION_SUCCESS')
      if (changed) {
        navigate(generatePath(urls.workoutRoutine, { id, routine_id }))
      } else {
        navigate({
          pathname: generatePath(urls.athleteRoutines, { id }),
          search: `?routine_status=${filter}`,
        })
      }
    }
  }

  const handleCancelClick = useCallback(() => {
    navigate(generatePath(urls.athleteRoutines, { id }), {
      replace: true,
    })
  }, [navigate, id])

  const renderWorkoutModels = useMemo(
    () =>
      workoutModels?.map((model, modelIndex) => {
        const options: TMenuDropdownOption[] = [
          {
            label: 'Editar treino',
            onClick: () => onEditModel(modelIndex),
            icon: 'edit',
          },
          {
            label: 'Renomear',
            onClick: () => renameWorkoutModel(model.id),
            icon: 'edit',
          },
          {
            label: 'Duplicar',
            onClick: () => duplicateWorkoutModel(model.id),
            icon: 'contentCopy',
          },
          {
            icon: 'book',
            label: 'Adicionar a biblioteca',
            onClick: () => handleAddToLibrary(model.id),
          },
          {
            label: 'Excluir',
            onClick: () => handleDeleteWorkoutModel(model.id),
            icon: 'delete',
            color: 'critical',
          },
        ]

        return (
          <WorkoutModelReviewCard
            key={model.id}
            openGifModal={openExerciseGifModal}
            options={options}
            shadow
            workoutModel={model}
          />
        )
      }),
    [
      duplicateWorkoutModel,
      handleAddToLibrary,
      handleDeleteWorkoutModel,
      onEditModel,
      openExerciseGifModal,
      renameWorkoutModel,
      workoutModels,
    ],
  )

  const renderAddButton = useMemo(
    () => (
      <Button
        size="small"
        track={buttonClickTracking}
        trackName="add_new_workout_model"
        variation="borderless"
        onClick={newWorkoutModel}
        className="mx-auto my-0"
      >
        <Icon iconName="add" color={colors.interactive.default} />
        Adicionar novo treino
      </Button>
    ),
    [colors.interactive.default, newWorkoutModel],
  )

  if (loading) {
    return <Loading active />
  }

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Styled.Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Styled.Content>
            {isDesktop && <RoutineInfo workoutRoutine={workoutRoutine} />}

            <Styled.RightContent>
              {!isDesktop && renderAddButton}

              {renderWorkoutModels}

              {isDesktop && renderAddButton}
            </Styled.RightContent>
          </Styled.Content>

          <Styled.ButtonContainer>
            <Styled.CancelButton
              cancel
              margin="0"
              onClick={handleCancelClick}
              size="xsmall"
              track={buttonClickTracking}
              trackName="navigate_to_athlete_routines"
              variation="outlined"
            >
              Cancelar
            </Styled.CancelButton>

            <FormButton
              size="xsmall"
              track={buttonClickTracking}
              trackName="create_or_update_models"
            >
              Salvar rotina
            </FormButton>
          </Styled.ButtonContainer>
        </Styled.Form>
      </FormProvider>

      {isVisible === 'mobile-exercise-gif-modal' && (
        <ModalExerciseImage
          exercise={selectedExercise}
          handleClose={closeModal}
        />
      )}

      {isVisible === 'delete-exercise-modal' && (
        <ModalDelete
          cancelTitle="Continuar editando"
          confirmTitle="Sim, quero excluir"
          description="Você está excluindo um treino e perderá todos os exercícios adicionados nele. Deseja prosseguir?"
          onConfirm={handleDelete}
          title="Excluir Treino"
        />
      )}

      {isVisible === 'exercise-go-back-confirmation' && (
        <ModalConfirmation
          confirmTitle="Confirmar"
          description="Tem certeza que deseja voltar? Voltando agora você vai perder as alterações realizadas."
          onConfirm={() => navigate(-1)}
          title="Confirmação"
        />
      )}

      {isVisible === 'rename-workout-model' && (
        <ModalRenameWorkoutModel
          workoutModel={workoutModels?.find(
            (model) => selectedModel === model.id,
          )}
          updateWorkoutModel={updateWorkoutModel}
        />
      )}

      {isVisible === 'add-workout-model-to-library' && (
        <Modal
          title="Nome do modelo"
          description="Você pode escolher o nome que esse treino aparecerá na biblioteca."
          primaryButton={{
            name: 'Salvar modelo',
            onClick: modalMethods.handleSubmit(addWorkoutModelToLibrary),
          }}
          secondaryButton={{
            name: 'Cancelar',
          }}
          maxWidth="556px"
        >
          <FormProvider {...modalMethods}>
            <Aligner padding="16px 24px">
              <InputText
                displayError={false}
                label="Nome do treino"
                name="name"
                placeholder="Digite aqui o nome"
              />
            </Aligner>
          </FormProvider>
        </Modal>
      )}
    </Fragment>
  )
}

export default WorkoutReview
