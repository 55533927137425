// Models
import { IExerciseData } from 'storage/exercise/models'
import {
  EWorkoutDifficulty,
  EWorkoutGoal,
  IFormInputs,
  IModelInput,
} from 'models'
import { IWorkoutRoutineFormData } from '../LibraryWorkoutRoutineModel'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC, useCallback, useContext, useMemo, useState } from 'react'
import { FormProvider, useForm, useFieldArray } from 'react-hook-form'

// Libraries
import { ThemeContext } from 'styled-components'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { prepareModelsToPayload } from 'filters/workouts'
import {
  success as successModel,
  triggerCreateWorkoutsModel,
  triggerDeleteWorkoutsModel,
  triggerUpdateWorkoutsModel,
} from 'storage/workoutModel/duck'
import { getModelName, uid } from 'utils/functions'
import { triggerPartialUpdateWorkoutRoutineTemplate } from 'storage/workoutRoutine/duck'
import { urls } from 'routes/paths'
import { useMediaQuery, useModal } from 'hooks'
import {
  workoutModelSelector,
  workoutRoutineSelector,
} from 'utils/helpers/redux'
import { workoutModelsSchema, workoutRoutineSchema } from 'schemas'

// Components
import * as Styled from './styled'
import { Button, Icon, IconButton, InputText, Loading } from 'heeds-ds'
import {
  ModalDelete,
  ModalExerciseImage,
  ModalRenameWorkoutModel,
  RoutineInfo,
  WorkoutModelReviewCard,
} from 'components'
import { triggerToastSuccess } from 'storage/general/duck'

const LibraryWorkoutRoutineModelReview: FC = () => {
  const { id = '' } = useParams()
  const { models, workoutModels } = useSelector(workoutModelSelector)
  const { loading, workoutRoutineTemplate } = useSelector(
    workoutRoutineSelector,
  )
  const { breakpoints, colors } = useContext(ThemeContext)
  const { closeModal, openModal, isVisible } = useModal()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)
  const navigate = useNavigate()

  const [selectedExercise, setSelectedExercise] = useState<IExerciseData>()
  const [selectedModel, setSelectedModel] = useState('')

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(workoutModelsSchema),
    delayError: 800,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { models: workoutModels || [] },
  })
  const { control } = methods
  const { append, fields } = useFieldArray({
    control,
    name: `models`,
  })

  const routineMethods = useForm<IWorkoutRoutineFormData>({
    defaultValues: workoutRoutineTemplate ? workoutRoutineTemplate : {},
    resolver: yupResolver(workoutRoutineSchema),
    delayError: 800,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const openExerciseGifModal = useCallback(
    (exercise: IExerciseData) => {
      setSelectedExercise(exercise)
      openModal('mobile-exercise-gif-modal')
    },
    [openModal],
  )

  const handleGoBack = () => navigate(-1)

  const handleCancelClick = useCallback(() => {
    navigate(urls.libraryRoutinesTemplates, {
      replace: true,
    })
  }, [navigate])

  const duplicateWorkoutModel = useCallback(
    (modelId: string) => {
      if (workoutModels) {
        const modelIndex = workoutModels.findIndex(
          (model) => model.id === modelId,
        )
        const { name, workout_set } = workoutModels[modelIndex]
        const field = fields[modelIndex]
        const fieldCopy = {
          ...field,
          id: 'NEW' + uid(),
          name: `Cópia de ${name}`,
        }

        append(fieldCopy)

        const updatedModels = [
          ...workoutModels,
          { ...fieldCopy, workout_set: { ...workout_set } },
        ]

        dispatch(successModel({ workoutModels: updatedModels }))
      }
    },
    [append, dispatch, fields, workoutModels],
  )

  const updateWorkoutModel = (modelId: string, updatedModel: IModelInput) => {
    if (workoutModels) {
      const index = workoutModels.findIndex((model) => model.id === modelId)
      const updatedModels = [...workoutModels]
      updatedModels[index] = { ...updatedModel }

      dispatch(successModel({ workoutModels: updatedModels }))
    }
  }

  const onEditModel = useCallback(
    (modelIndex: number) => {
      navigate({
        pathname: generatePath(urls.libraryWorkoutRoutineModels, { id }),
        search: `treino-selecionado=${modelIndex}`,
      })
    },
    [id, navigate],
  )

  const renameWorkoutModel = useCallback(
    (modelId: string) => {
      setSelectedModel(modelId)
      openModal('rename-workout-model')
    },
    [openModal],
  )

  const handleDeleteWorkoutModel = useCallback(
    (modelId: string) => {
      setSelectedModel(modelId)
      openModal('delete-exercise-modal')
    },
    [openModal],
  )

  const handleDelete = () => {
    if (workoutModels) {
      const updatedModels = workoutModels.filter(
        (stateModel) => stateModel.id !== selectedModel,
      )

      dispatch(successModel({ workoutModels: updatedModels }))
    }
    closeModal()
  }

  const newWorkoutModel = useCallback(() => {
    if (workoutModels) {
      const newIndex = workoutModels.length
      const newModel = {
        id: 'NEW' + uid(),
        name: getModelName(newIndex),
        workout_set: {},
        workout_routine: Number(id),
      }
      const updatedModels = [...workoutModels, newModel]

      append(newModel)

      dispatch(successModel({ workoutModels: updatedModels }))

      navigate({
        pathname: generatePath(urls.libraryWorkoutRoutineModels, { id }),
        search: `treino-selecionado=${newIndex}`,
      })
    }
  }, [append, dispatch, id, navigate, workoutModels])

  const onSubmitRoutine = (routineDataForm: IWorkoutRoutineFormData) => {
    id &&
      dispatch(
        triggerPartialUpdateWorkoutRoutineTemplate({
          routine_pk: Number(id),
          name: routineDataForm.name,
        }),
      )
  }

  const onSubmit = (dataForm: IFormInputs) => {
    if (workoutModels && workoutRoutineTemplate) {
      const updatedData = prepareModelsToPayload(dataForm.models)
      let changes = false

      updatedData.forEach((workoutModel) => {
        if (workoutModel.id) {
          changes = true
          dispatch(
            triggerUpdateWorkoutsModel({
              ...workoutModel,
              model_pk: workoutModel.id,
              workout_routine: workoutRoutineTemplate.id,
            }),
          )
        } else {
          dispatch(
            triggerCreateWorkoutsModel({
              ...workoutModel,
              routine_pk: workoutRoutineTemplate.id,
            }),
          )
        }
      })

      models?.results.forEach((model) => {
        const index = workoutModels.findIndex(
          (stateModel) => stateModel.id === `${model.id}`,
        )
        if (index === -1) {
          dispatch(triggerDeleteWorkoutsModel({ model_pk: model.id }))
        }
      })

      dispatch(
        triggerToastSuccess({
          customTitle: `Modelo ${
            changes ? 'atualizado' : 'criado'
          } com sucesso!`,
        }),
      )

      navigate(urls.libraryRoutinesTemplates, { replace: true })
    }
  }

  const addButton = useMemo(() => {
    return (
      <Button variation="borderless" size="small" onClick={newWorkoutModel}>
        <Icon iconName="add" color={colors.interactive.default} />
        Adicionar treino
      </Button>
    )
  }, [colors, newWorkoutModel])

  const renderWorkoutModels = useMemo(
    () =>
      workoutModels?.map((model, modelIndex) => {
        const options: TMenuDropdownOption[] = [
          {
            label: 'Editar treino',
            onClick: () => onEditModel(modelIndex),
            icon: 'edit',
          },
          {
            label: 'Renomear',
            onClick: () => renameWorkoutModel(model.id),
            icon: 'edit',
          },
          {
            label: 'Duplicar',
            onClick: () => duplicateWorkoutModel(model.id),
            icon: 'contentCopy',
          },
          {
            label: 'Excluir',
            onClick: () => handleDeleteWorkoutModel(model.id),
            icon: 'delete',
            color: 'critical',
          },
        ]

        return (
          <WorkoutModelReviewCard
            key={model.id}
            openGifModal={openExerciseGifModal}
            options={options}
            startsExpanded
            workoutModel={model}
          />
        )
      }),
    [
      duplicateWorkoutModel,
      handleDeleteWorkoutModel,
      onEditModel,
      openExerciseGifModal,
      renameWorkoutModel,
      workoutModels,
    ],
  )

  if (!workoutRoutineTemplate && loading) {
    return <Loading active />
  }

  return (
    <Styled.Container>
      <Styled.ContainerHeader>
        {isDesktop ? (
          <>
            <IconButton
              size="large"
              iconName="arrowBack"
              onClick={handleGoBack}
            />
            <Styled.Header>
              <Styled.ModelNameLabel>Nome do modelo: </Styled.ModelNameLabel>
              <FormProvider {...routineMethods}>
                <InputText
                  name="name"
                  placeholder="Digite o nome do modelo de rotina"
                  scale="small"
                  displayError={false}
                />
              </FormProvider>
            </Styled.Header>
          </>
        ) : (
          <>
            <div className="flex min-h-[178px] w-full flex-col gap-2 rounded-2 border border-border-input bg-surface px-4 py-6">
              <FormProvider {...routineMethods}>
                <InputText
                  name="name"
                  scale="small"
                  label="Nome do modelo"
                  placeholder="Digite o nome do modelo da rotina"
                />
              </FormProvider>

              <div className="grid grid-cols-2 gap-2">
                <div className="flex flex-col gap-1">
                  <p className="text-copy4 font-semibold">Objetivo</p>
                  <p className="text-copy4">
                    {workoutRoutineTemplate?.goal
                      ? EWorkoutGoal[workoutRoutineTemplate.goal]
                      : null}
                  </p>
                </div>

                <div className="flex flex-col gap-1">
                  <p className="text-copy4 font-semibold">
                    Nível de dificuldade
                  </p>
                  <p className="text-copy4">
                    {workoutRoutineTemplate?.difficulty
                      ? EWorkoutDifficulty[workoutRoutineTemplate.difficulty]
                      : null}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </Styled.ContainerHeader>

      {!isDesktop && addButton}

      <Styled.Content>
        <div className="flex flex-1 gap-4 overflow-y-auto">
          {isDesktop && (
            <RoutineInfo isATemplate workoutRoutine={workoutRoutineTemplate} />
          )}

          <FormProvider {...methods}>
            <Styled.Form onSubmit={methods.handleSubmit(onSubmit)}>
              {renderWorkoutModels}

              {isDesktop && addButton}
            </Styled.Form>
          </FormProvider>
        </div>

        <Styled.ButtonContainer>
          <Styled.CancelButton
            onClick={handleCancelClick}
            size={isDesktop ? 'xsmall' : 'small'}
            track={buttonClickTracking}
            trackName="navigate_to_athlete_routines"
          >
            Cancelar
          </Styled.CancelButton>

          <Styled.NextButton
            size={isDesktop ? 'xsmall' : 'small'}
            track={buttonClickTracking}
            trackName="create_or_update_models"
            onClick={() => {
              routineMethods.handleSubmit(onSubmitRoutine)()
              methods.handleSubmit(onSubmit)()
            }}
          >
            Salvar modelo
          </Styled.NextButton>
        </Styled.ButtonContainer>
      </Styled.Content>

      {isVisible === 'mobile-exercise-gif-modal' && (
        <ModalExerciseImage
          exercise={selectedExercise}
          handleClose={closeModal}
        />
      )}

      {isVisible === 'delete-exercise-modal' && (
        <ModalDelete
          cancelTitle="Continuar editando"
          confirmTitle="Sim, quero excluir"
          description="Você está excluindo um treino e perderá todos os exercícios adicionados nele. Deseja prosseguir?"
          onConfirm={handleDelete}
          title="Excluir Treino"
        />
      )}

      {isVisible === 'rename-workout-model' && (
        <ModalRenameWorkoutModel
          workoutModel={workoutModels?.find(
            (model) => selectedModel === model.id,
          )}
          updateWorkoutModel={updateWorkoutModel}
        />
      )}
    </Styled.Container>
  )
}

export default LibraryWorkoutRoutineModelReview
