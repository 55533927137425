// Models
import { IconsType } from '../../UI/Icons/values'
import { TButtonTrakingEvent } from '../../../models'

// Misc
import { buttonStyles } from '../BaseButton/styles'
import { cn } from '../../../utils/classes'

// React
import { useContext, forwardRef, MouseEvent } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useLocation } from 'react-router-dom'

// Components
import { Props as ButtonProps } from '../BaseButton'
import Icon from '../../UI/Icons'

export type Props = ButtonProps & {
  iconName?: IconsType
  track?: (event: TButtonTrakingEvent) => void
  trackName?: string
}

enum EIconSizes {
  xsmall = 18,
  small = 20,
  medium = 24,
  large = 24,
}

const IconButton = forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
  const {
    disabled,
    cancel,
    size = 'medium',
    variation = 'outlined',
    iconName,
    track,
    trackName,
    onClick,
    className,
    ...restProps
  } = props
  const { search } = useLocation()
  const theme = useContext(ThemeContext)

  const iconColors = {
    main: theme.colors.icon.onPrimary,
    outlined: theme.colors.icon[disabled ? 'disabled' : 'default'],
    borderless: theme.colors.interactive[disabled ? 'disabled' : 'default'],
    success: theme.colors.icon.onPrimary,
    cancel: theme.colors.icon.critical,
  }

  const tracking = track
    ? () =>
        track({
          eventName: 'click',
          action: `click_button_${trackName}`,
          location: window.location.pathname + search,
        })
    : undefined

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    tracking?.()
    onClick?.(event)
  }

  return (
    <button
      ref={ref}
      data-testid="IconButton"
      type="button"
      onClick={handleClick}
      disabled={disabled}
      className={cn(
        buttonStyles({ variation, size, onlyIcon: true, cancel }),
        'rounded-2',
        {
          'border-border-input bg-surface hover:border-border-input':
            variation === 'outlined',
        },
        className,
      )}
      {...restProps}
    >
      <Icon
        size={EIconSizes[size]}
        color={iconColors[cancel ? 'cancel' : variation]}
        iconName={iconName}
      ></Icon>
    </button>
  )
})
IconButton.displayName = 'Button'

export default IconButton
