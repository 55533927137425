// React
import { FC, ReactNode, useEffect, useState } from 'react'

// Libraries
import { useNavigate } from 'react-router-dom'

// Misc
import { cn } from 'utils/helpers/classess'
import { urls } from 'routes/paths'

// Components
import { Tabs } from 'heeds-ds'
// Constants
const STEPS = ['Rotinas', 'Anamneses', 'Treinos', 'Exercícios']

export interface IAthleteOutletContext {
  id: string
  isEditing: boolean
  navigateToNextTab: (to?: string) => void
  navigateToPreviousTab: (to?: string) => void
  blockNavigation: (to?: string) => void
}

type pageType = 'Rotinas' | 'Anamneses' | 'Treinos' | 'Exercícios'

type Props = {
  children: ReactNode | ReactNode[]
  page: pageType
}

const LibraryHeader: FC<Props> = ({ children, page = 'Rotinas' }) => {
  const navigate = useNavigate()

  const [selectedTab, setSelectedTab] = useState<pageType>(page)

  useEffect(() => {
    switch (selectedTab) {
      case 'Rotinas':
        return navigate(urls.libraryRoutinesTemplates)
      case 'Anamneses':
        return navigate(urls.libraryAnamneseTemplates)
      case 'Treinos':
        return navigate(urls.LibraryWorkoutModels)
      case 'Exercícios':
        return navigate(urls.LibraryExerciseTemplate)
    }
  }, [navigate, selectedTab])

  return (
    <div
      data-testid="library-header-container"
      className={cn(
        'relative mb-[60px] flex w-full flex-col items-end justify-between border border-border-input px-4',
        'pt-[11px] xl:mb-0 xl:flex-row xl:px-4',
      )}
    >
      <Tabs
        active={selectedTab}
        distanceOfBar="6"
        fontSize="copy3"
        setActive={(tab) => setSelectedTab(tab as pageType)}
        tabs={STEPS}
      />
      <div
        className={cn(
          'absolute left-4 right-4 top-full self-center px-2 py-4 xl:relative xl:left-auto xl:right-auto',
          'xl:top-auto xl:w-[375px] xl:p-0',
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default LibraryHeader
