// React
import { ChangeEvent, FC } from 'react'

// Misc
import { cn } from '../../utils/classes'

import { useController, useFormContext } from 'react-hook-form'

type Props = {
  disabled?: boolean
  name: string
}

const Switch: FC<Props> = (props: Props) => {
  const { disabled, name } = props

  const { control } = useFormContext()
  const {
    field: { onChange, ref, value },
  } = useController({
    name,
    control,
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked)
  }

  return (
    <label
      className={cn('flex cursor-pointer items-center', {
        'cursor-not-allowed': disabled,
      })}
    >
      <input
        data-testid="switch"
        checked={value}
        disabled={disabled}
        type="checkbox"
        onChange={handleChange}
        name={name}
        ref={ref}
        className="absolute hidden opacity-0"
      />
      <div
        className={cn(
          'relative h-6 w-12 rounded-[32px] bg-border-shadowSubdued p-1 transition',
          'before:absolute before:left-[5px] before:h-[14px] before:rounded-[14px]',
          'duration-300 ease-in-out before:bg-white before:content-[""]',
          'before:bottom-1/2 before:translate-y-1/2 before:transition',
          'before:w-[14px] before:duration-300 before:ease-in-out',
          {
            'bg-icon before:translate-x-[25px] before:translate-y-1/2 hover:bg-icon-hovered':
              value,
            'group-checked:bg-icon-disabled hover:bg-icon-hovered':
              disabled && value,
          },
        )}
      />
    </label>
  )
}

export default Switch
