// Models
import { IAction } from 'lib/redux/models'
import {
  ILoginPayload,
  IRegisterResponse,
  IResetPasswordPayload,
  IStatusResponse,
  INewPasswordPayload,
  TokenType,
  INewPasswordResponse,
  IUser,
  IForgotPasswordPayload,
  IUserInvitePayload,
  IUserInvite,
  ITokensResponse,
} from '../models'
import { IRegisterPayload } from './../models/index'
import { SagaIterator } from '@redux-saga/types'

// Libraries
import { all, call, put, takeLatest } from 'redux-saga/effects'

// Misc
import { failure, fulfill, request, success, Types } from '../duck'
import {
  login,
  register,
  registerEmailConfirmation,
  requestForgotPasswordEmail,
  resendEmailConfirmation,
  resetPassword,
  newPassword,
  validateResetToken,
  userInvite,
} from 'services/auth'
import { getTokenBasedOnProvider, logoutFirebase } from 'services/firebase'
import { pendoInitialize } from 'lib/pendo'
import { setAuthTokens } from 'lib/axios/jwt'
import { triggerToastError, triggerToastSuccess } from 'storage/general/duck'
import { urls } from 'routes/paths'
import { updateAthletes } from 'storage/athletes/duck'

export function* logoutSaga() {
  yield call(logoutFirebase)
  yield put({ type: 'APP/RESET' })
}

export function* requestForgotPasswordSaga(
  action: IAction<IForgotPasswordPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IStatusResponse = yield call(
        requestForgotPasswordEmail,
        action.payload.email,
      )

      if (response) {
        yield put(
          success({ success: true, userData: { email: action.payload.email } }),
        )

        if (action?.payload?.redirection) {
          const { navigate, to } = action.payload.redirection
          navigate(to)
        } else {
          yield put(
            triggerToastSuccess({
              customTitle: 'E-mail reenviado!',
              message: 'Lembre de olhar sua caixa de spam.',
            }),
          )
        }
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* registerEmailConfirmationSaga(action: IAction<string>) {
  yield put(request())
  try {
    if (action.payload) {
      const response: boolean = yield call(
        registerEmailConfirmation,
        action.payload,
      )

      if (response) {
        yield put(success({ registerConfirmed: true }))
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(success({ registerConfirmed: false }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* resendConfirmationEmailSaga(action: IAction<string>) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IRegisterResponse = yield call(
        resendEmailConfirmation,
        action.payload,
      )
      if (response) {
        yield put(success({ success: true }))
        yield put(
          triggerToastSuccess({
            customTitle: 'E-mail reenviado!',
            message: 'Lembre de olhar sua caixa de spam.',
          }),
        )
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(
      triggerToastError({
        customTitle: 'Algo deu errado...',
        message: 'Clique novamente em reenviar e-mail.',
      }),
    )
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* resetPasswordSaga(action: IAction<IResetPasswordPayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IStatusResponse = yield call(
        resetPassword,
        action.payload,
      )

      if (response?.status === 'OK') {
        yield put(success({ resetPasswordSuccess: true }))
      }

      if (action?.payload?.redirection) {
        const { navigate, to } = action.payload.redirection
        navigate(to)
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* newPasswordSaga(action: IAction<INewPasswordPayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const response: INewPasswordResponse = yield call(
        newPassword,
        action.payload,
      )
      if (response) {
        yield put(success({ newPasswordSuccess: true, alert: true }))
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
    yield put(success({ newPasswordSuccess: false, alert: true }))
  } finally {
    yield put(fulfill())
  }
}

export function* triggerLoginSaga(action: IAction<ILoginPayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const firebaseToken: TokenType = yield call(
        getTokenBasedOnProvider,
        action.payload,
      )
      const userData: IUser & ITokensResponse = yield call(login, {
        token: firebaseToken,
        provider: action.payload.provider,
        type: action.payload.type,
        invite_token: action.payload.invite_token,
      })
      if (userData) {
        pendoInitialize(userData)
        if (userData.type === 'personal') {
          const { navigate, to } = action.payload.redirection

          setAuthTokens({ access: userData.access, refresh: userData.refresh })
          yield put(success({ userData }))
          navigate(to)
        } else if (action.payload.type === 'athlete') {
          const { navigate, to } = action.payload.redirection
          yield put(success({ userData }))
          navigate(to)
        } else {
          const { navigate } = action.payload.redirection
          navigate(urls.unauthorized)
        }
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* triggerRegisterSaga(action: IAction<IRegisterPayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IRegisterResponse = yield call(register, action.payload)
      if (response) {
        const { email, registered_with_email_from_invite, type } = response
        const { navigate, to } = action.payload.redirection
        yield put(
          success({
            userData: {
              email,
              registered_with_email_from_invite,
              type,
            },
          }),
        )
        navigate(to)
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* validateResetTokenSaga(action: IAction<TokenType>) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IStatusResponse = yield call(
        validateResetToken,
        action.payload,
      )

      if (response?.status === 'OK') {
        yield put(success({ isResetTokenValid: true }))
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(success({ isResetTokenValid: false }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* userInviteSaga(action: IAction<IUserInvitePayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const { email, name, anamnesis_template_id } = action.payload
      const response: IUserInvite = yield call(userInvite, {
        name: name ? name : email,
        email,
        anamnesis_template_id,
      })

      if (email && response) {
        yield put(
          updateAthletes({
            name: name ? name : email,
            id: 0,
            status: 'invited',
          }),
        )
        yield put(
          triggerToastSuccess({
            customTitle: 'Convite enviado com sucesso!',
            message:
              'Um e-mail foi encaminhado para o aluno com o link para preenchimento do cadastro.',
          }),
        )
      }

      if (response) {
        yield put(success({ invite_url: response.invite_url }))
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export default function* authSaga(): SagaIterator {
  yield all([
    takeLatest(
      Types.REGISTER_EMAIL_CONFIRMATION,
      registerEmailConfirmationSaga,
    ),
    takeLatest(Types.REQUEST_FORGOT_PASSWORD, requestForgotPasswordSaga),
    takeLatest(Types.RESEND_CONFIRMATION_EMAIL, resendConfirmationEmailSaga),
    takeLatest(Types.TRIGGER_REGISTER, triggerRegisterSaga),
    takeLatest(Types.TRIGGER_LOGIN, triggerLoginSaga),
    takeLatest(Types.TRIGGER_USER_INVITE, userInviteSaga),
    takeLatest(Types.RESET_PASSWORD, resetPasswordSaga),
    takeLatest(Types.NEW_PASSWORD, newPasswordSaga),
    takeLatest(Types.VALIDATE_RESET_TOKEN, validateResetTokenSaga),
    takeLatest(Types.LOGOUT, logoutSaga),
  ])
}
