// React
import { FC } from 'react'

// Components
import { Skeleton } from 'heeds-ds'

const InfoLoader: FC = () => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-4">
      <Skeleton className="h-32 w-32 rounded-full" />
      <Skeleton className="h-[26px] w-[60vw] rounded-1" />
      <Skeleton className="h-4 w-[30vw] rounded-1" />
      <Skeleton className="h-4 w-[30vw] rounded-1" />
      <Skeleton className="h-8 w-[65px] rounded-4" />
      <Skeleton className="h-11 w-[143px] rounded-1" />
    </div>
  )
}

export default InfoLoader
