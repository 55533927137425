// React
import { FC, useContext } from 'react'

import { ThemeContext } from 'styled-components'

import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import * as Styled from './styled'
import { Skeleton } from 'heeds-ds'

const Loader: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  return (
    <Styled.LoadContainer data-testid="row">
      {isDesktop ? (
        <>
          <div className="flex items-center gap-2 px-6 py-4">
            <Skeleton className="h-[42px] w-[42px] shrink-0 rounded-5" />
            <Skeleton className="h-4 w-60" />
          </div>
          <div className="px-6 py-4">
            <Skeleton className="h-4 w-60" />
          </div>
          <div className="px-6 py-4">
            <Skeleton className="h-4 w-60" />
          </div>
          <div className="px-6 py-4">
            <Skeleton className="h-4 w-40" />
          </div>
          <div className="px-6 py-4">
            <Skeleton className="h-4 w-40" />
          </div>
          <div className="px-6 py-4">
            <Skeleton className="h-9 w-9" />
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col gap-2">
            <Skeleton className="m-2 h-4 w-48" />
            <Skeleton className="h-4 w-80" />
            <Skeleton className="h-4 w-[60px]" />
            <Skeleton className="h-4 w-[70px]" />
          </div>
        </>
      )}
    </Styled.LoadContainer>
  )
}

export default Loader
