// Models
import {
  IAnamneseAnswer,
  IAnamneseAnswerState,
} from 'storage/assessmentAnamneseAnswer/models'
import { IFilter } from 'heeds-ds/src/components/Inputs/InputSearch'
import { ILoadAnamneseAnswersPayload } from 'services/assessmentAnamneseAnswer/@types'
import { TMenuDropdownOption } from 'heeds-ds/src/models'
import IStore from 'lib/redux/models'

// React
import {
  FC,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  Navigate,
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import {
  selectAnamneseAnswer,
  triggerCreateAnamneseAnswers,
  triggerDeleteAnamneseAnswer,
  triggerLoadAnamneseAnswers,
  triggerLoadMoreAnamneseAnswers,
} from 'storage/assessmentAnamneseAnswer/duck'
import { urls } from 'routes/paths'
import {
  useDebounceFunction,
  useFilters,
  useMediaQuery,
  useMenu,
  useModal,
} from 'hooks'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Body,
  Button,
  CommonList,
  DropdownMenu,
  Icon,
  InputSearch,
  Loading,
} from 'heeds-ds'
import {
  AnamneseAnswerItem,
  Modal,
  ModalDelete,
  ModalMoreOptions,
  ModalNewAnamneseTemplate,
} from 'components'
import Loader from 'components/lists/AnamneseAnswerItem/loader'
import { triggerToastSuccess } from 'storage/general/duck'

// Constants
export const ADD_NEW_ANAMNESE_ANSWERS_SUCCESS =
  'Seu aluno será notificado que existe uma nova anamnese para responder'

const ORDER_FILTER = [
  { label: 'Mais recentes', value: 'creation_date', param: 'ordering' },
  { label: 'A-Z', value: 'title', param: 'ordering' },
  { label: 'Z-A', value: '-title', param: 'ordering' },
]

const dayInMs = 86400000

const page_size = 20

const columnNames = [
  { label: 'Nome da anamnese' },
  { label: 'Criado por' },
  { label: 'Data de envio' },
  { label: 'Data de resposta' },
  { label: 'Status' },
]

const headerGridTemplate =
  'grid-cols-[minmax(20rem,1fr)repeat(4,minmax(2rem,0.5fr))minmax(2rem,0.35fr)]'

const AthleteAssessmentAnamneseAnswers: FC = () => {
  const { id = '' } = useParams()
  const { anamneseAnswers, error, loading, next } = useSelector<
    IStore,
    IAnamneseAnswerState
  >(({ assessmentAnamneseAnswer }) => assessmentAnamneseAnswer)
  const { breakpoints } = useContext(ThemeContext)
  const { closeModal, openModal, isVisible } = useModal()
  const { setPagename } = useMenu()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)
  const isTablet = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)
  const navigate = useNavigate()

  const [selected, setSelected] = useState<IAnamneseAnswer>()

  const { filters, setFilter, toggleFilter } =
    useFilters<ILoadAnamneseAnswersPayload>(window.location.search)

  const handleDelete = (anamneseId: string) => {
    dispatch(
      triggerDeleteAnamneseAnswer({
        id: anamneseId,
        successCallback: () =>
          handleReloadAnamnese({ ...filters, athlete_pk: Number(id) }),
      }),
    )
  }

  const getOptions = (
    anamneseAnswer: IAnamneseAnswer,
  ): TMenuDropdownOption[] => {
    return [
      {
        icon: 'eye',
        label: 'Visualizar respostas',
        onClick: () => {
          navigateToAnamnese(anamneseAnswer)
          closeModal()
        },
      },
      {
        icon: 'send',
        label: 'Reenviar formulário',
        disabled: !anamneseAnswer.answer_date,
        onClick: () => {
          handleResendForm(anamneseAnswer)
        },
      },
      {
        disabled: !!anamneseAnswer.answer_date,
        color: 'critical',
        icon: 'delete',
        label: 'Excluir',
        onClick: () => {
          setSelected(anamneseAnswer)
          openModal('delete-anamnese-modal')
        },
      },
    ]
  }

  const navigateBack = () => {
    navigate(-1)
  }

  const navigateToAnamnese = (anamnese: IAnamneseAnswer) => {
    dispatch(selectAnamneseAnswer(anamnese))
    navigate(
      generatePath(urls.athleteAssessmentAnamneseAnswer, {
        id,
        anamnese_id: anamnese.id,
      }),
    )
  }

  const copyAnamneseAnswer = (anamnese: IAnamneseAnswer) =>
    dispatch(
      triggerCreateAnamneseAnswers({
        anamnesis_template_ids: [anamnese.template_id],
        athlete_id: anamnese.athlete,
        successCallback: () =>
          dispatch(
            triggerToastSuccess({
              message: 'Formulário reenviado.',
            }),
          ),
      }),
    )

  const handleResendForm = (anamnese: IAnamneseAnswer) => {
    if (!anamnese.answer_date) return

    const today = new Date()
    const answeredDate = new Date(anamnese.answer_date)
    const diffInMs = Math.abs(today.getTime() - answeredDate.getTime())
    const diffInDays = Math.ceil(diffInMs / dayInMs)

    if (diffInDays > 180) return copyAnamneseAnswer(anamnese)

    openModal('modal-not-resend')
  }

  const handleAddAnamnese = (templateIds: string[]) => {
    const successCallback = () => {
      dispatch(
        triggerToastSuccess({
          message: ADD_NEW_ANAMNESE_ANSWERS_SUCCESS,
        }),
      )
      handleReloadAnamnese(filters)
    }
    id &&
      dispatch(
        triggerCreateAnamneseAnswers({
          athlete_id: Number(id),
          anamnesis_template_ids: templateIds,
          successCallback,
        }),
      )
  }

  const handleEndReached = () => {
    if (anamneseAnswers && !loading && next) {
      const query = {
        ...filters,
        athlete_pk: Number(id),
        page: next,
        page_size,
      }
      dispatch(triggerLoadMoreAnamneseAnswers(query))
    }
  }

  const handleReloadAnamnese = useCallback(
    (newFilters: ILoadAnamneseAnswersPayload) => {
      const query = {
        ...newFilters,
        athlete_pk: Number(id),
        page_size,
      }
      dispatch(triggerLoadAnamneseAnswers(query))
    },
    [dispatch, id],
  )

  const onClickFilter = useCallback(
    (filter: IFilter) => {
      const newFilters = toggleFilter(filter.param, filter.value, true)
      handleReloadAnamnese({ ...newFilters, athlete_pk: Number(id) })
    },
    [handleReloadAnamnese, id, toggleFilter],
  )

  const orderingFilters = useMemo(
    () =>
      ORDER_FILTER.map((orderItem) => ({
        ...orderItem,
        active: filters?.ordering?.toString() === orderItem.value,
        onClick: () => {
          if (!isTablet) closeModal()
          onClickFilter(orderItem)
        },
      })),
    [closeModal, filters?.ordering, isTablet, onClickFilter],
  )

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filters = setFilter('title', event.target.value, true)
    debouncedLoad({ ...filters, athlete_pk: Number(id) })
  }

  const debouncedLoad = useDebounceFunction<
    (newFilters: ILoadAnamneseAnswersPayload) => void
  >(handleReloadAnamnese, 1000)

  const renderItems = (item: IAnamneseAnswer) => {
    return (
      <AnamneseAnswerItem
        anamneseAnswer={item}
        handleOptions={getOptions}
        key={item.id}
        onClick={() => navigateToAnamnese(item)}
        onSwipeClick={() => {
          setSelected(item)
          openModal('modal-more-options')
        }}
      />
    )
  }

  useLayoutEffect(() => {
    const changedAthlete =
      anamneseAnswers?.length && anamneseAnswers?.[0]?.athlete !== Number(id)
    if (!loading && (changedAthlete || !anamneseAnswers)) {
      const query = {
        ...filters,
        athlete_pk: Number(id),
        page_size,
      }
      dispatch(triggerLoadAnamneseAnswers(query))
    }
  }, [dispatch, filters, id, anamneseAnswers, loading])

  useLayoutEffect(() => {
    setPagename('ANAMNESES')

    return () => {
      setPagename('DASHBOARD')
    }
  }, [dispatch, setPagename])

  if (!loading && error) {
    return (
      <Navigate to={generatePath(urls.athleteAssessment, { id })} replace />
    )
  }

  if (loading && anamneseAnswers === undefined) {
    return <Loading active />
  }

  return (
    <Styled.Container>
      {isTablet && (
        <Aligner justify={isDesktop ? 'space-between' : 'flex-end'}>
          {isDesktop && (
            <Button
              onClick={navigateBack}
              size="small"
              track={buttonClickTracking}
              trackName="go_back_navigation"
              variation="borderless"
            >
              <Icon iconName="chevronLeft" />
              Voltar para avaliações
            </Button>
          )}

          <Button
            onClick={() => openModal('new-anamnese-modal')}
            size="small"
            track={buttonClickTracking}
            trackName="open_new_anamnese_modal"
          >
            Adicionar anamnese
          </Button>
        </Aligner>
      )}

      <Styled.Content>
        <Styled.ContentHeader>
          {isTablet && <Body weight={600}>Anamneses</Body>}

          <InputSearch
            onChange={onSearchChange}
            scale="small"
            value={filters?.title || ''}
            className="w-[375px]"
          />

          {!isTablet && (
            <Styled.SwapVertButton
              onClick={() => openModal('ordering-filter-modal')}
              track={buttonClickTracking}
              trackName="open_ordering_filters_menu"
            />
          )}
        </Styled.ContentHeader>

        {isTablet && (
          <Aligner align="center" justify="flex-end" padding="0.8rem 1.6rem">
            <DropdownMenu items={orderingFilters}>
              <Button
                size="xsmall"
                track={buttonClickTracking}
                trackName="open_ordering_filters_menu"
                variation="borderless"
              >
                <Styled.SwapVertIcon />
                Ordenar
              </Button>
            </DropdownMenu>
          </Aligner>
        )}

        <CommonList<IAnamneseAnswer>
          columns={columnNames}
          columnsFormat={headerGridTemplate}
          data={anamneseAnswers || []}
          hasMore={!!next}
          loaderComponent={<Loader />}
          onEndReached={handleEndReached}
          padding="py-4 pr-4 pl-6"
          renderItem={renderItems}
          showHeader={isTablet}
        />
      </Styled.Content>

      {!isTablet && (
        <Styled.ButtonContainer>
          <Button
            onClick={() => openModal('new-anamnese-modal')}
            size="small"
            track={buttonClickTracking}
            trackName="open_new_anamnese_modal"
          >
            Adicionar anamnese
          </Button>
        </Styled.ButtonContainer>
      )}

      {!isTablet && isVisible === 'modal-more-options' && (
        <ModalMoreOptions options={selected ? getOptions(selected) : []} />
      )}

      {!isTablet && isVisible === 'ordering-filter-modal' && (
        <ModalMoreOptions options={orderingFilters} />
      )}

      {isVisible === 'new-anamnese-modal' && (
        <ModalNewAnamneseTemplate onSend={handleAddAnamnese} />
      )}

      {isVisible === 'delete-anamnese-modal' && (
        <ModalDelete
          confirmTitle="Excluir"
          description="Essa ação é irreversível, seu aluno não terá mais acesso a este formulário."
          onConfirm={() => selected && handleDelete(selected.id)}
          title="Tem certeza que deseja excluir?"
        />
      )}

      {isVisible === 'modal-not-resend' && (
        <Modal onClose={closeModal}>
          <Styled.ModalContainer>
            <Styled.ModalTitle>Reenviar formulário?</Styled.ModalTitle>

            <Styled.ModalContent>
              <Styled.ModalDescription>
                Você poderá reenviar o formulário só após 6 meses da data de
                resposta do seu aluno.
              </Styled.ModalDescription>

              <Button
                onClick={closeModal}
                size="small"
                track={buttonClickTracking}
                trackName="alert_about_form_resubmission"
                className="w-full"
              >
                Entendi!
              </Button>
            </Styled.ModalContent>
          </Styled.ModalContainer>
        </Modal>
      )}
    </Styled.Container>
  )
}

export default AthleteAssessmentAnamneseAnswers
