// Models
import { IFilter } from 'heeds-ds/src/components/Inputs/InputSearch'
import { IFilterFields } from 'blocks/dashboard/athlete/AthleteRoutines/RoutineList'
import { IWorkoutRoutineState } from 'storage/workoutRoutine/models'
import { IWorkoutRoutineListPayload } from 'services/workoutRoutine/@types'
import { TRoutineStatus } from 'models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useLayoutEffect } from 'react'

// Libs
import { ThemeContext } from 'styled-components'
import { generatePath, useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cleanup, success } from 'storage/workoutRoutine/duck'
import { triggerLoadAthlete } from 'storage/athleteInfo/duck'
import { triggerLoadAthletePlans } from 'storage/athletePlans/duck'
import {
  triggerLoadListWorkoutRoutines,
  triggerLoadMoreListWorkoutRoutine,
} from 'storage/workoutRoutine/duck'
import { urls } from 'routes/paths'
import { useDebounceFunction, useMediaQuery, useMenu } from 'hooks'
import useFilters from 'hooks/useFilters'

// Components
import * as Block from 'blocks/dashboard/athlete/AthleteRoutines'
import * as Styled from './styled'
import { Button } from 'heeds-ds'

// Constants
const page_size = 20

const AthleteRoutines: FC = () => {
  const { id = '' } = useParams()
  const { error, workoutRoutines, loading, next } = useSelector<
    IStore,
    IWorkoutRoutineState
  >((state) => state.workoutRoutine)
  const { setPagename } = useMenu()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const { filters, isValueIncluded, setFilter, toggleFilter, resetFilters } =
    useFilters<IWorkoutRoutineListPayload>(window.location.search)

  const handleEndReached = () => {
    if (workoutRoutines && !loading && next) {
      const query = {
        ...filters,
        athlete: id,
        page: next,
        page_size,
      }
      dispatch(triggerLoadMoreListWorkoutRoutine(query))
    }
  }

  const handleGoBack = () => navigate(urls.athletes)

  const handleModalFilters = (modalFilters: IFilterFields) => {
    const newFilters = { ...filters, ...modalFilters }
    resetFilters(newFilters)
    handleReloadWorkoutRoutine(newFilters)
  }

  const handleNavigateToCreation = () => {
    dispatch(cleanup())
    navigate(generatePath(urls.workoutRoutineCreate, { id }))
  }

  const onClickFilter = (filter: IFilter) => {
    const newFilters = toggleFilter(filter.param, filter.value, true)
    handleReloadWorkoutRoutine(newFilters)
  }

  const onTabChange = (selectedTab: TRoutineStatus) => {
    const newFilters = setFilter('routine_status', selectedTab, true)
    handleReloadWorkoutRoutine(newFilters)
  }

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filters = setFilter('search', event.target.value, true)
    debouncedLoad(filters)
  }

  const handleReloadWorkoutRoutine = (
    newFilters: IWorkoutRoutineListPayload,
  ) => {
    const query = {
      ...newFilters,
      athlete: id,
      page_size,
      routine_status: newFilters.routine_status || 'current',
    }
    dispatch(triggerLoadListWorkoutRoutines(query))
  }

  const debouncedLoad = useDebounceFunction<
    (newFilters: IWorkoutRoutineListPayload) => void
  >(handleReloadWorkoutRoutine, 1000)

  useLayoutEffect(() => {
    if (!workoutRoutines && !loading && !error) {
      const query = {
        ...filters,
        athlete: id,
        page_size,
        routine_status: filters.routine_status || 'current',
      }
      dispatch(triggerLoadListWorkoutRoutines(query))
    }
  }, [dispatch, error, filters, id, loading, workoutRoutines])

  useLayoutEffect(() => {
    if (id) {
      const athlete_pk = parseInt(id)

      dispatch(triggerLoadAthlete({ id: athlete_pk }))
      dispatch(triggerLoadAthletePlans({ id: athlete_pk }))
    }
  }, [dispatch, id])

  useLayoutEffect(() => {
    setPagename('ROTINAS')

    return () => {
      setPagename('DASHBOARD')
      dispatch(success({ workoutRoutines: undefined }))
    }
  }, [dispatch, setPagename])

  return (
    <Styled.Container id="athlete-routines-container">
      {isDesktop && (
        <Button
          size="small"
          variation="borderless"
          onClick={handleGoBack}
          track={buttonClickTracking}
          trackName="navigate_to_athletes"
        >
          <Styled.GoBackIcon />
          Voltar para lista de alunos
        </Button>
      )}

      <Block.RoutineHeader
        activeTab={filters.routine_status?.[0] as TRoutineStatus}
        handleNavigateToCreation={handleNavigateToCreation}
        onTabChange={onTabChange}
      />

      <Block.RoutineList
        filters={filters}
        isActive={isValueIncluded}
        handleEndReached={handleEndReached}
        handleNavigateToCreation={handleNavigateToCreation}
        onClickFilter={onClickFilter}
        onFiltersChange={handleModalFilters}
        onSearchChange={onSearchChange}
        refreshPage={() => handleReloadWorkoutRoutine(filters)}
      />
    </Styled.Container>
  )
}

export default AthleteRoutines
