// Models
import {
  IGetExercisesPayload,
  IGetExercisesResponse,
  IEquipmentResponse,
  IExerciseDeletePayload,
  IPresignedData,
} from './@types'

// Misc
import { objectToQueryString } from 'lib/query-string'
import api from 'lib/axios/api'
import { IExerciseData } from 'storage/exercise/models'

export const loadExercises = async (
  payload: IGetExercisesPayload,
): Promise<IGetExercisesResponse> => {
  try {
    const query = payload
    const { data }: { data: IGetExercisesResponse } = await api.get(
      `exercises/`,
      {
        params: query,
        paramsSerializer: function (params) {
          return objectToQueryString(params)
        },
      },
    )
    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const loadExercisesLibrary = async (
  payload: IGetExercisesPayload,
): Promise<IGetExercisesResponse> => {
  try {
    const query = payload
    const { data }: { data: IGetExercisesResponse } = await api.get(
      `exercises/library/`,
      {
        params: query,
        paramsSerializer: function (params) {
          return objectToQueryString(params)
        },
      },
    )

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const loadExercise = async (
  exerciseId: number,
): Promise<IExerciseData> => {
  try {
    const response = await api.get(`exercises/${exerciseId}/`)
    const data: IExerciseData = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const loadEquipments = async (): Promise<IEquipmentResponse> => {
  try {
    const response = await api.get('exercises/equipment/')
    const data: IEquipmentResponse = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const createExercise = async (
  payload: IExerciseData,
): Promise<IExerciseData> => {
  try {
    const response = await api.post('exercises/library/', payload)
    const data: IExerciseData = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const updateExercise = async (
  payload: IExerciseData,
): Promise<IExerciseData> => {
  try {
    const response = await api.put(`exercises/${payload?.id}/`, payload)
    const data: IExerciseData = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const partialUpdateExercise = async (
  payload: IExerciseData,
): Promise<IExerciseData> => {
  try {
    const response = await api.patch(`exercises/${payload?.id}/`, payload)
    const data: IExerciseData = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const removeExercise = async (
  payload: IExerciseDeletePayload,
): Promise<IExerciseDeletePayload> => {
  try {
    const { id } = payload
    const response = await api.delete(`/exercises/${id}/`)
    const data: IExerciseDeletePayload = response.data

    return data
  } catch (error: unknown) {
    const err = error as Error
    throw new Error(err.message)
  }
}

export const uploadFile = async (file: File, presignedData: IPresignedData) => {
  const formData = new FormData()

  Object.entries(presignedData.fields).forEach(([key, value]) => {
    formData.append(key, value as string)
  })

  formData.append('file', file)

  const response = await fetch(presignedData.url, {
    method: 'POST',
    body: formData,
  })

  if (response.ok) {
    console.log('File uploaded successfully')
  } else {
    console.error('File upload failed')
  }
}
