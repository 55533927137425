// React
import { FC, useContext } from 'react'

import { ThemeContext } from 'styled-components'

import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import * as Styled from './styled'
import { Aligner, Skeleton } from 'heeds-ds'

const Loader: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  return (
    <Styled.LoadContainer data-testid="row">
      {isDesktop ? (
        <>
          <Skeleton className="h-4 w-60" />
          <Skeleton className="ml-5 h-4 w-60" />
          <Skeleton className="ml-5 h-4 w-40" />
        </>
      ) : (
        <Aligner flex="column" gap="0.8rem">
          <Skeleton className="h-[21px] w-72" />
          <Skeleton className="h-[19px] w-[60px]" />
        </Aligner>
      )}
    </Styled.LoadContainer>
  )
}

export default Loader
