// Misc
import { cn } from '../../utils/classes'

type Props = {
  className?: string
}

const Skeleton = ({ className }: Props) => {
  return (
    <div
      className={cn(
        'animation-shine-through m-0 h-full w-full rounded-[5px] bg-surface',
        className,
      )}
    />
  )
}

export default Skeleton
