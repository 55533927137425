// Models
import { IModelInput, TSpecialSetInput } from 'models'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC, useCallback, useContext } from 'react'

// Libraries
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { getModelName, uid } from 'utils/functions'
import { useMediaQuery } from 'hooks'

// Components
import * as Styled from './styled'
import { Button, DropdownMenu, Icon, IconButton } from 'heeds-ds'

interface Props {
  addWorkoutModel: (model: IModelInput) => void
  duplicateWorkoutModel: () => void
  models: IModelInput[]
  removeWorkoutModel: () => void
  renameWorkoutModel: () => void
  selectWorkoutModel: (index: number) => void
  selectedModelIndex: number
  specialSet?: TSpecialSetInput
}

const WorkoutModelHeader: FC<Props> = ({
  addWorkoutModel,
  duplicateWorkoutModel,
  models,
  removeWorkoutModel,
  renameWorkoutModel,
  selectWorkoutModel,
  selectedModelIndex,
  specialSet,
}) => {
  const { breakpoints, colors } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)

  const hasPrevious = selectedModelIndex > 0
  const hasNext = Number(models?.length) - 1 > selectedModelIndex
  const itsBlocked = specialSet !== undefined

  const handleBack = () => selectWorkoutModel(selectedModelIndex - 1)

  const handleNext = () => selectWorkoutModel(selectedModelIndex + 1)

  const createWorkoutModel = useCallback(() => {
    addWorkoutModel({
      id: 'NEW' + uid(),
      name: getModelName(models.length),
      workout_set: {},
      workout_routine: models[selectedModelIndex].workout_routine,
    })
  }, [addWorkoutModel, models, selectedModelIndex])

  const actionOptions: TMenuDropdownOption[] = [
    {
      label: 'Renomear',
      icon: 'edit',
      onClick: renameWorkoutModel,
    },
    {
      label: 'Duplicar',
      icon: 'contentCopy',
      onClick: duplicateWorkoutModel,
    },
    {
      label: 'Excluir',
      icon: 'delete',
      color: 'critical',
      onClick: removeWorkoutModel,
    },
  ]

  return (
    <Styled.Container>
      <Styled.ModelContent>
        {isDesktop && (
          <IconButton
            disabled={!hasPrevious || itsBlocked}
            iconName="chevronLeft"
            onClick={handleBack}
            track={buttonClickTracking}
            trackName="show_previously_workout_model"
          />
        )}

        <Dropdown.Root>
          <Styled.ModelItem>
            <Styled.ModelName>
              {models?.[selectedModelIndex]?.name}
            </Styled.ModelName>

            {!isDesktop && (
              <Dropdown.Trigger asChild onClick={(e) => e.stopPropagation()}>
                <IconButton
                  iconName="expandMore"
                  size="small"
                  track={buttonClickTracking}
                  trackName="expand_to_show_workout_models"
                  className="ml-auto"
                />
              </Dropdown.Trigger>
            )}

            <DropdownMenu items={actionOptions}>
              <IconButton
                className="lg:h-8 lg:w-[32px]"
                disabled={itsBlocked}
                iconName="moreVertical"
                size="small"
                track={buttonClickTracking}
                trackName="open_menu_options_to_workout_model"
              />
            </DropdownMenu>
          </Styled.ModelItem>

          <Dropdown.Portal>
            <Styled.Content>
              {models?.map((model, index) => {
                return (
                  <Styled.Item
                    key={index}
                    onClick={() => selectWorkoutModel(index)}
                  >
                    {model?.name}
                  </Styled.Item>
                )
              })}
              <Styled.Item onClick={createWorkoutModel}>
                <Styled.AddButton>
                  <Icon iconName="add" color={colors.interactive.default} />
                  Adicionar Treino
                </Styled.AddButton>
              </Styled.Item>
            </Styled.Content>
          </Dropdown.Portal>
        </Dropdown.Root>

        {isDesktop && (
          <IconButton
            disabled={!hasNext || itsBlocked}
            iconName="chevronRight"
            onClick={handleNext}
            track={buttonClickTracking}
            trackName="show_next_workout_model"
          />
        )}
      </Styled.ModelContent>

      {isDesktop && (
        <Button
          disabled={itsBlocked}
          onClick={createWorkoutModel}
          size="xsmall"
          track={buttonClickTracking}
          trackName="add_workout_model_state"
          variation="borderless"
        >
          <Icon iconName="add" color={colors.interactive.default} />
          Novo treino
        </Button>
      )}
    </Styled.Container>
  )
}

export default WorkoutModelHeader
