// Models
import { IconsType } from 'heeds-ds/src/components/UI/Icons/StylizedIcons/values'
import IStore from 'lib/redux/models'

// React
import { FC, useEffect, useState } from 'react'

// Libraries
import { useSelector } from 'react-redux'

// Misc
import { cn } from 'utils/helpers/classess'
import { useModal } from 'hooks'

// Components
import { Button, Icon, IconButton, LinkButton, StylizedIcons } from 'heeds-ds'
import { Modal } from 'components/modals'

// Assets
import insiderBackground from 'assets/images/insider-banner-background.png'

const MODAL_TEXTS = [
  {
    icon: 'exercise',
    title: 'O que significa ser um Insider?',
    text: `Ser um Insider significa que você, como profissional de educação física, terá a oportunidade de usar o CADU antes de todos, 
          testar funcionalidades, dar feedbacks e ajudar a aprimorar o sistema para atender melhor às necessidades da profissão. É a chance 
          de contribuir com o desenvolvimento de uma ferramenta essencial para o seu dia a dia.`,
  },
  {
    icon: 'fitnessApp',
    title: 'Qual seria sua função?',
    text: `Sua função como Insider é explorar a ferramenta nas versões web e celular, fazer simulações com alunos e fornecer feedback sincero 
          sobre o uso, acessibilidade e recursos. Informe se algo importante está faltando ou se encontrar bugs, enviando prints ou relatando os 
          problemas encontrados.`,
  },
  {
    icon: 'app',
    title: 'Tenho que substituir meu app ou sistema atual?',
    text: `Não se preocupe! Participar do nosso grupo exclusivo de testadores não significa que você precisa substituir o seu app ou sistema atual. 
          Estamos apenas coletando o seu feedback, sem necessidade de implantações, migrações ou contratos.`,
  },
  {
    icon: 'sign',
    title: 'Vamos juntos nessa jornada?',
    text: `Venha fazer parte dessa iniciativa e ajude a criar uma ferramenta que realmente atenda às suas necessidades e a dos seus colegas 
          de profissão. Estamos ansiosos para contar com a sua expertise!`,
  },
]

const InsidersCard: FC = () => {
  const { profile } = useSelector((state: IStore) => state.profile)

  const { openModal, isVisible, closeModal } = useModal()
  const [open, setOpen] = useState(true)

  const handleKnowMore = () => {
    openModal('insider-modal-info')
    setOpen(false)
    localStorage.setItem('has_opened_insider_modal', 'true')
  }

  useEffect(() => {
    const hasOpened = localStorage.getItem('has_opened_insider_modal')
    setOpen(!hasOpened)
  }, [profile?.email])

  return (
    <div
      className={cn(
        'items-start rounded-2 border border-border-input shadow-[0px_2px_15px_0px_rgba(43,42,99,0.07)] xl:p-6',
        'relative flex h-fit w-full flex-col justify-center bg-badge-purpleText p-5 xl:max-w-[350px]',
      )}
    >
      <div
        style={{ backgroundImage: `url(${insiderBackground})` }}
        className={cn(
          'absolute left-0 top-0 w-full bg-cover bg-repeat opacity-10',
          'pointer-events-none z-[1] h-full',
        )}
      />
      <div data-testid="insiders-card-container" className="z-[2] w-full">
        <div
          className={cn(
            'mb-0 flex w-full items-center justify-between xl:mb-2',
            {
              'mb-5': open,
            },
          )}
        >
          <p className="text-copy1 font-semibold text-text-onPrimary">
            Seja um Insider!
          </p>
          <div className="xl:hidden">
            <IconButton
              iconName={open ? 'expandLess' : 'expandMore'}
              onClick={() => setOpen(!open)}
            />
          </div>
        </div>
        <div
          className={cn('hidden xl:flex xl:flex-col', {
            'flex flex-col': open,
          })}
        >
          <p className="mb-4 text-copy4 font-medium text-text-onPrimary">
            Participe do nosso canal de whatsapp exclusivo e fique por dentro
            das novidades do CADU em primeira mão! Além disso, você pode
            compartilhar suas sugestões para melhorar ainda mais o sistema.
          </p>
          <p className="text-copy4 font-medium text-text-onPrimary">
            E tem mais: inscreva-se e concorra a sorteios exclusivos para
            personal trainers!
          </p>
          <div className="mx-auto xl:ml-auto xl:mr-0">
            <Button
              size="xsmall"
              variation="outlined"
              onClick={handleKnowMore}
              className="mt-[24px]"
            >
              Clique aqui e saiba mais
            </Button>
          </div>
        </div>
        {isVisible === 'insider-modal-info' && (
          <Modal>
            <div className="w-auto xl:w-[712px]">
              <p className="my-6 px-6 py-3 text-copy1 font-semibold">
                Conheça mais sobre ser um Insider.
              </p>
              <div className="mt-8 flex h-[420px] flex-col gap-4 overflow-y-auto px-6 pb-8">
                {MODAL_TEXTS.map((info, index) => {
                  return (
                    <details
                      key={index}
                      className="group rounded-2 border border-border-input px-4 py-5"
                    >
                      <summary className="mb-0 flex cursor-pointer items-center justify-start gap-5 group-open:mb-4">
                        <StylizedIcons iconName={info.icon as IconsType} />
                        <p className="text-copy2 font-medium">{info.title}</p>
                        <div className="ml-auto rounded-2 border border-border-input p-[6px]">
                          <div className="hidden group-open:flex">
                            <Icon iconName="expandLess" />
                          </div>
                          <div className="flex group-open:hidden">
                            <Icon iconName="expandMore" />
                          </div>
                        </div>
                      </summary>
                      <p className="text-copy3 font-normal">{info.text}</p>
                    </details>
                  )
                })}
              </div>
              <div
                className={cn(
                  'flex w-full flex-col-reverse items-center justify-center border-t border-t-border-input',
                  'px-6 py-4 xl:flex-row xl:justify-end xl:gap-8',
                )}
              >
                <Button
                  size="xsmall"
                  variation="borderless"
                  onClick={closeModal}
                >
                  Voltar
                </Button>
                <LinkButton
                  to="https://chat.whatsapp.com/KHa8qGJRc5j4jWmURFnU2B"
                  target="_blank"
                  size="xsmall"
                  className="w-full xl:w-auto"
                >
                  <Icon iconName="whatsApp" className="fill-text-onPrimary" />
                  Entre no canal do whatsapp
                </LinkButton>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  )
}

export default InsidersCard
