// Libs
import { cva } from 'class-variance-authority'

export const colorStyles = cva([''], {
  variants: {
    color: {
      default: 'text-text fill-icon',
      critical:
        'text-icon-critical fill-icon-critical hover:text-icon-critical',
      warning: 'text-icon-warning fill-icon-warning hover:text-icon-warning',
      success: 'text-icon-success fill-icon-success hover:text-icon-success',
    },
  },
  defaultVariants: {
    color: 'default',
  },
})
