// React
import { FC } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cn } from 'utils/helpers/classess'
import { toSnakeCase } from 'utils/functions'

// Components
import { Button, FormButton } from 'heeds-ds'

type Props = {
  disabled?: boolean
  form?: string
  loading?: boolean
  primaryTitleButton?: string
  secundaryTitleButton?: string
  twoButtons?: boolean
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
}

const FooterActionButton: FC<Props> = ({
  disabled,
  form,
  loading,
  primaryTitleButton,
  secundaryTitleButton,
  onClick,
  twoButtons,
}) => {
  return (
    <div
      className={cn(
        'fixed bottom-0 right-0 flex w-full justify-between px-[37px] pb-[23px] pt-3 backdrop-blur-[1.5px]',
        'xl:right-12 xl:w-[calc(100%-96px-9.6rem)] xl:px-16 xl:py-3',
      )}
    >
      {twoButtons && (
        <Button
          cancel={secundaryTitleButton === 'Cancelar'}
          onClick={onClick}
          size="small"
          track={buttonClickTracking}
          trackName={`secondary_footer_${toSnakeCase(secundaryTitleButton)}`}
          variation="outlined"
        >
          {secundaryTitleButton}
        </Button>
      )}
      <FormButton
        track={buttonClickTracking}
        trackName={`primary_footer_${toSnakeCase(primaryTitleButton)}`}
        disabled={disabled}
        form={form}
        loading={loading}
        size="small"
        className={!twoButtons ? 'm-auto w-[187px] md:ml-auto md:mr-0' : ''}
      >
        {primaryTitleButton}
      </FormButton>
    </div>
  )
}

export default FooterActionButton
