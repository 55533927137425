//Models
import { TAthleteCalendar } from 'models'

//React
import { FC, useContext, useEffect, useState } from 'react'

// Misc
import { cn } from 'utils/helpers/classess'

// Libraries
import { ThemeContext } from 'styled-components'

// Components
import { Avatar, Icon } from 'heeds-ds'

type Props = {
  conflict?: boolean
  conflictList: TAthleteCalendar[]
  handleConflictedTime?: (item: TAthleteCalendar) => void
}

const ConflictCard: FC<Props> = ({
  conflict,
  conflictList,
  handleConflictedTime,
}: Props) => {
  const theme = useContext(ThemeContext)
  const [showConflictCard, setShowConflictCard] = useState(0)

  useEffect(() => {
    setShowConflictCard(0)
  }, [conflictList])

  const isConflicting = conflictList?.length > 0 && conflict

  return (
    <div className="flex flex-col">
      <div
        className={cn('flex w-full items-center justify-center', {
          'w-[390px]': isConflicting,
        })}
      >
        {
          <p
            data-testid="conflict-message"
            className={cn('mb-[68px] mt-44 items-center text-copy3', {
              'items-start mt-5 mb-6': isConflicting,
            })}
          >
            {isConflicting
              ? 'O horário atual que você escolheu está em conflito com outro aluno já adicionado:'
              : 'Não existe conflito com esse horário.'}
          </p>
        }
      </div>
      <div className="flex items-center justify-center">
        {conflictList?.map((item, index) => {
          return index === showConflictCard ? (
            <div key={index} className="flex items-center justify-center">
              {showConflictCard > 0 ? (
                <div
                  onClick={() => setShowConflictCard(showConflictCard - 1)}
                  className="mr-[22px] cursor-pointer"
                >
                  <Icon
                    iconName="chevronLeft"
                    color={theme.colors.icon.default}
                  />
                </div>
              ) : (
                <div className="flex w-[42px]" />
              )}
              <div
                data-testid="conflict_card"
                onClick={() => handleConflictedTime?.(item)}
                className={cn(
                  'flex max-w-[338px] cursor-pointer flex-col rounded-4 border border-icon-critical',
                  'bg-surface p-4 shadow-[0px_3px_7px_rgba(0,0,0,0.05)]',
                  { 'border-icon': item.new },
                )}
              >
                <div className="flex">
                  <div>
                    <Avatar
                      scale="small"
                      name={item.athlete.toString()}
                      className="mr-6"
                    />
                  </div>
                  <div className="flex flex-col items-start">
                    <p className="mb-2 text-copy1 font-bold text-text-secondary">
                      {item.athlete}
                    </p>
                    <p className="mb-2 text-copy1 text-text-secondary">
                      {item.day}, {item.start}-{item.end}
                    </p>
                    <p className="text-copy2 text-text-secondary">
                      {item.location}
                    </p>
                  </div>
                </div>
              </div>
              {conflictList.length - 1 > showConflictCard ? (
                <div
                  onClick={() => setShowConflictCard(showConflictCard + 1)}
                  className="ml-[22px] cursor-pointer"
                >
                  <Icon
                    iconName="chevronRight"
                    color={theme.colors.icon.default}
                  />
                </div>
              ) : (
                <div className="flex w-[42px]" />
              )}
            </div>
          ) : null
        })}
      </div>
    </div>
  )
}

export default ConflictCard
