// Models
import { IUser } from 'storage/auth/models'

const isAValidEnvironment = process.env.REACT_APP_ENV === 'production'

export const loadPendoScript = () => {
  if (!isAValidEnvironment) return

  const script = document.createElement('script')
  script.src = `https://cdn.eu.pendo.io/agent/static/${process.env.REACT_APP_PENDO_API_KEY}/pendo.js`
  script.async = true
  document.body.appendChild(script)
}

export const pendoInitialize = (userData: IUser) => {
  const { email, profileId } = userData
  if (profileId && isAValidEnvironment) {
    try {
      window.pendo.initialize({
        apiKey: process.env.REACT_APP_PENDO_API_KEY,
        account: {
          id: profileId,
        },
        visitor: {
          email,
          heeds_email: email.includes('@heeds.tech'),
          id: profileId,
        },
      })
    } catch (error) {
      console.error('Pendo initialize error', error)
    }
  }
}
