// Components
import { Skeleton } from 'heeds-ds'

const ProfileTabsLoader = () => {
  return (
    <div className="flex w-full flex-row gap-2 p-4 md:flex-col">
      <div className="flex w-full flex-col gap-2 md:flex-row">
        <Skeleton className="h-40 w-full rounded-2 xl:w-[425px]" />
        <Skeleton className="h-40 w-full rounded-2 xl:w-[425px]" />
      </div>
      <div className="hidden flex-row gap-2 md:flex">
        <Skeleton className="h-40 w-[425px] rounded-2" />
        <Skeleton className="h-40 w-[425px] rounded-2" />
      </div>
    </div>
  )
}

export default ProfileTabsLoader
