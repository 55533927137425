// Models
import { IFilter } from 'heeds-ds/src/components/Inputs/InputSearch'
import {
  IWorkoutRoutine,
  IWorkoutRoutineState,
} from 'storage/workoutRoutine/models'
import { IWorkoutRoutineListPayload } from 'services/workoutRoutine/@types'
import { TModalMoreOption } from 'components/modals/ModalMoreOptions'
import { TRoutineDificulty, TRoutineGoal, TRoutineOrdering } from 'models'
import IStore from 'lib/redux/models'

// React
import React, { FC, useCallback, useContext, useMemo, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { generatePath, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { convertOptionToFilter } from 'utils/functions'
import { triggerDeleteWorkoutRoutine } from 'storage/workoutRoutine/duck'
import { triggerSendRoutineToAthlete } from 'storage/workoutModel/duck'
import { urls } from 'routes/paths'
import { useMediaQuery, useModal } from 'hooks'

// Components
import * as Styled from './styled'
import {
  Aligner,
  Body,
  Button,
  Chip,
  CommonList,
  DropdownMenu,
  Icon,
  IconButton,
  InputSearch,
  Loading,
} from 'heeds-ds'
import {
  GlobalRoutineItem,
  ModalAthleteRoutineFilters,
  ModalDelete,
  ModalMoreOptions,
  ModalShareRoutine,
} from 'components'
import Loader from 'components/lists/GlobalRoutineItem/loader'

// Assets
import {
  DATE_RANGE_OPTIONS,
  DIFFICULTY_OPTIONS,
  GOAL_OPTIONS,
} from 'utils/constants'

// Constants
const COLUMNS = [
  { label: 'Alunos' },
  { label: 'Nome da rotina' },
  { label: 'Período' },
  { label: 'Dias a vencer' },
  { label: 'Nível' },
  { label: 'Ações' },
]

const HEADER_GRID_TEMPLATE =
  'grid-cols-[minmax(200px,1fr)minmax(240px,1fr)minmax(200px,1fr)minmax(150px,1fr)minmax(10px,0.5fr)minmax(8px,0.35fr)]'

const ORDER_FILTER = [
  { label: 'Mais recentes', value: 'start_date', param: 'ordering' },
  { label: 'A-Z', value: 'name', param: 'ordering' },
  { label: 'Z-A', value: '-name', param: 'ordering' },
]
const ROUTINE_FILTERS = [
  ...convertOptionToFilter(DATE_RANGE_OPTIONS, 'date_range'),
  ...convertOptionToFilter(DIFFICULTY_OPTIONS, 'difficulty'),
  ...convertOptionToFilter(GOAL_OPTIONS, 'goal'),
]

const SECTION_TITLE = {
  current: 'Rotinas de treino em andamento',
  scheduled: 'Próximas rotinas de treinos',
  history: 'Rotinas de treinos encerradas',
}

const SECTION_SUBTITLE = {
  current:
    'Acompanhe as rotinas de exercícios que seus alunos estão executando.',
  scheduled:
    'Acesse as rotinas agendadas e garanta que os treinos estejam prontos.',
  history:
    'Revise as rotinas de treinos anteriores para analisar o progresso dos seus alunos.',
}

export interface IFilterFields {
  athlete_name?: string
  date_range?: string
  difficulty?: TRoutineDificulty
  goal?: TRoutineGoal
  ordering?: TRoutineOrdering
}

interface Props {
  filters: IWorkoutRoutineListPayload
  handleEndReached: () => void
  isActive: (name: string, value: string) => boolean
  onClickFilter: (filters: IFilter) => void
  onFiltersChange: (filters: IFilterFields) => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  openNewRoutineModal: () => void
  refreshPage: () => void
}

const GlobalRoutineList: FC<Props> = ({
  filters,
  handleEndReached,
  isActive,
  onClickFilter,
  onFiltersChange,
  onSearchChange,
  openNewRoutineModal,
  refreshPage,
}) => {
  const { next, loading, workoutRoutines } = useSelector<
    IStore,
    IWorkoutRoutineState
  >((state) => state.workoutRoutine)
  const { closeModal, openModal, isVisible } = useModal()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  const [selected, setSelected] = useState<IWorkoutRoutine | undefined>()

  const hasRoutines = useMemo(
    () =>
      Boolean(workoutRoutines?.length) ||
      ROUTINE_FILTERS.some((item) => isActive(item.param, item.value)) ||
      (filters.search && filters.search.toString() !== ''),
    [filters.search, isActive, workoutRoutines?.length],
  )

  const handleSendRoutineToLibrary = useCallback(
    (routine_pk: number) => {
      dispatch(triggerSendRoutineToAthlete({ routine_pk, to_library: true }))
    },
    [dispatch],
  )

  const handleShareRoutine = (athletes: number[]) => {
    selected &&
      dispatch(
        triggerSendRoutineToAthlete({ routine_pk: selected.id, athletes }),
      )
  }

  const options: TModalMoreOption[] = [
    {
      icon: 'edit',
      label: 'Editar',
      onClick: () =>
        selected?.athlete_profile &&
        onEditRoutine(selected.athlete_profile, selected.id),
    },
    {
      icon: 'book',
      label: 'Adicionar a biblioteca',
      onClick: () => selected && handleSendRoutineToLibrary(selected.id),
    },
    {
      icon: 'send',
      label: 'Enviar para um aluno',
      onClick: () => openModal('send-routine-modal'),
    },
    {
      icon: 'delete',
      color: 'critical',
      label: 'Excluir',
      onClick: () => openModal('delete-routine-modal'),
    },
  ]

  const handleFiltersChange = (modalFilters: IFilterFields) => {
    onFiltersChange(modalFilters)
    closeModal()
  }

  const navigateToRoutine = useCallback(
    (athleteId: number, routineId: number) => {
      navigate(
        generatePath(urls.workoutRoutine, {
          id: athleteId,
          routine_id: routineId,
        }),
      )
    },
    [navigate],
  )

  const orderingFilters = useMemo(
    () =>
      ORDER_FILTER.map((orderItem) => ({
        ...orderItem,
        active: filters?.ordering?.toString() === orderItem.value,
        onClick: () => {
          if (!isTablet) closeModal()
          onClickFilter(orderItem)
        },
      })),
    [closeModal, filters?.ordering, isTablet, onClickFilter],
  )

  const onEditRoutine = useCallback(
    (athleteId: number, routineId: number) => {
      navigate(
        generatePath(urls.workoutRoutineEdit, {
          id: athleteId,
          routine_id: routineId,
        }),
      )
      closeModal()
    },
    [closeModal, navigate],
  )

  const onRemoveRoutine = () => {
    selected &&
      dispatch(
        triggerDeleteWorkoutRoutine({
          routine_pk: selected.id,
          successCallback: refreshPage,
        }),
      )
    setSelected(undefined)
  }

  const openFilterModal = () => openModal('routine-filters-modal')

  const renderFilters = useMemo(() => {
    return filters
      ? ROUTINE_FILTERS.map((item) => {
          const active = isActive(item.param, item.value)
          if (active)
            return (
              <Chip
                active
                closeIcon
                key={item.label}
                label={item.label}
                onClick={() => onClickFilter(item)}
                scale="small"
              />
            )
        })
      : null
  }, [filters, isActive, onClickFilter])

  const renderRoutineItems = useCallback(
    (item: IWorkoutRoutine) => {
      return (
        <GlobalRoutineItem
          routine={item}
          key={item.id}
          onClick={() =>
            item?.athlete_profile &&
            navigateToRoutine(item.athlete_profile, item.id)
          }
          onEdit={() =>
            item?.athlete_profile &&
            onEditRoutine(item.athlete_profile, item.id)
          }
          onRemove={() => {
            setSelected(item)
            openModal('delete-routine-modal')
          }}
          onShare={() => {
            setSelected(item)
            openModal('send-routine-modal')
          }}
          sendToLibrary={() => handleSendRoutineToLibrary(item.id)}
          openOptions={() => {
            setSelected(item)
            openModal('more-options-routine-modal')
          }}
        />
      )
    },
    [handleSendRoutineToLibrary, navigateToRoutine, onEditRoutine, openModal],
  )

  if (loading && !workoutRoutines) {
    return <Loading active />
  }

  return (
    <Styled.Content id="athlete-routines-content">
      <Styled.SearchContainer>
        <Aligner flex="column" gap={`0.${isTablet ? 4 : 8}rem`}>
          <Body weight={600}>
            {SECTION_TITLE[filters?.routine_status || 'current']}
          </Body>

          <Body type="copy3" color={theme.colors.text.subdued}>
            {SECTION_SUBTITLE[filters?.routine_status || 'current']}
          </Body>
        </Aligner>

        {hasRoutines && (
          <Aligner gap="3.2rem" width={isTablet ? '37.5rem' : '100%'}>
            <InputSearch
              onChange={onSearchChange}
              onClick={openFilterModal}
              placeholder="Pesquisar"
              scale="small"
              value={filters?.search ?? ''}
              className="w-full"
            />

            {!isTablet && (
              <IconButton
                iconName="swapVert"
                variation="main"
                onClick={() => openModal('ordering-filter-modal')}
              />
            )}
          </Aligner>
        )}
      </Styled.SearchContainer>

      {hasRoutines && (
        <Styled.FiltersScrollContainer>
          <Aligner gap="1.6rem">{renderFilters}</Aligner>

          {isTablet && (
            <DropdownMenu items={orderingFilters}>
              <Button size="xsmall" variation="borderless">
                <Icon
                  iconName="swapVert"
                  color={theme.colors.interactive.default}
                />
                Ordenar
              </Button>
            </DropdownMenu>
          )}
        </Styled.FiltersScrollContainer>
      )}

      {!hasRoutines ? (
        <Styled.EmptyContainer isDesktop={isTablet}>
          <Styled.EmptyText>
            Você ainda não cadastrou nenhuma rotina de treino.
          </Styled.EmptyText>

          <Button
            onClick={openNewRoutineModal}
            size="small"
            track={buttonClickTracking}
            trackName="navigate_to_create_routine"
          >
            <Styled.AddIcon />
            Criar nova rotina
          </Button>
        </Styled.EmptyContainer>
      ) : (
        <CommonList<IWorkoutRoutine>
          columnsFormat={HEADER_GRID_TEMPLATE}
          columns={COLUMNS}
          data={workoutRoutines || []}
          onEndReached={handleEndReached}
          loaderComponent={<Loader />}
          padding="py-4 px-6"
          showHeader={isTablet}
          renderItem={renderRoutineItems}
          hasMore={!!next}
        />
      )}

      {!isTablet && hasRoutines && (
        <Styled.ContainerBottom>
          <Button
            onClick={openNewRoutineModal}
            size="small"
            track={buttonClickTracking}
            trackName="navigate_to_create_routine"
            className="w-full"
          >
            <Styled.AddIcon />
            Adicionar rotina
          </Button>
        </Styled.ContainerBottom>
      )}

      {!isTablet && isVisible === 'more-options-routine-modal' && (
        <ModalMoreOptions options={options} />
      )}

      {!isTablet && isVisible === 'ordering-filter-modal' && (
        <ModalMoreOptions options={orderingFilters} />
      )}

      {isVisible === 'send-routine-modal' && (
        <ModalShareRoutine onSend={handleShareRoutine} />
      )}

      {isVisible === 'routine-filters-modal' && (
        <ModalAthleteRoutineFilters
          handleCancel={() => closeModal()}
          onConfirm={handleFiltersChange}
          type="global"
        />
      )}

      {isVisible === 'delete-routine-modal' && (
        <ModalDelete
          confirmTitle="Excluir"
          description="Tem certeza que deseja excluir esta rotina?"
          onConfirm={onRemoveRoutine}
          title="Excluir Rotina"
        />
      )}
    </Styled.Content>
  )
}

export default GlobalRoutineList
