// React
import { FC } from 'react'

type Props = {
  day?: string
  end?: string
  location?: string
  start?: string
}

const AthleteHoursCard: FC<Props> = ({ day, end, location, start }) => {
  return (
    <div
      data-testid="container"
      className="flex flex-col gap-1 rounded-2px border-l-4 border-icon px-[22px] pb-4 pt-4"
    >
      <p className="text-copy4 font-semibold text-text-secondary">{day}</p>
      <p className="text-copy4 text-text-subdued">
        {start} às {end}
      </p>
      <p className="text-copy4 text-text-subdued">{location}</p>
    </div>
  )
}

export default AthleteHoursCard
