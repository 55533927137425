// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { cn } from 'utils/helpers/classess'
import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import { Skeleton } from 'heeds-ds'

export type Props = {
  gridTemplate?: string
  padding?: string
}

const Loader: FC<Props> = ({ gridTemplate, padding }) => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  return (
    <div>
      {isDesktop ? (
        <div className={cn('grid w-full', gridTemplate)}>
          <div className={padding}>
            <Skeleton className="h-5" />
          </div>
          <div className={padding}>
            <Skeleton className="h-5" />
          </div>
          <div className={padding}>
            <Skeleton className="h-5" />
          </div>
          <div className={padding}>
            <Skeleton className="h-5" />
          </div>
        </div>
      ) : (
        <div className="mx-6 my-2 flex rounded-2 border border-border-input p-4">
          <div className="flex flex-col gap-1">
            <div className="mb-1">
              <Skeleton className="h-5 w-[220px]" />
            </div>
            <Skeleton className="h-5 w-40" />
            <Skeleton className="h-5 w-[130px]" />
            <Skeleton className="h-5 w-[120px]" />
            <Skeleton className="h-5 w-[160px]" />
          </div>
          <div className="ml-auto">
            <Skeleton className="h-10 w-10" />
          </div>
        </div>
      )}
    </div>
  )
}

export default Loader
