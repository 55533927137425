// Models
import { TMenuDropdownOption } from 'heeds-ds/src/models'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'

// React
import { FC } from 'react'

// Misc
import { dateFormatEnglishToPortuguese } from 'utils/functions'
import { cn } from 'utils/helpers/classess'

// Components
import { DropdownMenu, IconButton } from 'heeds-ds'

export type Props = {
  routineTemplate: IWorkoutRoutine
  onEdit: (routineId: number) => void
  onRemove: () => void
  onShare: () => void
  onDuplicated?: (routine: IWorkoutRoutine) => void
  gridTemplate: string
  padding?: string
}

const Desktop: FC<Props> = ({
  routineTemplate,
  onEdit,
  onRemove,
  onShare,
  onDuplicated,
  gridTemplate,
  padding,
}) => {
  const { id, name, personal_profile, start_date } = routineTemplate

  const options: TMenuDropdownOption[] = [
    {
      icon: 'edit',
      label: 'Editar',
      onClick: () => onEdit(id),
    },
    {
      icon: 'share',
      label: 'Compartilhar',
      onClick: onShare,
    },
    {
      color: 'critical',
      icon: 'delete',
      label: 'Excluir',
      onClick: onRemove,
    },
  ]

  const systemOptions: TMenuDropdownOption[] = [
    {
      icon: 'contentCopy',
      label: 'Duplicar',
      onClick: () => onDuplicated?.(routineTemplate),
    },
    {
      icon: 'share',
      label: 'Compartilhar',
      onClick: onShare,
    },
  ]

  return (
    <div
      className={cn(
        'grid w-full cursor-pointer items-center border-b border-b-border-input bg-surface',
        gridTemplate,
      )}
    >
      <div className={cn(padding)}>
        <p className="text-copy4">{name}</p>
      </div>
      <div className={cn(padding)}>
        <p className="text-copy4">
          {dateFormatEnglishToPortuguese(start_date) || '-'}
        </p>
      </div>
      <div className={cn(padding)}>
        <p className="text-copy4">
          {personal_profile === 0 ? 'CADU (sistema)' : 'Você'}
        </p>
      </div>
      <div className={cn(padding)}>
        <DropdownMenu items={personal_profile === 0 ? systemOptions : options}>
          <IconButton
            size="small"
            iconName="moreVertical"
            trackName="open_anamnese_model_options"
          />
        </DropdownMenu>
      </div>
    </div>
  )
}

export default Desktop
