// Models
import { IExerciseData } from 'storage/exercise/models'

// React
import { FC } from 'react'

// Misc
import { isVideo } from 'utils/functions'

// Components
import { Modal } from 'components'

interface IExerciseProps {
  exercise?: IExerciseData
  handleClose: () => void
}

const ModalExercise: FC<IExerciseProps> = ({ exercise, handleClose }) => {
  const title = exercise
    ? exercise.name[0].toUpperCase() + exercise?.name.slice(1)
    : ''

  const file = exercise?.file as string

  return (
    <Modal maxWidth="54rem" onClose={handleClose} title={title}>
      {isVideo(file) ? (
        <div className="flex items-center justify-center p-4 xl:h-[500px]">
          <video
            controls
            src={file}
            className="h-[60vh] w-full rounded-2 bg-black xl:h-full xl:w-[500px]"
          />
        </div>
      ) : (
        <img
          src={file}
          alt={exercise?.name}
          className="w-full border-t border-t-border-input object-contain px-8 pb-20 pt-4 xl:h-[500px] xl:w-[500px]"
        />
      )}
    </Modal>
  )
}

export default ModalExercise
