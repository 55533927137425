// React
import { HTMLAttributes, ReactNode, useContext, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { cn } from '../../utils/classes'
import { tagContainerStyles } from './styles'

// Components
import Icon from '../UI/Icons'

type Props = HTMLAttributes<HTMLDivElement> & {
  active?: boolean
  borderPosition?: 'top' | 'right' | 'bottom' | 'left'
  children?: ReactNode | ReactNode[]
  className?: string
  disabled?: boolean
  expandable?: boolean
  internalContent?: ReactNode | ReactNode[]
}

const Tag = ({
  active,
  borderPosition = 'left',
  children,
  className,
  disabled,
  expandable,
  internalContent,
  ...divProps
}: Props) => {
  const [open, setOpen] = useState(false)
  const theme = useContext(ThemeContext)

  const toggleExpand = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setOpen((prev) => !prev)
  }

  return (
    <div
      data-testid="container"
      {...divProps}
      className={cn(
        tagContainerStyles({ border: borderPosition }),
        {
          'border-icon hover:border-icon-critical': active,
          'cursor-not-allowed opacity-50 pointer-events-none border-icon-disabled':
            disabled,
        },
        className,
      )}
    >
      <div className="flex justify-between">
        {children}
        {expandable && (
          <div
            className="cursor-pointer"
            data-testid="expand-icon"
            onClick={toggleExpand}
          >
            <Icon
              color={theme.colors.icon.default}
              iconName={open ? 'expandLess' : 'expandMore'}
              size={20}
            />
          </div>
        )}
      </div>
      {expandable && (
        <div
          data-testid="collapsable-container"
          onClick={toggleExpand}
          className={cn(
            'w-full overflow-hidden transition-all duration-300 ease-in-out',
            { 'pt-6 max-h-min': open },
          )}
        >
          {internalContent}
        </div>
      )}
    </div>
  )
}

export default Tag
