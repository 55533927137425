// Models
import { TExercisesGroupFilter } from 'models'

// React
import { FC, useLayoutEffect, useState } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { useDebounceFunction, useModal } from 'hooks'

// Components
import { Button, Chip, InputSearch } from 'heeds-ds'
import Modal from 'components/modals/Modal'

type Props = {
  filters: TExercisesGroupFilter[]
  handleClose?: () => void
  onChange: (filters: TExercisesGroupFilter[]) => void
}

const ModalMuscleGroups: FC<Props> = ({ filters, handleClose, onChange }) => {
  const { closeModal } = useModal()
  const [name, setName] = useState<string>('')

  const [stateFilters, setStateFilters] = useState(filters)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    setName(newValue)
    debounceFunction(newValue)
  }

  const newFunction = (newValue: string) => {
    const normalize = (str: string) =>
      str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    const newList = filters.filter((filter) => {
      return normalize(filter.label).includes(normalize(newValue))
    })

    setStateFilters(newList)
  }

  const debounceFunction = useDebounceFunction(newFunction, 1000)

  const onClickFilter = (label: string) => {
    setStateFilters((oldFilters) =>
      oldFilters.map((filter) => {
        if (filter.label === label) return { ...filter, active: !filter.active }
        return filter
      }),
    )
  }

  const onSave = () => {
    onChange(stateFilters)
    if (handleClose) return handleClose()
    closeModal()
  }

  useLayoutEffect(() => {
    setStateFilters(filters)
  }, [filters])

  const groupByParam = (
    filters: TExercisesGroupFilter[],
    parameter: string,
  ) => {
    const list = filters.filter((item) => item.param === parameter)

    return list
  }

  const musclesGroup = groupByParam(stateFilters, 'muscle')
  const difficultyGroup = groupByParam(stateFilters, 'difficulty')
  const libraryGroup = groupByParam(stateFilters, 'library')

  return (
    <Modal onClose={handleClose} isSlide={true}>
      <div
        data-testid="modal-muscle-group-container"
        className="relative flex h-[calc(100%-48px)] w-screen flex-col p-[2rem_2.4rem] lg:h-full
     lg:w-[470px]"
      >
        <header className="mb-4 flex flex-col gap-2">
          <h2 className="text-center font-roboto text-[18px] font-semibold  lg:text-start">
            Filtros
          </h2>

          <p className="font-roboto text-[16px] font-normal leading-[18.75px] text-text-subdued">
            Selecione quais grupos musculares ou tipo de equipamento você
            precisa.
          </p>
        </header>
        <InputSearch onChange={handleChange} value={name} scale="small" />
        <div className="mt-2 flex max-h-[calc(100%-220px)] overflow-auto">
          <div className="flex w-full flex-col">
            {musclesGroup.length > 0 && (
              <div className="mb-2 h-fit min-w-full px-1">
                <h1 className="font-roboto text-[14px] font-semibold">
                  Grupo Muscular
                </h1>
                <div className="z-50 flex w-full flex-wrap gap-4 gap-y-4">
                  {musclesGroup.map((filter) => {
                    return (
                      <Chip
                        label={filter.label}
                        key={filter.label}
                        onClick={() => onClickFilter(filter.label)}
                        active={filter.active}
                        scale="small"
                      />
                    )
                  })}
                </div>
              </div>
            )}

            {difficultyGroup.length > 0 && (
              <div className="mb-2 h-fit min-w-full px-1">
                <h1 className="font-roboto text-[14px] font-semibold">
                  Dificuldade
                </h1>
                <div className="z-50 flex w-full flex-wrap gap-4 gap-y-4">
                  {difficultyGroup.map((filter) => {
                    return (
                      <Chip
                        label={filter.label}
                        key={filter.label}
                        onClick={() => onClickFilter(filter.label)}
                        active={filter.active}
                      />
                    )
                  })}
                </div>
              </div>
            )}

            {libraryGroup.length > 0 && (
              <div className="mb-2 h-fit min-w-full px-1">
                <h1 className="font-roboto text-[14px] font-semibold">
                  Biblioteca
                </h1>
                <div className="z-50 flex w-full flex-wrap gap-4 gap-y-4">
                  {libraryGroup.map((filter) => {
                    return (
                      <Chip
                        label={filter.label}
                        key={filter.label}
                        onClick={() => onClickFilter(filter.label)}
                        active={filter.active}
                      />
                    )
                  })}
                </div>
              </div>
            )}

            {/* <button onClick={callLibrary}>LIBRARY</button> */}
          </div>
        </div>
      </div>
      <div
        className="
        fixed bottom-0 left-0 flex w-full 
        items-center
        justify-center border-t-2 border-solid
        border-border-input bg-white p-[1.6rem_2.4rem] 
        lg:absolute
        lg:bottom-0
         "
      >
        <Button
          data-testid="modal-muscle-group-save-button"
          onClick={onSave}
          size="small"
          track={buttonClickTracking}
          trackName="update_muscle_group_filters"
          className="z-50 w-full"
        >
          Filtrar
        </Button>
      </div>
    </Modal>
  )
}

export default ModalMuscleGroups
