// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { cn } from 'utils/helpers/classess'

// Components
import * as Styled from './styled'
import { Body } from 'heeds-ds'

type Props = {
  className?: string
  onClick?: () => void
  percent?: number
  reference?: string
  title?: string
  value?: string
}

const FinancialInfoTag: FC<Props> = ({
  className,
  onClick,
  percent,
  reference,
  title,
  value,
}) => {
  const { colors } = useContext(ThemeContext)

  const isPositive = percent !== undefined && percent >= 0
  const percentColor = colors.text[isPositive ? 'success' : 'critical']
  const prefix = 'financial-info-tag'

  return (
    <div
      className={cn(
        'flex w-full justify-between rounded-1 border border-border-input',
        'bg-surface p-4 shadow-sm',
        { 'cursor-pointer': !!onClick },
        className,
      )}
      data-testid={`${prefix}-container`}
      onClick={onClick}
    >
      <Styled.LeftColumn>
        <Styled.Title>{title}</Styled.Title>

        <Styled.Value>{value}</Styled.Value>
      </Styled.LeftColumn>

      <Styled.RightColumn>
        <Styled.Month>{reference}</Styled.Month>

        {percent !== undefined && (
          <Styled.Percent
            data-testid={`${prefix}-percent`}
            negative={!isPositive}
          >
            {isPositive ? <Styled.ArrowUp /> : <Styled.ArrowDown />}

            <Body type="copy4" color={percentColor}>
              {percent + '%'}
            </Body>
          </Styled.Percent>
        )}
      </Styled.RightColumn>
    </div>
  )
}

export default FinancialInfoTag
