// Models
import { TAthleteCalendar } from 'models'

// React
import { FC, useEffect, useRef, useState, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import {
  getMonthName,
  upperCaseFirstLetter,
  weekDays,
  addLeftZero,
} from 'utils/calendarUtils'

// Components
import { Body } from 'components/UI/Typography'
import { Button, IconButton, LoadingSpinner } from 'heeds-ds'
import TimesCard from '../TimeCard'
import * as Styled from './styled'
import AthleteCard from './AthleteCard'

// Assets
import { timeObjectList } from 'utils/constants'
import { useModal } from 'hooks'

type calendarType = {
  id: number
  isToday: boolean
  currentWeek: number
  day: number
  month: number
  weekDay: number
  year: number
  allAthletesList: TAthleteCalendar[]
  sameTimeList: TAthleteCalendar[]
}

type Props = {
  displayMode: (type: string) => void
  dateCallback: (initialDate: string) => void
  fullScreenMode: boolean
  savedAthletes: TAthleteCalendar[]
  startDate: string
  calendarList: calendarType[]
  loading?: boolean
}

const WeekView: FC<Props> = (props: Props) => {
  const {
    dateCallback,
    displayMode,
    startDate,
    fullScreenMode,
    savedAthletes,
    calendarList,
    loading,
  } = props
  const { closeModal } = useModal()
  const theme = useContext(ThemeContext)
  const [startYear, startMonth, startDay] = startDate?.split('-') || ''
  const date = new Date()
  const ref = useRef<HTMLDivElement>(null)
  const timeList = timeObjectList
  const todayDate = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}`

  const allAthletes = savedAthletes.sort(
    (a, b) =>
      parseInt(a.start.split(':').toString()) -
      parseInt(b.start.split(':').toString()),
  )
  const [selectedDate, setSelectedDate] = useState([
    parseInt(startDay),
    parseInt(startMonth),
    parseInt(startYear),
  ])
  const [selectedDay, selectedMonth, selectedYear] = selectedDate
  const [showCard, setShowCard] = useState(false)
  const [headerTitle, setHeaderTitle] = useState('')
  const [selectedDayOfTheWeek, setSelectedDayOfTheWeek] = useState(
    new Date(startDate).getDay() || date.getDay(),
  )
  const [selectedWeek, setSelectedWeek] = useState(
    calendarList.filter(function (is) {
      return is.isToday === true
    })[0]?.currentWeek,
  )
  const CalendarListSliced = calendarList.filter(function (is) {
    return is.currentWeek === selectedWeek
  })
  const weeks = CalendarListSliced
  const isToday = CalendarListSliced.some(function (els) {
    return els.day === date.getDate() && els.month === date.getMonth() + 1
  })

  const scrollToCurrentTime = () => {
    ref.current &&
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
  }

  useEffect(() => {
    scrollToCurrentTime()
  }, [])

  const handleToday = () => {
    dateCallback && dateCallback(todayDate)
    setSelectedWeek(
      calendarList.filter((week) => {
        return date.getMonth() + 1 === week.month && week.isToday
      })[0]?.currentWeek,
    )
    scrollToCurrentTime()
  }

  const handlePreviousNext = (previous?: boolean) => {
    setShowCard(false)
    setSelectedWeek(weeks[0].currentWeek)
    const lastFirstWeek = () => {
      previous
        ? calendarList.length < 35
          ? setSelectedWeek(6)
          : setSelectedWeek(5)
        : setSelectedWeek(1)
      setSelectedDate([
        selectedDay,
        previous ? selectedMonth - 1 : selectedMonth + 1,
        selectedYear,
      ])
      if (selectedMonth === 1 && previous) {
        setSelectedDate([selectedDay, 12, selectedYear - 1])
        dateCallback && dateCallback(`${selectedYear - 1}-12-${weeks[0].day}`)
      } else {
        previous &&
          dateCallback &&
          dateCallback(`${selectedYear}-${selectedMonth - 1}-${weeks[0].day}`)
      }
      if (selectedMonth === 12 && !previous) {
        setSelectedDate([selectedDay, 1, selectedYear + 1])
        dateCallback && dateCallback(`${selectedYear + 1}-1-${weeks[0].day}`)
      } else {
        !previous &&
          dateCallback &&
          dateCallback(`${selectedYear}-${selectedMonth + 1}-${weeks[0].day}`)
      }
    }
    previous
      ? setSelectedWeek(selectedWeek - 1)
      : setSelectedWeek(selectedWeek + 1)

    if ((!previous && selectedWeek === 5) || (selectedWeek === 1 && previous)) {
      lastFirstWeek()
    }
  }

  const handleDay = (
    day: number,
    month: number,
    year: number,
    weekDay: number,
    showCard: boolean,
  ) => {
    setSelectedDate([day, month, year])
    getMonthName(month)
    setSelectedDayOfTheWeek(weekDay)
    setShowCard(showCard)
    dateCallback && dateCallback(`${year}-${month}-${day}`)
  }

  useEffect(() => {
    const handleHeader = (week: calendarType[]) => {
      {
        week?.map((item) => {
          return week[1]?.month === item.month
            ? setHeaderTitle(`${week[0].day}
             a  
             ${week[week.length - 1].day} 
             ${upperCaseFirstLetter(getMonthName(item.month - 1))} 
             de
             ${item.year}`)
            : setHeaderTitle(`${week[0].day}
            de  ${upperCaseFirstLetter(getMonthName(item.month - 2)).substring(
              0,
              3,
            )} a
            ${week[week.length - 1].day} 
            ${upperCaseFirstLetter(getMonthName(item.month - 1)).substring(
              0,
              3,
            )} 
            de
            ${item.year}`)
        })
      }
    }
    handleHeader(weeks)
  })

  const renderHeader = () => {
    return (
      <Styled.Header>
        <Styled.DateContainer>
          <IconButton
            iconName="chevronLeft"
            onClick={() => handlePreviousNext(true)}
            size="xsmall"
            track={buttonClickTracking}
            trackName="display_previous_week"
          />
          <Styled.Title>
            <Body type="copy2" weight={500} color={theme.colors.text.secondary}>
              {headerTitle}
            </Body>
          </Styled.Title>
          <IconButton
            iconName="chevronRight"
            onClick={() => handlePreviousNext()}
            size="xsmall"
            track={buttonClickTracking}
            trackName="display_next_week"
          />
        </Styled.DateContainer>
        <Button
          onClick={handleToday}
          size="xsmall"
          track={buttonClickTracking}
          trackName="show_today_in_calendar"
          variation={isToday ? 'main' : 'outlined'}
          className="ml-auto rounded-[15px]"
        >
          <Body
            type="copy4"
            fontFamily="roboto"
            color={
              isToday ? theme.colors.text.onPrimary : theme.colors.text.subdued
            }
          >
            Hoje
          </Body>
        </Button>
        <Styled.DisplayTypeContainer>
          <Button
            onClick={() => displayMode?.('month')}
            size="xsmall"
            track={buttonClickTracking}
            trackName="display_month_view_in_calendar"
            variation="outlined"
            className="rounded-[15px]"
          >
            <Body
              type="copy4"
              fontFamily="roboto"
              color={theme.colors.text.default}
            >
              Mensal
            </Body>
          </Button>
          <Button
            onClick={() => displayMode?.('week')}
            size="xsmall"
            track={buttonClickTracking}
            trackName="display_week_view_in_calendar"
            className="rounded-[15px]"
          >
            <Body
              type="copy4"
              fontFamily="roboto"
              color={theme.colors.text.onPrimary}
            >
              Semanal
            </Body>
          </Button>
          <IconButton
            iconName="close"
            onClick={closeModal}
            size="small"
            track={buttonClickTracking}
            trackName="close_calendar_modal"
          />
        </Styled.DisplayTypeContainer>
      </Styled.Header>
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderWeekList = (week: any[]) => {
    return week.map((item, index) => {
      const thisDate = new Date(`${item.year}-${item.month}-${item.day}`)

      return (
        <Styled.DayColumn
          key={index}
          onClick={() =>
            handleDay(
              item.day,
              item.month,
              item.year,
              item.weekDay,
              item.allAthletesList.length > 0,
            )
          }
        >
          {timeList.map((time, index) => {
            const active =
              item.year === date.getFullYear() &&
              item.month === date.getMonth() + 1 &&
              item.day === date.getDate() &&
              time.replace(/:.*/, '') === addLeftZero(date.getHours(), 2)
            return (
              <Styled.HourLine
                key={index}
                distance={index}
                show={index % 2 == 0 ? true : false}
                minutes={date.getMinutes() * 1.75}
                active={active}
                ref={
                  time.replace(/:.*/, '') === addLeftZero(date.getHours(), 2)
                    ? ref
                    : null
                }
              />
            )
          })}
          {item.sameTimeList.length > 0 &&
            item.sameTimeList?.map((i: TAthleteCalendar, sameIndex: number) => {
              const thisTime = item?.sameTimeList[sameIndex]
              const avatarOrder = [...thisTime].sort(
                (a: { new: number }, b: { new: number }) => a.new - b.new,
              )
              const innerListOrdered = [...thisTime].sort(
                (a: { new: number }, b: { new: number }) => a.new - b.new,
              )
              const isNewAthlete = [...thisTime].some(
                (e: { new: boolean }) => e.new === true,
              )
              return (
                item.sameTimeList[sameIndex]?.length > 1 &&
                item.sameTimeList[sameIndex]?.map(
                  (sameTime: TAthleteCalendar) => {
                    const thisAthleteDate = sameTime.initialDate
                      ? new Date(sameTime.initialDate)
                      : new Date()
                    return sameTime.recurringTimes.includes(item.weekDay) &&
                      thisAthleteDate <= thisDate ? (
                      <AthleteCard
                        sameTime
                        isNewAthlete={isNewAthlete}
                        timePosition={sameIndex}
                        start={sameTime.start}
                        end={sameTime.end}
                        avatarOrder={avatarOrder}
                        innerStart={item.sameTimeList[sameIndex][0].start}
                        innerEnd={item.sameTimeList[sameIndex][0].end}
                        innerList={innerListOrdered}
                      />
                    ) : null
                  },
                )
              )
            })}
          {item.allAthletesList?.map(
            (itemSavedAthlete: TAthleteCalendar, savedIndex: number) => {
              const leftSpace =
                item.allAthletesList[savedIndex]?.startCompare >
                  item.allAthletesList[savedIndex - 1]?.startCompare &&
                item.allAthletesList[savedIndex]?.startCompare <
                  item.allAthletesList[savedIndex - 1]?.endCompare
              return (
                <AthleteCard
                  key={savedIndex}
                  leftSpace={leftSpace}
                  isNewAthlete={itemSavedAthlete.new}
                  timePosition={savedIndex}
                  start={itemSavedAthlete.start}
                  end={itemSavedAthlete.end}
                  athlete={itemSavedAthlete.athlete}
                />
              )
            },
          )}
        </Styled.DayColumn>
      )
    })
  }

  return (
    <Styled.CalendarContainer data-testid="weekView">
      {renderHeader()}
      {loading ? (
        <Styled.loadingContainer>
          <LoadingSpinner size="medium" color="border-icon" />
        </Styled.loadingContainer>
      ) : (
        <Styled.CalendarBody>
          <Styled.CalendarBodyInner>
            <Styled.WeekDays>
              {weekDays('long').map((week, index) => {
                const weekSplited = week.split('-feira')
                return (
                  <Body
                    type="small"
                    key={index}
                    weight={index === selectedDayOfTheWeek ? 700 : 400}
                    color={
                      index === selectedDayOfTheWeek
                        ? theme.colors.text.secondary
                        : theme.colors.text.subdued
                    }
                  >
                    {weekSplited.toString().toLocaleUpperCase().split(',')}
                  </Body>
                )
              })}
              <Styled.WeekTop>
                {CalendarListSliced.map((item, index) => {
                  const isSelected =
                    item.day === selectedDay &&
                    item.month === selectedMonth &&
                    item.year === selectedYear
                  return (
                    <Styled.Day key={index}>
                      <Body
                        type="copy5"
                        weight={isSelected ? 700 : 400}
                        color={
                          isSelected
                            ? theme.colors.text.secondary
                            : theme.colors.text.subdued
                        }
                        margin="8px 0 28px 0"
                      >
                        {item.day}
                      </Body>
                    </Styled.Day>
                  )
                })}
              </Styled.WeekTop>
            </Styled.WeekDays>
            <Styled.TimeColumn fullScreen={fullScreenMode}>
              <Styled.TimeList>
                {timeList.map((time, index) => {
                  return (
                    <Body
                      type="small"
                      color={
                        index % 2 == 0
                          ? theme.colors.text.default
                          : theme.colors.text.subdued
                      }
                      weight={index % 2 == 0 ? 600 : 400}
                      key={index}
                      margin="0 0 40px 0"
                    >
                      {time}
                    </Body>
                  )
                })}
              </Styled.TimeList>
              <Styled.AthletesTime weekHeight={timeList.length}>
                {renderWeekList(weeks)}
              </Styled.AthletesTime>
            </Styled.TimeColumn>
          </Styled.CalendarBodyInner>
        </Styled.CalendarBody>
      )}
      <TimesCard
        week={selectedDayOfTheWeek}
        show={showCard}
        athleteList={allAthletes}
        selectedDate={selectedDate}
        onClick={() => setShowCard(false)}
      />
    </Styled.CalendarContainer>
  )
}

export default WeekView
