import styled from 'styled-components'

type ContainerProps = {
  flex?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  gap?: string
  align?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-evenly'
  justify?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-evenly'
  margin?: string
  padding?: string
  width?: string
}

export const Aligner = styled.div<ContainerProps>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  gap: ${({ gap }) => gap || '0'};
  flex-direction: ${({ flex }) => flex || 'row'};
  align-items: ${({ align }) => align || 'initial'};
  justify-content: ${({ justify }) => justify || 'initial'};
  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || 0};
`
