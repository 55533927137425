// Libs
import { cva } from 'class-variance-authority'

export const triggerStyles = cva(
  [
    'w-full flex justify-between items-center text-text text-copy4 bg-surface border rounded-[3px]',
    'outline-none transition-all user-select-none group focus:shadow-lg data-[disabled]:cursor-not-allowed',
    'data-[disabled]:bg-surface-disabled data-[disabled]:text-text-subdued data-[state=open]:border-icon',
    'data-[disabled]:cursor-not-allowed data-[disabled]:bg-surface-disabled data-[disabled]:text-text-subdued',
    'data-[placeholder]:text-text-subdued data-[placeholder]:disabled:text-text-disabled',
  ],
  {
    variants: {
      color: {
        critical: 'border-text-critical',
        default: 'border-border-input',
        focused: 'border-text',
        success: 'border-text-success',
      },
      scale: {
        small: 'py-[10px] px-3',
        medium: 'py-3 px-4',
        large: 'py-[18px] px-4',
      },
    },
    defaultVariants: {
      color: 'default',
      scale: 'medium',
    },
  },
)
