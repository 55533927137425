// React
import { useCallback, useEffect, useState } from 'react'

// Misc
import { cn } from '../../utils/classes'

type Props = {
  callback: (value: boolean) => void
  value?: string
}

enum EVariables {
  SIZE = 2,
  UPPER = 3,
  LOWER = 5,
  NUMBER = 7,
}

const VALIDATORS = [
  {
    symbol: '8+',
    name: 'caracteres',
    variable: EVariables.SIZE,
  },
  {
    symbol: 'AA',
    name: 'maiúscula',
    variable: EVariables.UPPER,
  },
  {
    symbol: 'Aa',
    name: 'minúscula',
    variable: EVariables.LOWER,
  },
  {
    symbol: '123',
    name: 'números',
    variable: EVariables.NUMBER,
  },
]

function getErrorProduct(value: string) {
  const hasValidSize = value.length >= 8 ? 1 : EVariables.SIZE
  const hasUppercase = value.toLowerCase() != value ? 1 : EVariables.UPPER
  const hasLowercase = value.toUpperCase() != value ? 1 : EVariables.LOWER
  const hasNumber = /[0-9]/.test(value) ? 1 : EVariables.NUMBER

  return hasValidSize * hasUppercase * hasLowercase * hasNumber
}

const PasswordValidator = ({ value, callback }: Props) => {
  const [validationProduct, setValidationProduct] = useState<number | null>(
    null,
  )

  const isValidField = useCallback(
    (variable: number) => {
      if (validationProduct) {
        return !!(validationProduct % variable)
      }
      return false
    },
    [validationProduct],
  )

  const renderValidationFields = useCallback(() => {
    return VALIDATORS.map((item) => {
      const isValid = value && isValidField(item.variable)
      const color =
        validationProduct === null
          ? 'text-text-disabled'
          : isValid
          ? 'text-text-success'
          : 'text-text-critical'

      return (
        <div
          className={cn(
            'flex w-full flex-col items-center justify-between text-copy4 leading-[16px]',
            'text-text-disabled transition-all duration-200 ease-in-out',
            color,
          )}
          key={item.variable}
          color={color}
          data-testid={item.variable}
        >
          <p className="mb-[6px] text-copy4 font-semibold">{item.symbol}</p>
          {item.name}
        </div>
      )
    })
  }, [isValidField, validationProduct, value])

  useEffect(() => {
    if (value) {
      const product = getErrorProduct(value)
      setValidationProduct(product)
      if (product === 1) {
        callback(true)
      } else {
        callback(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div
      className={cn(
        'grid h-[38px] w-full select-none grid-cols-[repeat(4,min-content)] content-center',
        'items-center justify-center gap-x-4',
      )}
      data-testid="container"
    >
      {renderValidationFields()}
    </div>
  )
}

export default PasswordValidator
