// Models
import { TFinancialNotices } from 'storage/financial/models'

// React
import { FC } from 'react'

// Misc
import { cn } from 'utils/helpers/classess'

type Props = {
  className?: string
  notices?: TFinancialNotices[]
}

const FinancialNotices: FC<Props> = ({ className }) => {
  const idPrefix = 'financial-notices'

  return (
    <div
      className={cn(
        'flex w-full flex-col gap-6 rounded-1 border border-border-input',
        'bg-surface p-4 pb-0 shadow-sm lg:min-w-full',
        className,
      )}
      data-testid={idPrefix}
    >
      <p className="text-copy3 font-semibold text-text-secondary">Avisos</p>

      <div className="flex h-[calc(100%-4.8rem)] items-center justify-center pb-2">
        <p className="text-copy3 text-text-disabled">Ainda não há avisos.</p>
      </div>
    </div>
  )
}

export default FinancialNotices
