// React
import { FC } from 'react'

// Misc
import { cn } from '../../utils/classes'

// Component
import LoadingSpinner from '../LoadingSpinner'

type Props = {
  active?: boolean
}

const Loading: FC<Props> = ({ active }) => (
  <div
    className={cn(
      'absolute left-0 top-0 flex w-full items-center justify-center rounded text-h1',
      'z-10 h-full bg-[rgba(43,42,99,0.54)]',
      { 'hidden opacity-0': !active },
    )}
  >
    <LoadingSpinner size="medium" />
  </div>
)

export default Loading
