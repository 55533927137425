// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import * as Styled from './styled'
import { Skeleton } from 'heeds-ds'

const Loader: FC = () => {
  const { breakpoints } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)

  return (
    <Styled.LoadContainer data-testid="row">
      {isDesktop ? (
        <>
          <div className="flex w-[minmax(20rem,1fr)] items-center gap-4 px-6">
            <Skeleton className="h-[42px] w-[42px] shrink-0 rounded-[50%]" />
            <Skeleton className="h-4 w-full" />
          </div>
          <Skeleton className="ml-6 h-4 w-80" />
          <Skeleton className="ml-6 h-4 w-80" />
          <Skeleton className="ml-6 h-4 w-40" />
          <div className="flex w-[50px]" />
        </>
      ) : (
        <>
          <Skeleton className="h-[52px] w-[52px] shrink-0 rounded-[50%]" />
          <div className="flex flex-col gap-2">
            <Skeleton className="h-4 w-72" />
            <Skeleton className="h-4 w-[60px]" />
            <Skeleton className="h-4 w-[70px]" />
            <Skeleton className="h-4 w-72" />
          </div>
        </>
      )}
    </Styled.LoadContainer>
  )
}

export default Loader
