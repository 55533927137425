// Models
import { IMultiSelectOption, IMultiSelectItem } from '../../models'

// React
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'

// Misc
import { cn } from '../../utils/classes'

// Components
import { Icon } from '..'

type Props = {
  className?: string
  disabled?: boolean
  items: IMultiSelectOption[]
  label?: string | ReactNode | ReactNode[]
  placeholder?: string
  name: string
}

const MultiSelect = ({
  className,
  disabled,
  items,
  label,
  placeholder,
  name,
}: Props) => {
  const divRef = useRef<HTMLDivElement | null>(null)
  const optionsRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [widthList, setWidthList] = useState<number>(0)
  const [openItems, setOpenItems] = useState<Record<number, boolean>>({})
  const [selectedList, setSelectedList] = useState<IMultiSelectItem[]>([])
  const [isOptionsOpen, setOptionsOpen] = useState(false)
  const [filterList, setFilerList] = useState('')

  const { control } = useFormContext()
  const {
    field: { onChange, value },
  } = useController({ control, name, defaultValue: '' })

  const handleToggle = (id: number) => {
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [id]: !prevOpenItems[id],
    }))
  }

  const toggleItemInList = (item: IMultiSelectItem) => {
    setFilerList('')
    setSelectedList((prevList) => {
      const exists = prevList?.some((i) => i.id === item.id)

      if (exists) {
        let filtered = prevList?.filter((i) => i.id !== item.id)

        if (item.subLabel) {
          item.subLabel.forEach((sub) => {
            filtered = filtered.filter((i) => i.id !== sub.id)
          })
        }

        onChange(filtered)
        return filtered
      } else {
        const newItem = [...prevList, item]
        onChange(newItem)
        return newItem
      }
    })
  }

  const last = Math.floor(widthList / 90)

  const handleListClick = (item: IMultiSelectItem) => {
    toggleItemInList(item)
    setOptionsOpen(true)
  }

  const filteredItems = useMemo(() => {
    return items.filter((item) =>
      item.label?.toLocaleUpperCase().includes(filterList.toLocaleUpperCase()),
    )
  }, [items, filterList])

  useEffect(() => {
    if (value) {
      setSelectedList(value)
    }
  }, [value])

  useEffect(() => {
    if (divRef.current) {
      setWidthList(divRef.current.offsetWidth)
    }
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node) &&
        !divRef.current?.contains(event.target as Node)
      ) {
        setOptionsOpen(false)
      }
    }
    if (isOptionsOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOptionsOpen])

  return (
    <div
      className={cn(
        'relative',
        {
          'pointer-events-none cursor-not-allowed select-none opacity-50':
            disabled,
        },
        className,
      )}
    >
      {label && <label className="text-copy4 font-semibold">{label}</label>}
      <div
        ref={divRef}
        className={cn(
          'relative flex items-center justify-between overflow-hidden rounded-1',
          'border border-border-input bg-surface font-roboto',
          label && ['mt-[8px]'],
        )}
      >
        {selectedList.length === 0 && (
          <div
            onClick={() => !disabled && setOptionsOpen(true)}
            data-testid="dropdown-opener"
          >
            <input
              ref={inputRef}
              className="p-3 text-copy5 outline-none"
              placeholder={placeholder}
              value={filterList}
              onChange={(e) => setFilerList(e.target.value)}
            />
          </div>
        )}
        <div
          className={cn(
            'flex items-center gap-[4px] px-3 py-2 text-copy4 font-semibold outline-none',
            'w-11/12',
          )}
        >
          {selectedList &&
            selectedList.length > 0 &&
            selectedList?.slice(0, last)?.map((item, index) => {
              return index + 1 === last ? (
                <p
                  className={cn(
                    'flex h-auto w-[60px] bg-surface px-2 py-[2px] text-copy5',
                    'font-semibold text-text-subdued',
                  )}
                >
                  {`+ ${selectedList.length - (last - 1)}`}
                </p>
              ) : (
                <div
                  key={item.id}
                  onClick={() => handleListClick(item)}
                  className={cn(
                    'flex min-w-[60px] items-center gap-[8px] truncate rounded-3 px-2 py-[2px]',
                    'border font-roboto text-copy4',
                  )}
                >
                  <p
                    className={cn(
                      'flex-1 truncate text-copy5 font-semibold text-badge-purpleText',
                    )}
                  >
                    {item.label}
                  </p>
                  <Icon iconName="close" size={16} />
                </div>
              )
            })}
        </div>

        <button
          type="button"
          className="absolute right-0 pr-3"
          onClick={() => setOptionsOpen(!isOptionsOpen)}
        >
          <Icon iconName={isOptionsOpen ? 'expandLess' : 'expandMore'} />
        </button>
      </div>
      {isOptionsOpen && (
        <div
          data-testid="dropdown-selector"
          ref={optionsRef}
          className={cn(
            'absolute right-0 z-50 ml-2 mt-1 flex max-h-[200px] flex-col overflow-y-auto',
            'rounded-1 border border-border-input bg-surface py-2',
            className,
          )}
        >
          {filteredItems.length > 0 ? (
            filteredItems.map((item) => {
              const isOpen = openItems[item?.id || 0] || false
              return (
                <details
                  key={item.id}
                  onToggle={() => handleToggle(item?.id || 0)}
                  className="flex w-full items-center px-3 py-2"
                  open={selectedList.some((i) => i.id === item.id)}
                >
                  <summary
                    className={cn(
                      'ml-2 flex cursor-pointer list-none appearance-none items-center',
                      'gap-[8px] font-roboto text-copy4 font-semibold',
                    )}
                  >
                    <input
                      type="checkbox"
                      role="checkbox"
                      className={cn('m-0 h-4 p-0', 'w-4 cursor-pointer')}
                      checked={selectedList.some((i) => i.id === item.id)}
                      onClick={() => toggleItemInList(item)}
                      id={item.label}
                    />
                    <label
                      className="w-full cursor-pointer font-roboto text-copy4 font-semibold"
                      htmlFor={item.label}
                    >
                      {item.label}
                    </label>
                    {item.subLabel && (
                      <div className="ml-auto">
                        <Icon iconName={isOpen ? 'expandLess' : 'expandMore'} />
                      </div>
                    )}
                  </summary>
                  {item.subLabel?.map((sub) => {
                    return (
                      <div
                        key={sub.label}
                        className={cn(
                          'ml-2 flex cursor-pointer list-none appearance-none items-center',
                          'pl-2 pt-4 font-roboto text-copy4 font-semibold',
                        )}
                      >
                        <input
                          type="checkbox"
                          className="cursor-pointer"
                          checked={selectedList.some((i) => i.id === sub.id)}
                          onClick={() => toggleItemInList(sub)}
                          id={sub.label}
                        />
                        <label
                          className="ml-2 cursor-pointer font-roboto text-copy4 font-semibold"
                          htmlFor={sub.label}
                        >
                          {sub.label}
                        </label>
                      </div>
                    )
                  })}
                </details>
              )
            })
          ) : (
            <div className="flex w-full items-center px-3 py-2">
              <p className="ml-2 font-roboto text-copy4">
                Nem um item encontrado
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default MultiSelect
