// React
import { useEffect, useRef, useState } from 'react'

// Libraries
import { useController, useFormContext } from 'react-hook-form'

// Misc
import { cn } from '../../../utils/classes'
import { inputContainerStyles, textareaStyles } from './styles'

// Components
import { InputWrapper } from '../..'

// Constants
const verticalPaddingAndFontSize = {
  small: [28, 12],
  medium: [32, 15],
  large: [36, 18],
}

type Props = {
  className?: string
  disabled?: boolean
  displayError?: boolean
  height?: number
  label?: string
  mandatory?: boolean
  maxLength?: number
  name: string
  placeholder?: string
  readOnly?: boolean
  resizable?: boolean
  scale?: 'small' | 'medium' | 'large'
}

enum EFieldStates {
  default = 'default',
  error = 'critical',
  focus = 'focused',
  success = 'success',
}

const TextArea = ({
  disabled,
  displayError = true,
  height,
  maxLength,
  name,
  placeholder,
  readOnly,
  resizable = false,
  scale = 'medium',
  ...restWrapperProps
}: Props) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)

  const [fieldState, setFieldState] = useState(EFieldStates.default)
  const [focused, setFocused] = useState(false)

  const defaultInputHeight =
    verticalPaddingAndFontSize[scale][0] + verticalPaddingAndFontSize[scale][1]

  const [stateHeight, setHeight] = useState(height ?? defaultInputHeight)

  const { control } = useFormContext()
  const {
    field: { onBlur, onChange, value, ref },
    fieldState: { isDirty, error },
  } = useController({ control, name, defaultValue: '' })

  const handleBlur = () => {
    onBlur()
    setFocused(false)
    resizable && setHeight(height ?? defaultInputHeight)
  }

  const handleFocus = () => {
    setFocused(true)
    setFieldState(EFieldStates.focus)
  }

  useEffect(() => {
    switch (true) {
      case !!error:
        setFieldState(EFieldStates.error)
        break
      case focused:
        setFieldState(EFieldStates.focus)
        break
      case isDirty:
        setFieldState(EFieldStates.success)
        break
      default:
        setFieldState(EFieldStates.default)
        break
    }
  }, [disabled, error, focused, isDirty])

  useEffect(() => {
    if (resizable) {
      const scrollHeight = textAreaRef.current?.scrollHeight
      if (focused && scrollHeight) setHeight(scrollHeight)
      if (!value) setHeight(height ?? defaultInputHeight)
    }
  }, [defaultInputHeight, focused, height, resizable, textAreaRef, value])

  return (
    <InputWrapper
      {...restWrapperProps}
      color={fieldState}
      disabled={disabled}
      displayError={displayError}
      error={error?.message}
      name={name}
      size={scale}
    >
      <div
        aria-disabled={disabled}
        className={cn(inputContainerStyles({ color: fieldState }))}
      >
        <textarea
          className={cn(textareaStyles({ scale }), {
            'placeholder:text-text-disabled': disabled,
          })}
          style={{ height: stateHeight }}
          disabled={disabled}
          id={name}
          ref={(e) => {
            ref(e)
            textAreaRef.current = e
          }}
          value={value}
          maxLength={maxLength}
          name={name}
          onBlur={handleBlur}
          onChange={onChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          readOnly={readOnly}
          data-testid="textArea"
        />
      </div>
    </InputWrapper>
  )
}

export default TextArea
