// React
import { forwardRef } from 'react'

// Libs
import Creatable from 'react-select/creatable'
import Select from 'react-select'

// Misc
import { autocompleteStyles } from './styles'

export type CustomProps = {
  borderColor?: string
  disabled?: boolean
  position: 'bottom' | 'top'
  scale: 'small' | 'medium' | 'large'
}

/*
 * Simple Autocomplete
 */
const SimpleAutocomplete = forwardRef<
  React.ElementRef<typeof Select>,
  Omit<React.ComponentPropsWithoutRef<Select>, 'theme'> & CustomProps
>(({ scale, ...props }, ref) => (
  <Select
    {...props}
    ref={ref}
    unstyled
    className="autocomplete-scrollbar text-copy4"
    classNames={autocompleteStyles(scale)}
  />
))
SimpleAutocomplete.displayName = 'SimpleAutocomplete'

/*
 * Creatable
 */
const CreatableAutocomplete = forwardRef<
  React.ElementRef<Creatable>,
  Omit<React.ComponentPropsWithoutRef<Creatable>, 'theme'> & CustomProps
>(({ scale, ...props }, ref) => (
  <Creatable
    {...props}
    ref={ref}
    unstyled
    className="autocomplete-scrollbar text-copy4"
    classNames={autocompleteStyles(scale)}
  />
))
CreatableAutocomplete.displayName = 'CreatableAutocomplete'

export { CreatableAutocomplete, SimpleAutocomplete }
