// Models
import { IFilter } from 'heeds-ds/src/components/Inputs/InputSearch'
import { IFilterFields } from 'blocks/dashboard/routine/GlobalRoutineList'
import { IWorkoutRoutineState } from 'storage/workoutRoutine/models'
import { IWorkoutRoutineListPayload } from 'services/workoutRoutine/@types'
import { TRoutineStatus } from 'models'
import IStore from 'lib/redux/models'

// React
import { FC, useLayoutEffect } from 'react'

// Libs
import { generatePath, useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { cleanup, success } from 'storage/workoutRoutine/duck'
import {
  triggerLoadListWorkoutRoutines,
  triggerLoadMoreListWorkoutRoutine,
} from 'storage/workoutRoutine/duck'
import { urls } from 'routes/paths'
import { useDebounceFunction, useMenu, useModal } from 'hooks'
import useFilters from 'hooks/useFilters'

// Components
import * as Block from 'blocks/dashboard/routine'
import * as Styled from './styled'
import { Loading } from 'heeds-ds'
import { ModalNewRoutine } from 'components'

// Constants
const page_size = 20

const GlobalRoutines: FC = () => {
  const { id = '' } = useParams()
  const { error, loading, next, workoutRoutines } = useSelector<
    IStore,
    IWorkoutRoutineState
  >((state) => state.workoutRoutine)
  const { isVisible, openModal } = useModal()
  const { setPagename } = useMenu()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { filters, isValueIncluded, setFilter, toggleFilter, resetFilters } =
    useFilters<IWorkoutRoutineListPayload>(window.location.search)

  const handleEndReached = () => {
    if (workoutRoutines && !loading && next) {
      const query = {
        ...filters,
        athlete: id,
        page: next,
        page_size,
      }
      dispatch(triggerLoadMoreListWorkoutRoutine(query))
    }
  }

  const handleModalFilters = (modalFilters: IFilterFields) => {
    const newFilters = { ...filters, ...modalFilters }
    resetFilters(newFilters)
    handleReloadWorkoutRoutine(newFilters)
  }

  const handleNavigateToCreation = (athleteId: number) => {
    dispatch(cleanup())
    navigate(generatePath(urls.workoutRoutineCreate, { id: athleteId }))
  }

  const openNewRoutineModal = () => openModal('new-routine-modal')

  const onClickFilter = (filter: IFilter) => {
    const newFilters = toggleFilter(filter.param, filter.value, true)
    handleReloadWorkoutRoutine(newFilters)
  }

  const onTabChange = (selectedTab: TRoutineStatus) => {
    const newFilters = setFilter('routine_status', selectedTab, true)
    handleReloadWorkoutRoutine(newFilters)
  }

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filters = setFilter('search', event.target.value, true)
    debouncedLoad(filters)
  }

  const handleReloadWorkoutRoutine = (
    newFilters: IWorkoutRoutineListPayload,
  ) => {
    const query = {
      ...newFilters,
      athlete: id,
      page_size,
      routine_status: newFilters.routine_status || 'current',
    }
    dispatch(triggerLoadListWorkoutRoutines(query))
  }

  const debouncedLoad = useDebounceFunction<
    (newFilters: IWorkoutRoutineListPayload) => void
  >(handleReloadWorkoutRoutine, 1000)

  useLayoutEffect(() => {
    if (!workoutRoutines && !loading && !error) {
      const query = {
        ...filters,
        athlete: id,
        page_size,
        routine_status: filters.routine_status || 'current',
      }
      dispatch(triggerLoadListWorkoutRoutines(query))
    }
  }, [dispatch, error, filters, id, loading, workoutRoutines])

  useLayoutEffect(() => {
    setPagename('TREINOS')

    return () => {
      dispatch(success({ workoutRoutines: undefined }))
      setPagename('DASHBOARD')
    }
  }, [dispatch, setPagename])

  if (loading && workoutRoutines === undefined) {
    return <Loading active />
  }

  return (
    <Styled.Container id="athlete-routines-container">
      <Block.GlobalRoutineHeader
        activeTab={filters.routine_status?.[0] as TRoutineStatus}
        openNewRoutineModal={openNewRoutineModal}
        onTabChange={onTabChange}
      />

      <Block.GlobalRoutineList
        filters={filters}
        isActive={isValueIncluded}
        handleEndReached={handleEndReached}
        openNewRoutineModal={openNewRoutineModal}
        onClickFilter={onClickFilter}
        onFiltersChange={handleModalFilters}
        onSearchChange={onSearchChange}
        refreshPage={() => handleReloadWorkoutRoutine(filters)}
      />

      {isVisible === 'new-routine-modal' && (
        <ModalNewRoutine onNewRoutine={handleNavigateToCreation} />
      )}
    </Styled.Container>
  )
}

export default GlobalRoutines
