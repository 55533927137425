// Models
import { IExerciseListPayload, IExerciseState } from 'storage/exercise/models'
import { IFormInputs, IModelInput, TExercisesGroupFilter } from 'models'
import { IWorkoutRoutineState } from 'storage/workoutRoutine/models'
import { IWorkoutModelState } from 'storage/workoutModel/models'
import IStore from 'lib/redux/models'

// React
import {
  FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import isEqual from 'lodash.isequal'

// Misc
import { getModelName, isWebviewAccess, uid } from 'utils/functions'
import { prepareModelsToRedux } from 'filters/workouts'
import { success } from 'storage/workoutModel/duck'
import {
  triggerLoadExercises,
  triggerLoadMoreExercises,
} from 'storage/exercise/duck'
import { urls } from 'routes/paths'
import {
  useDebounceFunction,
  useFilters,
  useMediaQuery,
  useModal,
  useWorkoutModelsForm,
} from 'hooks'

// Components
import { Loading } from 'heeds-ds'
import {
  ModalConfirmation,
  ModalDelete,
  ModalRenameWorkoutModel,
} from 'components'
import Desktop from './desktop'
import Mobile from './mobile'

// Constants
import { EXERCISES_FILTERS } from 'utils/constants'

const page_size = 20

interface IFiltersQuery extends IExerciseListPayload {
  etapa?: '1' | '2'
  'treino-selecionado'?: string
}

const WorkoutModels: FC = () => {
  const { id = '', routine_id = '' } = useParams()
  const { exercises, loading: loadingExercise } = useSelector<
    IStore,
    IExerciseState
  >((state) => state.exercise)
  const { loading: loadingModel, workoutModels } = useSelector<
    IStore,
    IWorkoutModelState
  >((state) => state.workoutModel)
  const { loading: loadingRoutine, workoutRoutine } = useSelector<
    IStore,
    IWorkoutRoutineState
  >((state) => state.workoutRoutine)
  const { breakpoints } = useContext(ThemeContext)
  const { openModal, isVisible } = useModal()
  const { filters, isValueIncluded, setFilter, toggleFilter, resetFilters } =
    useFilters<IFiltersQuery>(window.location.search)
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)
  const isANativeView = isWebviewAccess()
  const navigate = useNavigate()

  const [, setSearchParams] = useSearchParams()

  const [showFilters, setShowFilters] = useState(false)
  const [stateModels, setStateModels] = useState<IModelInput[]>(
    workoutModels && workoutModels.length > 0
      ? workoutModels
      : [
          {
            id: 'NEW' + uid(),
            name: getModelName(0),
            workout_set: {},
            workout_routine: workoutRoutine?.id,
          },
        ],
  )

  const {
    methods,
    removeWorkoutModel,
    selectedModelIndex,
    setSelectedModelIndex,
    updateWorkoutModel,
    ...commonHookProps
  } = useWorkoutModelsForm(stateModels, setStateModels)

  const { handleSubmit } = methods

  const modelSelect = filters['treino-selecionado']

  const onChangeFilters = (filtersA: TExercisesGroupFilter[]) => {
    type Accumulator = Record<string, string[]>

    const filterReducer = (
      accumulator: Accumulator,
      item: TExercisesGroupFilter,
    ) => {
      if (item.active && item.param) {
        if (!accumulator[item.param]) {
          accumulator[item.param] = []
        }
        accumulator[item.param].push(item.name)
      }
      return accumulator
    }
    const newFilters: Accumulator = filtersA.reduce(filterReducer, {})

    resetFilters(newFilters)

    handleReloadExercises(newFilters)
  }

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filters = setFilter('name', event.target.value, true)
    debouncedLoad(filters)
  }

  const handleEndReached = () => {
    if (exercises && !loadingExercise && exercises?.next) {
      const query = {
        ...filters,
        page: exercises.next,
        page_size,
      }
      dispatch(triggerLoadMoreExercises(query))
    }
  }

  const handleReloadExercises = (newFilters: IExerciseListPayload) => {
    const query = {
      ...newFilters,
      page_size,
    }
    dispatch(triggerLoadExercises(query))
  }

  const debouncedLoad = useDebounceFunction<
    (newFilters: IExerciseListPayload) => void
  >(handleReloadExercises, 1000)

  const selectWorkoutModel = (modelIndex: number) => {
    const newFilters = { ...filters }
    delete newFilters['treino-selecionado']
    resetFilters(newFilters)
    setSelectedModelIndex(modelIndex)
  }

  const handleRemoveWorkoutModel = () => {
    const modelId = stateModels[selectedModelIndex].id
    removeWorkoutModel(modelId)
  }

  const onClickFilter = () => {
    setShowFilters((prev) => !prev)
  }

  const removeFilter = (filter: TExercisesGroupFilter) => {
    if (filter.param) {
      const newFilters = toggleFilter(
        filter.param as string,
        filter.name,
        false,
      )

      handleReloadExercises(newFilters)
    }
  }
  const changePage = (page: string) => setSearchParams({ etapa: page })

  const openRemoveModelModal = () => openModal('remove-workout-model-modal')

  const openRenameModelModal = () => openModal('rename-workout-model-modal')

  const onSubmit = useCallback(
    (formData: IFormInputs) => {
      const reduxData = prepareModelsToRedux(formData, stateModels)

      const updatedModels = reduxData.filter((model) =>
        Boolean(Object.values(model.workout_set ?? {}).length),
      )

      dispatch(success({ workoutModels: updatedModels }))

      navigate(generatePath(urls.workoutRoutineReview, { id, routine_id }))
    },
    [dispatch, id, navigate, routine_id, stateModels],
  )

  const commonWorkoutModelsProps = {
    ...commonHookProps,
    filters: EXERCISES_FILTERS.map((filter) => ({
      active: isValueIncluded(filter.param, filter.value.toString()) || false,
      name: filter.value,
      label: filter.label,
      param: filter.param,
    })),
    inputValue: filters?.name || '',
    loadMoreExercises: handleEndReached,
    methods,
    onChangeFilters,
    onClickFilter,
    onSearchChange,
    onSubmit,
    removeFilter,
    removeWorkoutModel: openRemoveModelModal,
    renameWorkoutModel: openRenameModelModal,
    selectWorkoutModel,
    selectedModelIndex,
    setStateModels,
    showFilters,
    stateModels,
    updateWorkoutModel,
  }

  const formValues = methods.watch()

  useEffect(() => {
    const payload = prepareModelsToRedux(formValues, stateModels)

    if (!isANativeView && !isEqual(payload, workoutModels)) {
      setStateModels(payload)
      dispatch(success({ workoutModels: payload }))
    }
  }, [dispatch, formValues, isANativeView, stateModels, workoutModels])

  useEffect(() => {
    !isANativeView &&
      modelSelect &&
      setSelectedModelIndex(parseInt(modelSelect))
  }, [isANativeView, modelSelect, setSelectedModelIndex])

  useLayoutEffect(() => {
    dispatch(triggerLoadExercises({ ...filters, page_size }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  if (
    (!(isVisible === 'add-new-model-modal') && loadingModel) ||
    loadingRoutine
  ) {
    return <Loading active />
  }

  return (
    <Fragment>
      {isDesktop ? (
        <Desktop {...commonWorkoutModelsProps} />
      ) : (
        <Mobile
          {...commonWorkoutModelsProps}
          changePage={changePage}
          mobilePage={filters.etapa}
        />
      )}

      {isVisible === 'remove-workout-model-modal' && (
        <ModalDelete
          onConfirm={handleRemoveWorkoutModel}
          title="Excluir treino"
          cancelTitle="Continar editando"
          confirmTitle="Sim, quero excluir"
          description="Você está excluindo um treino e perderá todos os exercícios adicionados nele. Deseja prosseguir?"
        />
      )}

      {isVisible === 'rename-workout-model-modal' && (
        <ModalRenameWorkoutModel
          updateWorkoutModel={updateWorkoutModel}
          workoutModel={stateModels[selectedModelIndex]}
        />
      )}

      {isVisible === 'empty-model' && (
        <ModalConfirmation
          confirmTitle="Ir para revisão"
          description="Você tem um treino que foi criado e não possui exercícios adicionados, indo para revisão ele será excluído. Deseja prosseguir?"
          onConfirm={handleSubmit(onSubmit)}
          title="Revisar treino"
        />
      )}
    </Fragment>
  )
}

export default WorkoutModels
