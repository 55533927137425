// Libraries
import { Skeleton } from 'heeds-ds'
import styled from 'styled-components'

export const Icon = styled(Skeleton)`
  width: 42px;
  height: 42px;
  border-radius: 20px;
  flex-shrink: 0;
`

export const LoadContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.surface.default};
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 1.5rem;
  margin: 0;
  padding: 1.6rem 2.4rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 0;
    display: grid;
    align-items: center;
    grid-template-columns:
      minmax(240px, 1fr) minmax(200px, 0.8fr) minmax(180px, 0.6fr)
      minmax(100px, 0.5fr) minmax(100px, 0.5fr) minmax(10px, 0.3fr);
    gap: 0.2rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    :not(:last-of-type) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
    }
  }
`
