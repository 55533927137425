// Libraries
import styled, { css } from 'styled-components'

// Components
import { Aligner, Body } from 'heeds-ds'

// Assets
import workoutImage from 'assets/images/exercise-223.png'

type Props = {
  thumbnail?: string
}

export const Cell = styled(Aligner)`
  padding: 1.6rem 1.6rem 1.6rem 2.4rem;
`

export const Row = styled.div`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template-columns:
    minmax(7.5rem, 0.1fr) repeat(4, minmax(15.25rem, 0.21fr))
    minmax(10rem, 0.15fr) minmax(6rem, 0.1fr);
  transition: all 0.3s ease-in-out;
  width: 100%;
  ${({ theme }) => css`
    background: ${theme.colors.surface.default};
    border-bottom: 1px solid ${theme.colors.border.input};
  `}
`

export const Text = styled(Body).attrs(({ theme }) => ({
  color: theme.colors.text.default,
  type: 'copy4',
  weight: 400,
}))``

export const Media = styled.div<Props>`
  align-items: center;
  background: ${({ thumbnail }) =>
      thumbnail
        ? 'linear-gradient(rgba(43, 42, 99, 0.45), rgba(43, 42, 99, 0.45))'
        : ''},
    url(${({ thumbnail }) => thumbnail || workoutImage});
  background-size: cover;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  min-width: 40px;
  border: 1px solid ${({ theme }) => theme.colors.border.input};
`
