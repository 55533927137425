// React
import { FC, useContext } from 'react'

// Libraries
import { ChartOptions } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { ThemeContext } from 'styled-components'

// Misc
import { BRL } from 'lib/currency'
import { cn } from 'utils/helpers/classess'

// Constants
const CHART_OPTIONS: ChartOptions<'doughnut'> = {
  animation: false,
  cutout: '77%',
  maintainAspectRatio: false,
  plugins: {
    tooltip: { enabled: false },
    legend: { display: false },
  },
  responsive: true,
  spacing: -2.5,
}

type Props = {
  className?: string
  received?: number
  reference: string
  toReceive?: number
  width?: string
}

const FinancialAmountReceived: FC<Props> = ({
  className,
  received,
  reference,
  toReceive,
}) => {
  const { colors } = useContext(ThemeContext)

  return (
    <div
      className={cn(
        'flex w-full flex-col gap-4 rounded-1 border border-border-input',
        'bg-surface p-4 shadow-sm',
        className,
      )}
      data-testid="financial-amount-received-container"
    >
      <div className="flex justify-between">
        <p className="text-copy3 font-semibold text-text-secondary">
          Valor Recebido
        </p>
        <p className="text-copy5 text-text-disabled">{reference}</p>
      </div>

      <div className="flex items-center gap-8 xl:gap-6">
        <div className="h-[65px] w-[65px] min-w-[65px] p-2 xl:h-[134px] xl:w-[134px] xl:min-w-[134px] xl:p-[14px]">
          <div className="h-full rounded-[50%] shadow-[0px_4px_4px_rgba(32,34,35,0.18)] xl:shadow-none">
            {
              <Doughnut
                options={CHART_OPTIONS}
                data={{
                  datasets: [
                    {
                      data: [toReceive || -1, received],
                      backgroundColor: [
                        colors.icon.disabled,
                        colors.text.secondary,
                      ],
                      borderRadius: [0, 5],
                      borderWidth: [0, 0],
                    },
                  ],
                }}
              />
            }
          </div>
        </div>

        <div className="flex flex-col items-start gap-4 xl:gap-6">
          <div className="flex items-center gap-2">
            <div className="h-3 w-3 rounded-[50%] bg-text-secondary" />

            <p
              className="text-copy3 text-text-subdued"
              data-testid={`financial-amount-received-label-0`}
            >
              Recebido
            </p>
            <p
              className="ml-2 whitespace-nowrap text-copy3 font-semibold"
              data-testid={`financial-amount-received-value-0`}
            >
              {BRL(received).format()}
            </p>
          </div>

          <div className="flex items-center gap-2">
            <div className="h-3 w-3 rounded-[50%] bg-icon-disabled" />

            <p
              className="text-copy3 text-text-subdued"
              data-testid={`financial-amount-received-label-1`}
            >
              Falta receber
            </p>

            <p
              className="ml-2 whitespace-nowrap text-copy3 font-semibold"
              data-testid={`financial-amount-received-value-1`}
            >
              {BRL(toReceive).format()}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FinancialAmountReceived
