// Models
import { EPlanModelToPortuguese, TAthleteStatus } from 'models'
import { IAthleteItem } from 'services/athletes/@types'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC, useMemo, useState } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cn } from 'utils/helpers/classess'

// Custom
import { Avatar, DropdownMenu, IconButton } from 'heeds-ds'

export type Props = {
  item: IAthleteItem
  onClick: () => void
  handleOptions: (
    id: number,
    subscriptionId: number,
    status?: TAthleteStatus,
  ) => TMenuDropdownOption[]
}

const Mobile: FC<Props> = ({
  item: { name, plan_model, status, id, subscription_pk },
  onClick,
  handleOptions,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleDropdownOpenChange = (open: boolean) => {
    setIsDropdownOpen(open)
  }

  const handleClick = () => {
    if (!isDropdownOpen) {
      onClick()
    }
  }

  const options = useMemo(
    () => handleOptions(id, subscription_pk || 0, status),
    [handleOptions, id, status, subscription_pk],
  )

  const handleStatusTag = () => {
    switch (status) {
      case 'active':
        return
      case 'inactive':
        return (
          <div className="rounded-4 bg-badge-greyBackground px-4 py-2">
            <p className="text-copy4 font-semibold text-badge-greyText">
              Desativado
            </p>
          </div>
        )
      case 'invited':
        return (
          <div className="rounded-4 bg-badge-yellowBackground px-4 py-2">
            <p className="text-copy4 font-semibold text-badge-yellowText">
              Convidado
            </p>
          </div>
        )
    }
  }

  return (
    <div
      onClick={handleClick}
      data-testid="card"
      className={cn(
        'flex flex-col items-start gap-2 rounded-[15px] border border-border-input bg-surface p-4',
        { 'opacity-90': status === 'inactive' },
      )}
    >
      {handleStatusTag()}
      <div className="flex w-full justify-between gap-4">
        <Avatar
          disabled={status === 'inactive'}
          name={name ?? ''}
          scale="xSmall"
        />
        <div className="w-4/6">
          <p
            className={cn('mb-2 truncate text-copy4 font-bold text-text', {
              'text-text-disabled': status === 'inactive',
            })}
          >
            {name}
          </p>
          <p
            className={cn('text-copy4 text-text', {
              'text-text-disabled': status === 'inactive',
            })}
          >
            {plan_model && EPlanModelToPortuguese[plan_model]}
          </p>
        </div>
        <div className="flex items-center justify-center">
          <DropdownMenu items={options} onOpenChange={handleDropdownOpenChange}>
            <IconButton
              size="small"
              iconName="moreVertical"
              track={buttonClickTracking}
              trackName="open_model_model_options"
            />
          </DropdownMenu>
        </div>
      </div>
    </div>
  )
}

export default Mobile
