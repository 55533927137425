// Libs
import { cva } from 'class-variance-authority'

export const colorStyles = cva([''], {
  variants: {
    color: {
      critical: 'text-surface-criticalSubdued fill-icon-critical',
      info: 'text-surface-highlightSubdued fill-icon-highlight',
      success: 'text-surface-successSubdued fill-icon-success',
      warning: 'text-surface-warningSubdued fill-icon-warning',
    },
  },
  defaultVariants: {
    color: 'info',
  },
})
