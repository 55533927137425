// Model
import { TooltipContentProps } from '@radix-ui/react-tooltip'

// React
import { FC } from 'react'

// Misc
import { cn } from '../../utils/classes'

// Components
import * as Tip from '@radix-ui/react-tooltip'

type Props = TooltipContentProps & {
  arrowSize?: number
  iconColor?: string
  children?: React.ReactNode
  content?: React.ReactNode
  delay?: number
  open?: boolean
  className?: string
}

const Tooltip: FC<Props> = (props: Props) => {
  const {
    arrowSize = 15,
    iconColor,
    children,
    content,
    delay = 400,
    open,
    className,
  } = props

  return (
    <Tip.Provider>
      <Tip.Root delayDuration={delay} open={open}>
        <Tip.Trigger asChild onClick={(e) => e.stopPropagation()}>
          {children}
        </Tip.Trigger>
        <Tip.Portal>
          <Tip.Content
            data-testid="tooltip-content"
            className={cn(
              'w-fit max-w-[310px] rounded-2 bg-background p-4 text-copy4 will-change-[transform,opacity]',
              'animation-slide-down-and-fade shadow-[0px_4px_50px_rgba(0,0,0,0.3)] duration-500',
              className,
            )}
          >
            {content}
            <Tip.Arrow
              height={Math.floor(arrowSize / 2)}
              width={arrowSize}
              className={cn('fill-background', iconColor)}
            />
          </Tip.Content>
        </Tip.Portal>
      </Tip.Root>
    </Tip.Provider>
  )
}

export default Tooltip
