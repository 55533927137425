// Libs
import { cva } from 'class-variance-authority'

export const inputContainerStyles = cva(
  [
    'flex w-full rounded-[3px] border bg-surface transition-all duration-300 ease-in-out',
  ],
  {
    variants: {
      color: {
        critical: 'text-text-critical',
        default: 'text-border-input',
        focused: 'text-text',
        success: 'text-text-success',
      },
    },
    defaultVariants: {
      color: 'default',
    },
  },
)

export const inputStyles = cva(
  [
    'border-none caret-[#202223] transition-all duration-300 ease-in-out outline-none w-full bg-transparent pl-4',
  ],
  {
    variants: {
      scale: {
        small: 'text-copy4 py-3',
        medium: 'text-copy3 py-[15px]',
        large: 'text-copy2 py-[18px]',
      },
    },
    defaultVariants: {
      scale: 'medium',
    },
  },
)

export const iconButtonStyles = cva(
  [
    'flex items-center bg-none border-none cursor-pointer absolute right-0 bottom-0 text-center px-2 z-10',
  ],
  {
    variants: {
      scale: {
        small: 'h-[42px]',
        medium: 'h-[50px]',
        large: 'h-[59px]',
      },
    },
    defaultVariants: {
      scale: 'medium',
    },
  },
)
