// Libraries
import styled from 'styled-components'

// Components
import { Skeleton } from 'heeds-ds'

export const Icon = styled(Skeleton)`
  border-radius: 2rem;
  flex-shrink: 0;
  height: 4.2rem;
  width: 4.2rem;
`

export const LoadContainer = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.surface.default};
  border-radius: 1.5rem;
  box-shadow: 0px 2px 5px 0px rgba(43, 42, 99, 0.12);
  display: flex;
  gap: 1.6rem;
  margin: 0 2.4rem;
  padding: 1.6rem 2.4rem;
  width: calc(100% - 4.8rem);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    align-items: center;
    cursor: pointer;
    display: grid;
    gap: 0.2rem;
    grid-template-columns: repeat(3, minmax(20rem, 1fr)) minmax(7rem, 0.33fr);
    margin: 0;
    transition: all 0.3s ease-in-out;
    width: 100%;

    :not(:last-of-type) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
    }
  }
`
