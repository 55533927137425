// Models
import { IAnamneseAnswerState } from 'storage/assessmentAnamneseAnswer/models'
import { IAthleteOutletContext } from 'layouts/AthleteRegisterLayout'
import IStore from 'lib/redux/models'

// React
import { FC, useLayoutEffect, useMemo } from 'react'

// Libraries
import {
  generatePath,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'

// Misc
import { dateFormatEnglishToPortuguese } from 'utils/functions'
import {
  cleanup as answerCleanup,
  triggerLoadAnamneseAnswers,
} from 'storage/assessmentAnamneseAnswer/duck'
import { urls } from 'routes/paths'
import { useDispatch, useSelector } from 'react-redux'

// Components
import * as Styled from './styled'
import { AssessmentCard, FooterActionButton } from 'components'
import { Loading } from 'heeds-ds'

const AthleteAssessment: FC = () => {
  const { id = '' } = useParams()
  const { anamneseAnswers, loading: answerLoading } = useSelector<
    IStore,
    IAnamneseAnswerState
  >(({ assessmentAnamneseAnswer }) => assessmentAnamneseAnswer)
  const { navigateToNextTab } = useOutletContext<IAthleteOutletContext>()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const lastAnswerDate = useMemo(() => {
    return anamneseAnswers?.length
      ? dateFormatEnglishToPortuguese(anamneseAnswers[0].creation_date)
      : ''
  }, [anamneseAnswers])

  const navigateToAnamnese = () =>
    navigate(generatePath(urls.athleteAssessmentAnamneseAnswers, { id }))

  useLayoutEffect(() => {
    if (id) dispatch(triggerLoadAnamneseAnswers({ athlete_pk: Number(id) }))
  }, [id, dispatch])

  useLayoutEffect(() => {
    return () => {
      dispatch(answerCleanup())
    }
  }, [dispatch])

  if (answerLoading) return <Loading active />

  return (
    <Styled.Container onSubmit={() => navigateToNextTab()}>
      <Styled.Grid>
        <AssessmentCard
          files={anamneseAnswers?.length || 0}
          last_deposit={lastAnswerDate}
          onClick={navigateToAnamnese}
          title="Anamneses"
          className="w-[338px]"
        />
      </Styled.Grid>

      <FooterActionButton primaryTitleButton="Próximo" />
    </Styled.Container>
  )
}

export default AthleteAssessment
