// Misc
import { cn } from '../../utils/classes'
import { colorStyles } from './styles'

//Components
import { GenericTextAction } from '../UI/Typography'
import Icon from '../UI/Icons'

type Props = {
  actionText?: string
  closeToast?: () => void
  customTitle?: string
  href?: string
  show: boolean
  text?: string
  variation?: 'success' | 'warning' | 'info' | 'error'
}

enum ETitles {
  error = 'Algo deu errado',
  info = 'Aviso',
  success = 'Sucesso!',
  warning = 'Atenção',
}

enum EIcons {
  error = 'circleX',
  info = 'circleInfo',
  success = 'circleCheck',
  warning = 'circleWarning',
}

const Toast = ({
  show = true,
  text,
  variation = 'success',
  href,
  actionText,
  closeToast,
  customTitle,
}: Props) => {
  return (
    <div
      data-testid="toast"
      className={cn(
        'fixed right-2 top-2 hidden w-[min(95%,40rem)] border-l-4 border-border-successDefault bg-surface',
        'rounded-[3px] px-6 py-4 shadow-[0px_0px_1px_rgba(9,30,66,0.31),0px_8px_12px_rgba(9,30,66,0.12)]',
        'animation-pop-from-top z-[999] xl:right-4 xl:top-8',
        colorStyles({ color: variation }),
        { flex: show },
      )}
    >
      <div className="flex gap-4">
        <Icon
          iconName={EIcons[variation]}
          size={24}
          className={cn(colorStyles({ color: variation }))}
        />

        <div>
          <p
            className={cn(
              'mt-1 text-copy4 font-bold',
              colorStyles({ color: variation }),
            )}
          >
            {customTitle ? customTitle : ETitles[variation]}
          </p>
          <p className="mt-2 text-copy4 text-text-subdued">{text}</p>
          {href && (
            <div className="mt-4 flex">
              <GenericTextAction href={href}>{actionText}</GenericTextAction>
            </div>
          )}
        </div>
      </div>
      <div
        onClick={closeToast}
        data-testid="toastCloseButton"
        className="absolute right-4 top-4 cursor-pointer"
      >
        <Icon iconName="close" size={24} className="fill-icon-disabled" />
      </div>
    </div>
  )
}

export default Toast
