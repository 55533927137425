// React & React Native
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
} from 'react'

type MenuContext = {
  closeMenu: () => void
  openMenu: () => void
  toggleMenu: () => void
  visible: boolean
  pagename: ReactNode | ReactNode[]
  setPagename: (arg: ReactNode | ReactNode[]) => void
  color: string
  setColor: (arg: string) => void
  path: string
  setPath: (arg: string) => void
  longHeader: boolean
  setLongHeader: (arg: boolean) => void
}

type Props = {
  children: ReactNode | ReactNode[]
}

const MenuContext = createContext<MenuContext | null>(null)

const MenuProvider: FC<Props> = ({ children }: Props) => {
  const [visible, setVisible] = useState(false)
  const [pagename, setPagename] = useState<ReactNode | ReactNode[]>('Dashboard')
  const [path, setPath] = useState('')
  const [color, setColor] = useState('')
  const [longHeader, setLongHeader] = useState(false)

  const openMenu = () => {
    setVisible(true)
  }

  const closeMenu = () => {
    setVisible(false)
  }

  const toggleMenu = () => {
    setVisible((prev) => !prev)
  }

  return (
    <MenuContext.Provider
      value={{
        closeMenu,
        openMenu,
        toggleMenu,
        visible,
        pagename,
        setPagename,
        path,
        setPath,
        color,
        setColor,
        longHeader,
        setLongHeader,
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

const useMenu = (): MenuContext => {
  const context = useContext(MenuContext)
  if (!context) {
    throw new Error('useMenu should be used inside MenuProvider')
  }
  return context
}

export { useMenu, MenuProvider }
