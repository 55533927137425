// React
import { FC } from 'react'

// Misc
import { cn } from 'utils/helpers/classess'
import { theme } from 'lib/styled-components/theme'
import { useMenu } from 'hooks/useMenu'
import { useNavigate } from 'react-router-dom'

// Custom
import { Icon } from 'heeds-ds'

type Props = {
  goBack?: boolean
}

const Header: FC<Props> = ({ goBack = true }) => {
  const { openMenu, pagename, path, color } = useMenu()
  const navigate = useNavigate()

  return (
    <div
      data-testid="header-container"
      color={color}
      className={cn(
        'sticky top-0 z-[5] grid w-full grid-cols-[40px_1fr_40px] items-start justify-center gap-4 bg-surface p-6 xl:hidden',
        {
          'rounded-b-[50px] shadow-[2px_2px_5px_1px_rgba(205,205,205,0.25)]':
            color,
        },
        color,
      )}
    >
      <div
        onClick={() => goBack && (path !== '' ? navigate(path) : navigate(-1))}
        className="flex h-10 w-10 cursor-pointer items-center justify-center"
      >
        {goBack && (
          <Icon
            iconName="arrowBack"
            color={theme.colors.icon.default}
            size={40}
          />
        )}
      </div>
      <h1 className="text-center font-barlow text-copy2 font-extrabold">
        {pagename}
      </h1>
      <div
        onClick={openMenu}
        className="flex h-10 w-10 cursor-pointer items-center justify-center"
      >
        <Icon
          iconName="hamburger"
          color={theme.colors.icon.default}
          size={40}
        />
      </div>
    </div>
  )
}

export default Header
