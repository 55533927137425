// React
import { FC, useContext, useEffect, useState } from 'react'

//Misc
import { cn } from 'utils/helpers/classess'

// Libraries
import { ThemeContext } from 'styled-components'

// Custom
import { Icon } from 'heeds-ds'

type Props = {
  children?: React.ReactNode | React.ReactNode[]
  className?: string
  collapsible?: boolean
  description?: React.ReactNode
  mobileCard?: boolean
  padding?: string
  startsOpen?: boolean
  title?: React.ReactNode | React.ReactNode[]
}

const ContentBox: FC<Props> = ({
  children,
  className,
  collapsible,
  description,
  mobileCard,
  padding,
  startsOpen = false,
  title,
}) => {
  const [collapsed, setCollapsed] = useState(!startsOpen)
  const theme = useContext(ThemeContext)

  const toggleCollapse = (event: React.MouseEvent) => {
    event.stopPropagation()
    collapsible && setCollapsed((prev: boolean) => !prev)
  }

  useEffect(() => {
    !startsOpen && setCollapsed(true)
  }, [startsOpen])

  useEffect(() => {
    !collapsible && setCollapsed(false)
  }, [collapsible])

  return (
    <div
      data-testid="container"
      onClick={(event) => collapsed && toggleCollapse(event)}
      className={cn(
        'h-auto max-h-full w-full bg-surface transition-all duration-300 ease-in-out',
        'xl:rounded-4 xl:shadow-[0px_3.5px_5.5px_rgba(0,0,0,0.02)]',
        {
          'rounded-4 shadow-[0px_3.5px_5.5px_rgba(0,0,0,0.02)]': mobileCard,
          'max-h-none': collapsed && description !== undefined,
          'max-h-[68px]': description !== undefined,
        },
        className,
      )}
    >
      <div
        data-testid="tapContainer"
        onClick={toggleCollapse}
        className={cn('flex w-full px-6 pb-0 pt-6 ', {
          'pt-4 px-6': mobileCard,
          'mb-6': collapsed,
        })}
      >
        <div
          className={cn('flex w-full justify-between', {
            'cursor-pointer': collapsed,
          })}
        >
          <p
            data-testid="body"
            className="text-copy3 font-bold text-text-secondary"
          >
            {title}
          </p>
          {collapsible && (
            <div className="flex h-5 w-5 cursor-pointer items-center justify-center">
              <Icon
                color={theme.colors.icon.default}
                iconName={collapsed ? 'expandMore' : 'expandLess'}
              />
            </div>
          )}
        </div>
        {description && (
          <div className="mt-2 h-auto text-copy4 text-text-disabled">
            {description}
          </div>
        )}
      </div>
      <div
        data-testid="content"
        className={cn(
          'ease block max-h-full p-6 transition-all duration-300',
          {
            'hidden max-h-0 overflow-hidden': collapsed,
            'px-6 pb-6': mobileCard,
          },
          padding,
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default ContentBox
