// Models
import { TButtonTrakingEvent } from '../../../models'

// React
import { forwardRef } from 'react'

// Libraries
import { useLocation } from 'react-router-dom'

// Components
import BaseButton, { Props as ButtonProps } from '../BaseButton'
import LoadingSpinner from '../../LoadingSpinner'

enum EIconSizes {
  xsmall = 'xsmall',
  small = 'xsmall',
  medium = 'medium',
  large = 'medium',
}

export type Props = ButtonProps & {
  loading?: boolean
  track?: (event: TButtonTrakingEvent) => void
  trackName?: string
}

const FormButton = forwardRef<HTMLButtonElement, Props>(
  ({ track, trackName, ...props }, ref) => {
    const {
      children,
      loading,
      disabled,
      size = 'medium',
      variation = 'main',
    } = props
    const { search } = useLocation()

    const iconColors = {
      main: 'border-text-onPrimary',
      outlined: disabled
        ? 'border-action-secondaryDisabled'
        : 'border-action-secondaryDefault',
      borderless: disabled
        ? 'border-interactive-disabled'
        : 'border-interactive',
      success: 'border-icon-onPrimary',
      cancel: 'border-interactive-critical',
    }

    const tracking = () =>
      track?.({
        eventName: 'click',
        action: `click_form_${trackName ?? ''}`,
        location: window.location.pathname + search,
      })

    return (
      <BaseButton
        data-testid="formButton"
        ref={ref}
        tracking={tracking}
        type="submit"
        {...props}
      >
        {loading ? (
          <LoadingSpinner
            size={EIconSizes[size]}
            color={iconColors[variation]}
          />
        ) : (
          children
        )}
      </BaseButton>
    )
  },
)
FormButton.displayName = 'FormButton'

export default FormButton
