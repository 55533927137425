// Models
import { TModalProps } from 'views/dashboard/profile/ProfilePlans'
import { IAuthState } from 'storage/auth/models'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useState } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// Misc
import { cn } from 'utils/helpers/classess'
import {
  triggerActiveProfilePlan,
  triggerDeactivateProfilePlan,
  triggerDeleteProfilePlan,
  triggerGetProfilePlan,
} from 'storage/financial/duck'
import { useMediaQuery } from 'heeds-ds/src/hooks'
import { useModal } from 'hooks'
import { urls } from 'routes/paths'

// Components
import { Body, DropdownMenu, IconButton, IconsType, Swipe, Tag } from 'heeds-ds'
import { ModalConfirmation, ModalDelete } from 'components/modals'

type TValue = 'monthly' | 'quarterly' | 'semiannual' | 'yearly'

interface IOnlinePlanValues {
  id: number
  label: string
  value: TValue
  showFrequency: boolean
}

type Props = {
  setShowCustomPlanModal: (value: boolean) => void
  display: boolean
  data: IOnlinePlanValues[]
}

const CustomPlans: FC<Props> = ({ setShowCustomPlanModal, display, data }) => {
  const { userData } = useSelector<IStore, IAuthState>(({ auth }) => auth)
  const { watch, setValue } = useFormContext()
  const { openModal, isVisible, closeModal } = useModal()
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const [plansValues, setPlansValues] = useState<IOnlinePlanValues[]>(data)
  const [planValue, setPlanValue] = useState<IOnlinePlanValues>()

  const planID = watch(`${planValue?.value}.in_person.id`)

  const [modalOptions, setModalOptions] = useState<TModalProps>({
    title: '',
    description: '',
    confirmTitle: '',
    onConfirm: () => {
      return
    },
  })

  const handlePlanStatus = (
    planValue: IOnlinePlanValues,
    status: boolean,
    hasStudent: boolean,
  ) => {
    setPlanValue(planValue)
    openModal(
      !hasStudent && status
        ? 'delete-in-person-plan-modal'
        : 'in-person-plan-status-modal',
    )
    setModalOptions(handleModalInfo(planValue, hasStudent, status))
  }

  const handleShowFrequency = (id: number) => {
    const updatedArray = plansValues.map((item) => {
      if (item.id === id) {
        return { ...item, showFrequency: !item.showFrequency }
      }
      return item
    })

    setPlansValues(updatedArray)
  }

  const handlePlansStatusOptions = (status: boolean, hasStudent: boolean) => {
    switch (true) {
      case status && hasStudent:
        return {
          label: 'Desativar plano',
          icon: 'close',
          color: 'critical',
        }
      case !status:
        return {
          label: 'Reativar plano',
          icon: 'check',
        }
      default:
        return {
          label: 'Deletar Plano',
          icon: 'trashCan',
          color: 'critical',
        }
    }
  }

  const handleModalInfo = (
    type: IOnlinePlanValues,
    hasActive: boolean,
    initialStatus: boolean,
  ) => {
    switch (true) {
      case hasActive:
        return {
          title: `Desativar plano`,
          description:
            'O desativamento do plano só será possível quando não houver mais alunos ativos cadastrados nele.',
          confirmTitle: 'Lista de alunos',
          onConfirm: () => {
            closeModal()
            navigate(
              {
                pathname: urls.athletes,
                search: `plan_model=in_person&plan_periodicity=${type.value}`,
              },
              { state: { planEdit: true } },
            )
          },
        }
      case initialStatus:
        return {
          title: `Deseja desativar esse plano?`,
          description:
            'Ele não será excluído permanentemente do nosso sistema, você poderá reativá-lo a qualquer momento.',
          confirmTitle: 'Desativar plano',
          onConfirm: () => {
            setValue(`${type?.value}.in_person.status`, 'inactive'),
              closeModal(),
              dispatch(triggerDeactivateProfilePlan({ id: planID }))
          },
        }
      default:
        return {
          title: 'Deseja ativar esse plano?',
          description:
            'Ele voltará com as informações e valores que você adicionou anteriormente.',
          confirmTitle: 'Ativar plano',
          onConfirm: () => {
            setValue(`${type?.value}.in_person.status`, 'active'),
              closeModal(),
              dispatch(triggerActiveProfilePlan({ id: planID }))
          },
        }
    }
  }

  return (
    <div className="my-6 flex w-full flex-col gap-6">
      {plansValues.map((planValue) => {
        const hasValue = watch(
          `${planValue.value}.in_person.value.custom`,
        )?.some((value: string) => value !== '')
        const status =
          watch(`${planValue.value}.in_person.status`) === 'active'
            ? true
            : false
        const hasStudent = watch(
          `${planValue.value}.in_person.has_active_subscriptions`,
        )
        const options = handlePlansStatusOptions(status, hasStudent)

        return (
          display &&
          hasValue &&
          Boolean(watch(`${planValue.value}.in_person.active`)) && (
            <div className="relative flex w-full items-center justify-center">
              <Swipe
                active={!isDesktop}
                actions={[
                  {
                    color: theme.colors.interactive.default,
                    icon: 'edit',
                    onClick: () => setShowCustomPlanModal(true),
                  },
                  {
                    color: options.color,
                    icon: options.icon as IconsType,
                    onClick: () =>
                      handlePlanStatus(planValue, status, hasStudent),
                  },
                ]}
              >
                <Tag key={planValue.id} className="cursor-pointer p-8">
                  <div className="flex flex-col">
                    <div
                      className={cn('flex h-full w-full items-center', {
                        'pb-12': planValue.showFrequency,
                      })}
                    >
                      <div className="flex h-full flex-col items-center justify-center">
                        <p className="text-copy4 font-semibold">
                          Plano {planValue.label} {!status && '(desativado)'}
                        </p>
                      </div>
                    </div>
                    {watch(`${planValue.value}.in_person.value.custom`)?.map(
                      (values: string, index: number) =>
                        values !== '' &&
                        planValue.showFrequency && (
                          <div
                            key={index}
                            className="mb-10 ml-5 mr-4 flex w-auto justify-between xl:ml-5 xl:mr-20"
                          >
                            <Body type="copy3" weight={500}>
                              {index + 1} {index + 1 === 1 ? 'vez' : 'vezes'}
                              /semana
                            </Body>
                            <Body type="copy2" weight={700}>
                              {values}
                            </Body>
                          </div>
                        ),
                    )}
                  </div>
                  <div className="absolute right-2 top-6 hidden flex-row gap-6 xl:flex">
                    <IconButton
                      variation="outlined"
                      size="small"
                      iconName={
                        planValue.showFrequency ? 'expandLess' : 'expandMore'
                      }
                      onClick={() => handleShowFrequency(planValue.id)}
                    />
                    <DropdownMenu
                      items={[
                        {
                          icon: 'edit',
                          label: 'Editar',
                          onClick: () => setShowCustomPlanModal(true),
                        },
                        {
                          color: options.color as
                            | 'default'
                            | 'critical'
                            | 'warning'
                            | 'success',
                          icon: options.icon as IconsType,
                          label: options.label,
                          onClick: () =>
                            handlePlanStatus(planValue, status, hasStudent),
                        },
                      ]}
                    >
                      <IconButton size="small" iconName="moreVertical" />
                    </DropdownMenu>
                  </div>
                </Tag>
              </Swipe>
            </div>
          )
        )
      })}
      {isVisible === 'delete-in-person-plan-modal' && (
        <ModalDelete
          description={
            'Você está excluindo um plano que não possui alunos cadastrados.'
          }
          onConfirm={() => {
            setValue(
              `${planValue?.value}.in_person.value.custom`,
              Array(7).fill(''),
            ),
              setValue(`${planValue?.value}.in_person.active`, false),
              setValue(`${planValue?.value}.in_person.status`, 'inactive'),
              closeModal(),
              dispatch(
                triggerDeleteProfilePlan({
                  id: planID,
                  successCallback: () =>
                    dispatch(
                      triggerGetProfilePlan({
                        profile_pk: userData?.profileId || 0,
                      }),
                    ),
                }),
              )
          }}
          confirmTitle="Excluir"
          title={'Tem certeza que deseja excluir?'}
        />
      )}
      {isVisible === 'in-person-plan-status-modal' && (
        <ModalConfirmation
          width="100%"
          description={modalOptions.description}
          onConfirm={modalOptions.onConfirm}
          confirmTitle={modalOptions.confirmTitle}
          title={modalOptions.title}
        />
      )}
    </div>
  )
}

export default CustomPlans
