// Libraries
import { cva } from 'class-variance-authority'

export const tagContainerStyles = cva(
  [
    'bg-surface relative w-full rounded-[2px] py-4 pr-6 pl-4 hover:bg-background',
  ],
  {
    variants: {
      border: {
        top: 'border-t-4',
        right: 'border-r-4',
        bottom: 'border-b-4',
        left: 'border-l-4',
      },
    },
    defaultVariants: {
      border: 'left',
    },
  },
)
