// React
import { FC } from 'react'

// Misc
import { cn } from 'utils/helpers/classess'

// Assets
import EmptyFolder from 'assets/images/folder-2.svg'
import FullFolder from 'assets/images/folder-1.svg'

interface Props {
  files: number
  last_deposit: string
  onClick?: () => void
  title: string
  className?: string
}

const AssessmentCard: FC<Props> = ({
  files,
  last_deposit,
  onClick,
  title,
  className,
}) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        'flex h-fit cursor-pointer flex-col gap-1 rounded-4 border border-border-input',
        'p-6 shadow-md xl:max-w-[338px] xl:py-6 xl:pl-4 xl:pr-6',
        className,
      )}
    >
      <div className="flex gap-4">
        <img
          src={files ? FullFolder : EmptyFolder}
          className="h-[42px] w-[42px]"
        />

        <div className="flex flex-col gap-2">
          <p className="text-copy2 font-bold">{title}</p>
          <p className="text-copy5 text-text-subdued">
            {last_deposit ? `Último adicionado: ${last_deposit}` : ''}
          </p>
        </div>
      </div>
      <p className="text-right text-copy3 font-semibold text-text-subdued">
        {files} Arquivo{files === 1 ? '' : 's'}
      </p>
    </div>
  )
}

export default AssessmentCard
