// Libs
import { cva } from 'class-variance-authority'

export const inputContainerStyles = cva(
  [
    'relative flex rounded-[3px] border transition-all duration-300 ease-linear aria-disabled:border-icon-disabled',
  ],
  {
    variants: {
      color: {
        critical: 'text-text-critical',
        default: 'text-border-input',
        focused: 'text-text',
        success: 'text-text-success',
      },
    },
    defaultVariants: {
      color: 'default',
    },
  },
)

export const inputStyles = cva(
  [
    'cursor-text border-none bg-transparent text-text caret-[#202223] outline-none transition-all duration-300 ease-linear',
    'placeholder:text-text-subdued disabled:cursor-not-allowed disabled:bg-surface-disabled disabled:text-text-subdued ',
    'pl-4 calendar-picker-indicator-none',
  ],
  {
    variants: {
      scale: {
        small: 'py-3 text-copy4',
        medium: 'py-[15px] text-copy3',
        large: 'py-[18px] text-copy2',
      },
    },
    defaultVariants: { scale: 'medium' },
  },
)
