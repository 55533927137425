// Libs
import { cva } from 'class-variance-authority'

export const colorStyles = cva([''], {
  variants: {
    color: {
      success: 'text-text-success fill-text-success border-text-success',
      warning: 'text-text-warning fill-text-warning border-text-warning',
      info: 'text-text-highlight fill-text-highlight border-text-highlight',
      error: 'text-text-critical fill-text-critical border-text-critical',
    },
  },
  defaultVariants: {
    color: 'success',
  },
})
