// Models
import { IExerciseListPayload } from 'storage/exercise/models'
import { IFormInputs, IModelInput, TExercisesGroupFilter } from 'models'
import { IWorkoutModelData } from 'storage/workoutModel/models'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'

// React
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'

// Libraries
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { createSelector } from '@reduxjs/toolkit'
import isEqual from 'lodash.isequal'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { prepareModelsToDisplay, prepareModelsToRedux } from 'filters/workouts'
import { success } from 'storage/workoutModel/duck'
import {
  exerciseSelector,
  workoutModelSelector,
  workoutRoutineSelector,
} from 'utils/helpers/redux'
import { getModelName, uid } from 'utils/functions'
import { urls } from 'routes/paths'
import {
  success as successRoutine,
  triggerGetWorkoutRoutineTemplate,
} from 'storage/workoutRoutine/duck'
import {
  triggerLoadExercises,
  triggerLoadMoreExercises,
} from 'storage/exercise/duck'
import {
  useDebounceFunction,
  useFilters,
  useMediaQuery,
  useModal,
  useWorkoutModelsForm,
} from 'hooks'
import { workoutRoutineSchema } from 'schemas'

// Components
import * as Styled from './styled'
import { Loading } from 'heeds-ds'
import {
  Modal,
  ModalConfirmation,
  ModalDelete,
  ModalRenameWorkoutModel,
  ModalWorkoutModelTemplates,
} from 'components'
import Desktop from './desktop'
import Mobile from './mobile'

// Constants
import { EXERCISES_FILTERS } from 'utils/constants'

const page_size = 20

interface IFiltersQuery extends IExerciseListPayload {
  etapa?: '1' | '2'
  'treino-selecionado'?: string
}

export interface IWorkoutRoutineFormData {
  id?: number
  name: string
  difficulty: string
  goal: string
}

const selector = createSelector(
  [exerciseSelector, workoutModelSelector, workoutRoutineSelector],
  (
    { exercises, loading: loadingExercise },
    { loading: loadingModel, workoutModels },
    { loading: loadingRoutine, workoutRoutineTemplate },
  ) => {
    return {
      exercises,
      loadingExercise,
      loadingModel,
      loadingRoutine,
      workoutModels,
      workoutRoutineTemplate,
    }
  },
)

const LibraryWorkoutRoutineModel: FC = () => {
  const { id = '' } = useParams()
  const {
    exercises,
    loadingExercise,
    loadingRoutine,
    workoutModels,
    workoutRoutineTemplate,
  } = useSelector(selector)
  const { breakpoints } = useContext(ThemeContext)
  const { filters, isValueIncluded, setFilter, toggleFilter, resetFilters } =
    useFilters<IFiltersQuery>(window.location.search)
  const { isVisible, closeModal, openModal } = useModal()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)
  const navigate = useNavigate()

  const [, setSearchParams] = useSearchParams()

  const [showFilters, setShowFilters] = useState(false)
  const [stateModels, setStateModels] = useState<IModelInput[]>(
    workoutModels && workoutModels.length > 0
      ? workoutModels
      : [
          {
            id: 'NEW' + uid(),
            name: getModelName(0),
            workout_set: {},
            workout_routine: workoutRoutineTemplate?.id,
          },
        ],
  )

  const routineMethods = useForm<IWorkoutRoutineFormData>({
    defaultValues: workoutRoutineTemplate ? workoutRoutineTemplate : {},
    resolver: yupResolver(workoutRoutineSchema),
    delayError: 800,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { reset } = routineMethods

  const {
    methods,
    updateWorkoutModel,
    selectedModelIndex,
    setSelectedModelIndex,
    removeWorkoutModel,
    ...commonHookProps
  } = useWorkoutModelsForm(stateModels, setStateModels)

  const formValues = methods.watch()

  const modelSelect = filters['treino-selecionado']

  const openRenameModelModal = () => openModal('rename-workout-model-modal')

  const openRemoveModelModal = () => openModal('remove-workout-model-modal')

  const handleRemoveWorkoutModel = () => {
    const modelId = stateModels[selectedModelIndex].id
    removeWorkoutModel(modelId)
  }

  const selectWorkoutModel = (modelIndex: number) => {
    const newFilters = { ...filters }
    delete newFilters['treino-selecionado']
    resetFilters(newFilters)
    setSelectedModelIndex(modelIndex)
  }

  const onChangeFilters = (filtersA: TExercisesGroupFilter[]) => {
    type Accumulator = Record<string, string[]>

    const filterReducer = (
      accumulator: Accumulator,
      item: TExercisesGroupFilter,
    ) => {
      if (item.active && item.param) {
        if (!accumulator[item.param]) {
          accumulator[item.param] = []
        }
        accumulator[item.param].push(item.name)
      }
      return accumulator
    }
    const newFilters: Accumulator = filtersA.reduce(filterReducer, {})

    resetFilters(newFilters)

    handleReloadExercises(newFilters)
  }
  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filters = setFilter('name', event.target.value, true)
    debouncedLoad(filters)
  }

  const handleEndReached = () => {
    if (exercises && !loadingExercise && exercises?.next) {
      const query = {
        ...filters,
        page: exercises.next,
        page_size,
      }
      dispatch(triggerLoadMoreExercises(query))
    }
  }

  const openWorkoutModelTemplateModal = () => {
    openModal('add-new-model-modal')
  }

  const handleLibraryModel = (templateModel: IWorkoutModelData) => {
    const preparedModel = prepareModelsToDisplay([templateModel])[0]

    if (stateModels && selectedModelIndex !== undefined) {
      const selectedModel = stateModels?.[selectedModelIndex]
      updateWorkoutModel?.(selectedModel.id, {
        ...preparedModel,
        id: selectedModel.id,
      })
    }
  }

  const handleReloadExercises = (newFilters: IExerciseListPayload) => {
    const query = {
      ...newFilters,
      page_size,
    }
    dispatch(triggerLoadExercises(query))
  }

  const debouncedLoad = useDebounceFunction<
    (newFilters: IExerciseListPayload) => void
  >(handleReloadExercises, 1000)

  const onClickFilter = () => {
    setShowFilters((prev) => !prev)
  }

  const removeFilter = (filter: TExercisesGroupFilter) => {
    if (filter.param) {
      const newFilters = toggleFilter(
        filter.param as string,
        filter.name,
        false,
      )

      handleReloadExercises(newFilters)
    }
  }

  const changePage = (page: string) => setSearchParams({ etapa: page })

  const onSubmitRoutine = (formData: IWorkoutRoutineFormData) => {
    workoutRoutineTemplate &&
      dispatch(
        successRoutine({
          workoutRoutineTemplate: {
            ...workoutRoutineTemplate,
            name: formData.name,
          },
        }),
      )
  }

  const onSubmit = useCallback(
    (formData: IFormInputs) => {
      const reduxData = prepareModelsToRedux(formData, stateModels)

      const updatedModels = reduxData.filter((model) =>
        Boolean(Object.values(model.workout_set ?? {}).length),
      )

      dispatch(success({ workoutModels: updatedModels }))

      navigate(
        generatePath(urls.libraryWorkoutRoutineReview, {
          id,
        }),
      )

      closeModal()
    },
    [closeModal, dispatch, id, navigate, stateModels],
  )

  const handleGoToReview = () => {
    methods.handleSubmit(onSubmit)()
    routineMethods.handleSubmit(onSubmitRoutine)()
  }

  const workoutRoutineProps = {
    ...commonHookProps,
    filters: EXERCISES_FILTERS.map((filter) => ({
      active: isValueIncluded(filter.param, filter.value.toString()) || false,
      name: filter.value,
      label: filter.label,
      param: filter.param,
    })),
    inputValue: filters?.name || '',
    loadMoreExercises: handleEndReached,
    methods,
    onChangeFilters,
    onClickFilter,
    onSearchChange,
    onSubmit,
    removeFilter,
    removeWorkoutModel: openRemoveModelModal,
    renameWorkoutModel: openRenameModelModal,
    routineMethods,
    selectWorkoutModel,
    selectedModelIndex,
    showFilters,
    stateModels,
    updateWorkoutModel,
    openWorkoutModelTemplateModal,
  }

  useEffect(() => {
    const payload = prepareModelsToRedux(formValues, stateModels)

    if (!isEqual(payload, workoutModels)) {
      setStateModels(payload)
      dispatch(success({ workoutModels: payload }))
    }
  }, [dispatch, formValues, setStateModels, stateModels, workoutModels])

  useEffect(() => {
    modelSelect && setSelectedModelIndex(Number(modelSelect))
  }, [modelSelect, setSelectedModelIndex])

  useLayoutEffect(() => {
    dispatch(triggerLoadExercises({ ...filters, page_size }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useLayoutEffect(() => {
    if (id) {
      const routine_pk = Number(id)
      const successCallback = (response: IWorkoutRoutine) => {
        dispatch(
          success({
            workoutModels: prepareModelsToDisplay(
              response.workout_models || [],
            ),
          }),
        )
        reset(response)
      }

      dispatch(
        triggerGetWorkoutRoutineTemplate({ routine_pk, successCallback }),
      )
    }
  }, [dispatch, id, reset])

  if (
    loadingRoutine ||
    !workoutRoutineTemplate ||
    workoutRoutineTemplate?.id !== Number(id)
  ) {
    return <Loading active />
  }

  return (
    <Styled.Container>
      <Styled.Content>
        {isDesktop ? (
          <Desktop {...workoutRoutineProps} />
        ) : (
          <Mobile
            {...workoutRoutineProps}
            changePage={changePage}
            mobilePage={filters.etapa}
          />
        )}
      </Styled.Content>

      {isVisible === 'go-to-review-modal' && (
        <Modal
          title="Revisar treino"
          description="Você está indo revisar o treino criado. É possível retornar e continuar editando. Deseja prosseguir?"
          maxWidth="51rem"
          icon="info"
          onClose={closeModal}
          primaryButton={{
            name: 'Ir para revisão',
            onClick: handleGoToReview,
          }}
          secondaryButton={{
            name: 'Cancelar',
          }}
        />
      )}

      {isVisible === 'remove-workout-model-modal' && (
        <ModalDelete
          onConfirm={handleRemoveWorkoutModel}
          title="Excluir treino"
          cancelTitle="Continar editando"
          confirmTitle="Sim, quero excluir"
          description="Você está excluindo um treino e perderá todos os exercícios adicionados nele. Deseja prosseguir?"
        />
      )}

      {isVisible === 'rename-workout-model-modal' && (
        <ModalRenameWorkoutModel
          updateWorkoutModel={updateWorkoutModel}
          workoutModel={stateModels[selectedModelIndex]}
        />
      )}

      {isVisible === 'add-new-model-modal' && (
        <ModalWorkoutModelTemplates
          onSelectWorkoutModel={(model) => handleLibraryModel(model)}
        />
      )}

      {isVisible === 'empty-model' && (
        <ModalConfirmation
          confirmTitle="Ir para revisão"
          description="Você tem um treino que foi criado e não possui exercícios adicionados, indo para revisão ele será excluído. Deseja prosseguir?"
          onConfirm={handleGoToReview}
          title="Revisar treino"
        />
      )}
    </Styled.Container>
  )
}

export default LibraryWorkoutRoutineModel
