// Models
import { IAthleteSchedule } from 'services/athleteSchedule/@types'
import { IAnamneseAnswerState } from 'storage/assessmentAnamneseAnswer/models'
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import { TRoutineStatus } from 'models'
import IStore from 'lib/redux/models'

// React
import { FC, useCallback, useContext, useLayoutEffect, useState } from 'react'

// Libraries
import {
  Navigate,
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cn } from 'utils/helpers/classess'
import { daysName, hourMinuteFormat } from 'utils/functions'
import { triggerLoadAthlete } from 'storage/athleteInfo/duck'
import { triggerLoadAthleteOverview } from 'storage/athleteOverview/duck'
import { triggerLoadAnamneseAnswers } from 'storage/assessmentAnamneseAnswer/duck'
import { triggerLoadListWorkoutRoutines } from 'storage/workoutRoutine/duck'
import { triggerToastWarning } from 'storage/general/duck'
import { urls } from 'routes/paths'
import { useMediaQuery, useMenu } from 'hooks'

// Components
import * as Blocks from 'blocks/dashboard/athlete/AthleteProfile'
import { Body, Icon, IconButton, LinkButton } from 'heeds-ds'
import AthleteHoursCard from 'components/AthleteHoursCard'
import AthleteProfileLoader from './loader'

const USER_INFO_KEYS = [
  'birthdate',
  'gender',
  'phone_number',
  'email',
  'address',
  'instagram',
  'emergency_contact_name',
  'emergency_contact_phone_number',
]

type DaysOfWeek = {
  sun: boolean
  mon: boolean
  tue: boolean
  wed: boolean
  thu: boolean
  fri: boolean
  sat: boolean
}

const formatScheduleList = (schedules: IAthleteSchedule[]) => {
  const orderedDays: (keyof DaysOfWeek)[] = [
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
    'sun',
  ]

  return schedules.flatMap((schedule) => {
    const end = hourMinuteFormat(schedule.end ?? '')
    const start = hourMinuteFormat(schedule.start ?? '')
    const days = Object.fromEntries(
      Object.entries(schedule.recurring_schedule ?? {}).filter(
        ([key]) => key !== 'id',
      ),
    )
    const sortedDays = orderedDays.reduce((acc, day) => {
      acc[day] = days[day]
      return acc
    }, {} as DaysOfWeek)
    const recurringDays = Object.keys(sortedDays) as Array<
      keyof typeof daysName
    >

    return recurringDays.flatMap((recurring) =>
      schedule.recurring_schedule &&
      schedule.recurring_schedule[recurring] === true
        ? {
            location: schedule.location ?? '',
            end,
            start,
            day: daysName[recurring],
          }
        : [],
    )
  })
}

const AthleteProfile: FC = () => {
  const { id } = useParams()
  const { athleteOverview, workoutRoutine } = useSelector<IStore, IStore>(
    (state) => state,
  )
  const { info, loading: infoAthletLoading } = useSelector<
    IStore,
    IAthleteInfoState
  >((state) => state.athleteInfo)
  const { anamneseAnswers, loading: anamneseLoading } = useSelector<
    IStore,
    IAnamneseAnswerState
  >(({ assessmentAnamneseAnswer }) => assessmentAnamneseAnswer)

  const navigate = useNavigate()
  const { setPagename, setColor, setLongHeader } = useMenu()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)
  const [open, setOpen] = useState(false)

  const navigateToFinancial = useCallback(
    () =>
      navigate(
        generatePath(urls.athleteSchedule, {
          id,
        }),
      ),
    [navigate, id],
  )

  const { error, overview, loading } = athleteOverview
  const { workoutRoutines, loading: loadingWorkouts } = workoutRoutine

  const loadingPage =
    loading || parseInt(id ?? '') !== overview?.id || infoAthletLoading

  const profileInfo = Object.fromEntries(
    Object.entries(overview ?? {})
      .filter(([key]) => USER_INFO_KEYS.includes(key))
      .sort(
        (a, b) => USER_INFO_KEYS.indexOf(a[0]) - USER_INFO_KEYS.indexOf(b[0]),
      ),
  )

  useLayoutEffect(() => {
    if (id) {
      dispatch(triggerLoadAthleteOverview(parseInt(id)))
    }
  }, [id, dispatch])

  useLayoutEffect(() => {
    if (id) dispatch(triggerLoadAnamneseAnswers({ athlete_pk: Number(id) }))
  }, [id, dispatch])

  useLayoutEffect(() => {
    if (id) {
      const query = {
        athlete: id,
        routine_status: 'current' as TRoutineStatus,
      }
      dispatch(triggerLoadListWorkoutRoutines(query))
    }
  }, [id, dispatch])

  useLayoutEffect(() => {
    if (id) {
      dispatch(triggerLoadAthlete({ id: Number(id) }))
    }
  }, [dispatch, id])

  useLayoutEffect(() => {
    setPagename(
      <Blocks.Info
        status={overview?.status}
        info={profileInfo}
        name={overview?.name || ''}
        profileUrl={generatePath(urls.athleteGeneral, {
          id,
        })}
        loading={loadingPage}
      />,
    )

    return () => setPagename('DASHBOARD')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPagename, overview, id, loadingPage])

  useLayoutEffect(() => {
    setColor(theme.colors.surface.default)

    return () => setColor('')
  }, [setColor, theme.colors.surface.default])

  useLayoutEffect(() => {
    setLongHeader(true)

    return () => setLongHeader(false)
  }, [setLongHeader])

  if (
    (id && parseInt(id) === overview?.id && error === '404') ||
    isNaN(parseInt(id ?? ''))
  ) {
    dispatch(triggerToastWarning({ customTitle: 'ID do atleta inválido!' }))

    return <Navigate to={urls.athletes} replace />
  }

  if (loadingPage) {
    return <AthleteProfileLoader />
  }

  return (
    <div className="overflow-hidden xl:mt-0 xl:overflow-auto">
      <div className="h-full overflow-hidden xl:overflow-auto">
        {isDesktop && (
          <div className="flex justify-between pl-10 pr-12 pt-10">
            <LinkButton
              size="small"
              variation="borderless"
              to={urls.athletes}
              track={buttonClickTracking}
              trackName="back_to_athletes_list"
            >
              <Icon
                iconName="arrowBack"
                color={theme.colors.interactive.default}
              />
              Voltar para alunos
            </LinkButton>
            <LinkButton
              to={generatePath(urls.athleteGeneral, {
                id,
              })}
              size="xsmall"
            >
              Editar perfil
            </LinkButton>
          </div>
        )}
        <div
          className={cn(
            'flex flex-col items-center justify-center gap-2 p-6 xl:grid xl:grid-cols-3 xl:flex-row',
            'xl:items-start xl:justify-start xl:gap-4 xl:pb-6 xl:pl-10 xl:pr-12 xl:pt-2',
          )}
        >
          <div className="col-span-1 flex w-full flex-col gap-2">
            <div className="hidden xl:flex">
              <Blocks.Info
                status={overview?.status}
                info={profileInfo}
                name={overview?.name || ''}
              />
            </div>
            {info && <Blocks.GeneralInfo info={info} />}
            <div className="flex items-center justify-between rounded-2 border border-border-input bg-surface p-4">
              <p className="text-copy3 font-semibold">Relatórios</p>
              <IconButton
                iconName="openInNew"
                size="small"
                onClick={() =>
                  navigate(
                    generatePath(urls.athleteRoutinesReport, {
                      id,
                    }),
                  )
                }
              />
            </div>
            <Blocks.Financial overview={overview} />
          </div>

          <div className="col-span-2 flex w-full flex-col gap-2 overflow-x-hidden xl:overflow-y-scroll">
            <div className="rounded-2 border border-border-input bg-surface p-4 shadow-sm">
              <div
                className={cn(
                  'mb-4 flex justify-between',
                  {
                    'mb-0': !open,
                  },
                  'xl:mb-4',
                )}
                onClick={() => setOpen(!open)}
              >
                <p className="text-copy3 font-semibold">Horários Agendados</p>
                <div className="hidden xl:flex">
                  <IconButton
                    onClick={navigateToFinancial}
                    iconName="openInNew"
                  />
                </div>
                <div className="xl:hidden">
                  <Icon iconName={open ? 'expandMore' : 'expandLess'} />
                </div>
              </div>
              {!!overview.schedules?.length && (
                <div
                  className={cn('flex flex-col gap-2 xl:grid xl:grid-cols-5', {
                    hidden: !open,
                  })}
                >
                  {formatScheduleList(overview.schedules)?.map((hour) => (
                    <AthleteHoursCard key={hour.day} {...hour} />
                  ))}
                </div>
              )}
              {!overview.schedules?.length && (
                <>
                  <Body
                    type="copy3"
                    align="center"
                    margin="32px auto"
                    color={theme.colors.text.disabled}
                  >
                    Esse aluno não possui um plano ativo.
                  </Body>
                  <LinkButton
                    size="xsmall"
                    variation="borderless"
                    to={generatePath(urls.athleteSchedule, { id })}
                    track={buttonClickTracking}
                    trackName="athlete_profile_empty_schedule_link"
                    className="mx-auto"
                  >
                    Preencher horários
                  </LinkButton>
                </>
              )}
            </div>
            <Blocks.ProfileTabs
              athleteId={overview.id}
              comments={overview.comment}
              routines={workoutRoutines}
              anamneses={anamneseAnswers}
              loading={anamneseLoading || loadingWorkouts}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AthleteProfile
