// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { cn } from 'utils/helpers/classess'
import { useMediaQuery } from 'hooks'

// Components
import { Skeleton } from 'heeds-ds'

const Loader: FC = () => {
  const { breakpoints } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)

  const cellBase = cn('flex items-center gap-1 p-4')

  return isDesktop ? (
    <div className="grid w-full grid-cols-financial-list border-b border-border-input text-copy4 text-text">
      <div className="flex gap-2 p-4">
        <Skeleton className="h-10 w-10 rounded-[50%]" />

        <div className="flex flex-col gap-1">
          <Skeleton className="h-4 w-20" />
          <Skeleton className="h-4 w-20" />
        </div>
      </div>

      <div className={cellBase}>
        <Skeleton className="h-4 w-20" />
      </div>

      <div className={cellBase}>
        <Skeleton className="h-4 w-20" />
      </div>

      <div className={cellBase}>
        <Skeleton className="h-[30px] w-[82px] rounded-[15px]" />
      </div>

      <div className={cellBase}>
        <Skeleton className="h-4 w-10" />
        <Skeleton className="h-4 w-10" />
      </div>

      <div className={cellBase}>
        <Skeleton className="h-9 w-9" />
      </div>
    </div>
  ) : (
    <div className="flex w-full flex-col gap-4 rounded-3xl border border-border-input bg-surface p-4">
      <div className="flex items-center gap-4">
        <Skeleton className="h-[52px] w-[52px] rounded-[50%]" />

        <div className="mr-auto flex flex-col gap-1">
          <Skeleton className="h-[18px] w-20" />
          <Skeleton className="h-[18px] w-[175px]" />
        </div>

        <Skeleton className="h-9 w-9" />
      </div>
    </div>
  )
}

export default Loader
