// Models
import { IAnamneseTemplate } from 'storage/assessmentAnamneseTemplate/models'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC } from 'react'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { dateFormatEnglishToPortuguese } from 'utils/functions'

// Components
import * as Styled from './styled'
import { DropdownMenu, IconButton } from 'heeds-ds'

export type Props = {
  anamneseTemplate: IAnamneseTemplate
  onClick: (anamneseId: string) => void
  onDuplicated: (anamnese: IAnamneseTemplate) => void
  onEdit: (anamneseId: string) => void
  onRemove: () => void
  onShare: () => void
  onVisualize: (anamneseId: string) => void
}

const Desktop: FC<Props> = ({
  anamneseTemplate,
  onClick,
  onDuplicated,
  onEdit,
  onRemove,
  onShare,
  onVisualize,
}) => {
  const { id, date, title, personal, is_being_used } = anamneseTemplate

  const options: TMenuDropdownOption[] = [
    {
      icon: 'edit',
      label: 'Editar',
      onClick: () => onEdit(id),
    },
    {
      icon: 'contentCopy',
      label: 'Duplicar',
      onClick: () => onDuplicated(anamneseTemplate),
    },
    {
      icon: 'share',
      label: 'Compartilhar',
      onClick: onShare,
      disabled: title === 'Anamnese modelo',
    },
    {
      color: 'critical',
      icon: 'delete',
      label: 'Excluir',
      onClick: onRemove,
      disabled: title === 'Anamnese modelo' || is_being_used,
    },
  ]

  const systemOptions: TMenuDropdownOption[] = [
    {
      icon: 'eye',
      label: 'Ver anamnese',
      onClick: () => onVisualize(id),
    },
  ]

  const showVisualize = title === 'Cuidados médicos' || title === 'Par-Q'

  return (
    <Styled.Row data-testid="row" onClick={() => onClick(id)}>
      <Styled.Cell data-testid="columnTitle">
        <Styled.Text>{title}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell data-testid="columnCreatedBy">
        <Styled.Text>{personal === 0 ? 'CADU' : 'Você'}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell data-testid="columnDate">
        <Styled.Text>{dateFormatEnglishToPortuguese(date)}</Styled.Text>
      </Styled.Cell>

      <Styled.Cell onClick={(e) => e.stopPropagation()}>
        {showVisualize ? (
          <IconButton
            size="small"
            iconName="eye"
            track={buttonClickTracking}
            trackName="navigate_to_anamnese_model_visualizer"
          />
        ) : (
          <DropdownMenu items={personal === 0 ? systemOptions : options}>
            <IconButton
              size="small"
              iconName="moreVertical"
              track={buttonClickTracking}
              trackName="open_anamnese_model_options"
            />
          </DropdownMenu>
        )}
      </Styled.Cell>
    </Styled.Row>
  )
}

export default Desktop
