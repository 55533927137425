// Models
import IStore from 'lib/redux/models'

// React
import { FC, Fragment, useMemo } from 'react'

// Libraries
import { screens } from 'tailwindcss/defaultTheme'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cn } from 'utils/helpers/classess'
import {
  triggerActivateSubscription,
  triggerDeactivateSubscription,
} from 'storage/financialSubscription/duck'
import { triggerLoadAthleteOverview } from 'storage/athleteOverview/duck'
import { triggerLoadInstallments } from 'storage/financial/duck'
import { triggerToastSuccess } from 'storage/general/duck'
import { useMediaQuery, useModal } from 'hooks'
import { urls } from 'routes/paths'

// Components
import { Avatar, Button, Icon } from 'heeds-ds'
import { Modal } from 'components'

// Constants
const STATUS_PROPS = {
  active: {
    button: 'Desativar Aluno',
    modal: {
      description:
        'O status do aluno ficará como desativado para o sistema. Você poderá reativar esse aluno a qualquer momento. Deseja prosseguir?',
      title: 'Desativar aluno',
      confirmTitle: 'Desativar',
    },
    toast: 'desativado',
  },
  inactive: {
    button: 'Ativar Aluno',
    modal: {
      description: `Tem certeza que deseja reativar este aluno?`,
      title: 'Reativar aluno',
      confirmTitle: 'Ativar',
    },
    toast: 'ativado',
  },
  invited: {
    button: 'Ativar Aluno',
    modal: {
      description: `Tem certeza que deseja ativar este aluno?`,
      title: 'Ativar aluno',
      confirmTitle: 'Ativar',
    },
    toast: 'ativado',
  },
}

const FinancialAthleteHeader: FC = () => {
  const { id = '' } = useParams()
  const { overview } = useSelector((state: IStore) => state.athleteOverview)
  const { subscription } = useSelector(
    (state: IStore) => state.financialSubscription,
  )
  const { closeModal, openModal, isVisible } = useModal()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(`(min-width: ${screens.lg})`)

  const status = useMemo(() => overview?.status ?? 'inactive', [overview])

  const handleChangeStatus = () => {
    const successCallback = () => {
      dispatch(triggerLoadAthleteOverview(Number(id)))
      dispatch(triggerLoadInstallments({ athlete_pk: Number(id) }))
      dispatch(
        triggerToastSuccess({
          customTitle: 'Sucesso',
          message: `Aluno ${overview?.name ?? ''} foi ${
            STATUS_PROPS[status].toast
          }.`,
        }),
      )
    }
    if (subscription) {
      const payload = {
        id: subscription.id,
        successCallback,
      }

      dispatch(
        status === 'active'
          ? triggerDeactivateSubscription(payload)
          : triggerActivateSubscription(payload),
      )
    }

    closeModal()
  }

  return (
    <Fragment>
      <div className="relative flex w-full items-center gap-6">
        <div
          className={cn(
            'flex w-full flex-col-reverse gap-4 rounded-2 border border-border-input bg-surface p-4',
            'xl:flex-row xl:items-center xl:justify-between xl:px-4 xl:py-2',
          )}
        >
          {isDesktop && (
            <Button
              onClick={() => navigate(urls.financial)}
              size="xsmall"
              track={buttonClickTracking}
              trackName="back_to_athlete_subscriptions"
              variation="borderless"
            >
              <Icon iconName="arrowBack" />
              Voltar para financeiro
            </Button>
          )}
          <div className="flex items-center gap-4">
            <div className="flex xl:hidden">
              <Avatar name={overview?.name ?? ''} scale="small" />
            </div>

            <div className="flex flex-col gap-1 xl:items-center">
              <p className="text-copy3 font-bold leading-7">
                {overview?.name ?? ''}
              </p>
              <p className="text-copy3 text-text-subdued">Financeiro</p>
            </div>
          </div>
          <div
            className={cn(
              'fixed inset-x-0 bottom-0 flex w-full items-center justify-center border-t border-border-input',
              'bg-surface p-4 xl:relative xl:w-auto xl:p-0',
            )}
          >
            <Button
              cancel={status === 'active'}
              onClick={() => openModal('(de)activate-athlete-modal')}
              size="xsmall"
              track={buttonClickTracking}
              trackName="open_athlete_activate_or_deactivate_modal"
            >
              {STATUS_PROPS[status].button}
            </Button>
          </div>
        </div>
      </div>

      {isVisible === '(de)activate-athlete-modal' && (
        <Modal
          {...STATUS_PROPS[status].modal}
          icon="warning"
          maxWidth="530px"
          primaryButton={{
            name: `Sim, quero ${status === 'active' ? 'desativar' : 'ativar'}`,
            onClick: handleChangeStatus,
          }}
          secondaryButton={{ name: 'Cancelar' }}
        />
      )}
    </Fragment>
  )
}

export default FinancialAthleteHeader
