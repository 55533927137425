// Misc
import { cn } from '../../../../utils/classes'

export const autocompleteStyles = (scale: 'small' | 'medium' | 'large') => ({
  control: ({ isFocused }: { isFocused: boolean }) =>
    cn(
      'flex items-center rounded-[3px] border border-border-input text-copy4 text-text',
      'px-4 hover:border-border',
      {
        'shadow-action-secondaryDefault shadow-[0px_0px_5px] hover:border-interactive-hovered':
          isFocused,
      },
    ),
  indicatorSeparator: () => 'hidden',
  valueContainer: () =>
    cn('py-3', {
      'py-[15px]': scale === 'medium',
      'py-[18px]': scale === 'large',
    }),
  placeholder: () => 'text-text-subdued disabled:text-text-disabled',
  menu: ({ placement }: { placement: string }) =>
    cn(
      'select-content-scrollbar animation-slide-down-and-fade z-50 my-2 origin-top rounded-1 bg-surface shadow-lg',
      { 'top-auto bottom-full origin-bottom': placement === 'top' },
    ),
  option: ({
    isFocused,
    isSelected,
  }: {
    isFocused: boolean
    isSelected: boolean
  }) =>
    cn(
      'flex cursor-pointer items-center gap-1 px-4 py-3 text-copy4 text-text',
      {
        'font-bold bg-surface-highlightSubduedHovered': isFocused || isSelected,
      },
    ),
  noOptionsMessage: () => 'text-text-disabled',
})
