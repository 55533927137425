export const DATE_RANGE_OPTIONS = [
  { label: 'Últimos 7 dias', value: 'last_7_days' },
  { label: 'Últimos 15 dias', value: 'last_15_days' },
  { label: 'Últimos 30 dias', value: 'last_30_days' },
  { label: 'Últimos 90 dias', value: 'last_90_days' },
  { label: 'Últimos 180 dias', value: 'last_180_days' },
  { label: 'Últimos 365 dias', value: 'last_365_days' },
]

export const DIFFICULTY_OPTIONS = [
  { label: 'Iniciante', value: 'ST' },
  { label: 'Intermediário', value: 'IN' },
  { label: 'Avançado', value: 'AD' },
]

export const DIVISION_OPTIONS = [
  { label: 'Dias da semana (segunda, terça, quarta...)', value: 'DW' },
  { label: 'Alfabético (A, B, C...)', value: 'AP' },
  { label: 'Numérico (1, 2, 3...)', value: 'NU' },
]

export const GENDER_OPTIONS = [
  { label: 'Feminino', value: 'F' },
  { label: 'Masculino', value: 'M' },
  { label: 'Outro', value: 'O' },
]

export const GOAL_OPTIONS = [
  { label: 'Hipertrofia', value: 'HT' },
  { label: 'Redução de Gordura', value: 'FR' },
  { label: 'Definição Muscular', value: 'MD' },
  { label: 'Prevenção de Lesões', value: 'IP' },
  { label: 'Condicionamento Físico', value: 'PC' },
  { label: 'Qualidade de Vida', value: 'QL' },
  { label: 'Treinamento Funcional', value: 'FT' },
  { label: 'Outros', value: 'OT' },
]

export const PAYMENT_TYPE_MODAL_OPTIONS = [
  { label: 'Boleto', value: 'bank_slip' },
  { label: 'Transferência', value: 'bank_transfer' },
  { label: 'Crédito', value: 'credit_card' },
  { label: 'Débito', value: 'debit_card' },
  { label: 'Pix', value: 'pix' },
  { label: 'Dinheiro', value: 'cash' },
]

export const SEND_REMIND_SUBJECT_OPTIONS = [
  { label: 'Pagamento atrasado', value: 'late' },
  { label: 'Pagamento realizado', value: 'due' },
  { label: 'Próximo ao vencimento', value: 'next' },
]

export const SEND_REMIND_NOTIFICATION_TYPE_OPTIONS = [
  { label: 'Aplicativo', value: 'app' },
  { label: 'E-mail', value: 'email' },
  { label: 'Whatsapp', value: 'whats' },
]

export const SET_TYPE_OPTIONS = [
  { label: 'Simples', value: 'normal' },
  { label: 'Biset', value: 'bi_set' },
  { label: 'Triset', value: 'tri_set' },
]

export const TIME_MEASUREMENT_OPTIONS = [
  { label: 'Segundos', value: 'S' },
  { label: 'Minutos', value: 'M' },
  { label: 'Horas', value: 'H' },
]

export const WORKOUT_SET_FIELD_OPTIONS = [
  { label: 'Cadência', value: 'cadence' },
  { label: 'Carga', value: 'weight' },
  { label: 'Comentários', value: 'comments' },
  { label: 'Repetição', value: 'repetitions' },
]

export const MECHANIC_FIELD_OPTIONS = [
  { label: 'Isolado', value: 'ISO', id: 0 },
  { label: 'Composto', value: 'COM', id: 1 },
]

export const FORCE_TYPE_FIELD_OPTIONS = [
  { label: 'Puxar', value: 'PUL', id: 0 },
  { label: 'Empurrar', value: 'PUS', id: 1 },
  { label: 'Alongar', value: 'STC', id: 2 },
  { label: 'Compressão', value: 'COM', id: 3 },
]

export const EXERCISE_TYPE_FIELD_OPTIONS = [
  { label: 'Condicionamento Físico', value: 'CON', id: 0 },
  { label: 'Pliometria', value: 'PLY', id: 1 },
  { label: 'Autoliberação Miofascial', value: 'SMR', id: 2 },
  { label: 'Força', value: 'STR', id: 3 },
  { label: 'Aquecimento', value: 'WAR', id: 4 },
]

export const DIFFICULTY_TYPE_FIELD_OPTIONS = [
  { label: 'Fácil', value: 'EAS', id: 0 },
  { label: 'Intermediário', value: 'INT', id: 1 },
  { label: 'Avançado', value: 'ADV', id: 2 },
]

export const EQUIPMENTS_OPTIONS = [
  { label: 'peso corporal', id: 1 },
  { label: 'cabo', id: 2 },
  { label: 'máquina de alavanca', id: 3 },
  { label: 'assistido', id: 4 },
  { label: 'medicine ball', id: 5 },
  { label: 'bola de estabilidade', id: 6 },
  { label: 'banda', id: 7 },
  { label: 'barra de peso', id: 8 },
  { label: 'corda', id: 9 },
  { label: 'haltere', id: 10 },
  { label: 'barra EZ', id: 11 },
  { label: 'máquina de trenó', id: 12 },
  { label: 'ergômetro de membros superiores', id: 13 },
  { label: 'kettlebell', id: 14 },
  { label: 'barra olímpica', id: 15 },
  { label: 'com peso', id: 16 },
  { label: 'bola bosu', id: 17 },
  { label: 'banda de resistência', id: 18 },
  { label: 'rolo', id: 19 },
  { label: 'máquina de esquiador', id: 20 },
  { label: 'martelo', id: 21 },
  { label: 'máquina smith', id: 22 },
  { label: 'rolo de roda', id: 23 },
  { label: 'bicicleta estacionária', id: 24 },
  { label: 'pneu', id: 25 },
  { label: 'barra hexagonal', id: 26 },
  { label: 'máquina elíptica', id: 27 },
  { label: 'máquina de escada', id: 28 },
]

export const MUSCLE_LIST_TYPE_FIELD_OPTIONS = [
  { label: 'Peitoral Maior', value: 'CHEST_PECTORAL_MAJOR', id: 1001 },
  { label: 'Peitoral Menor', value: 'CHEST_PECTORAL_MINOR', id: 1002 },
  { label: 'Latíssimo do Dorso', value: 'BACK_LATISSIMUS_DORSI', id: 2001 },
  { label: 'Trapézio', value: 'BACK_TRAPEZIUS', id: 2002 },
  { label: 'Romboides', value: 'BACK_RHOMBOIDS', id: 2003 },
  { label: 'Serrátil', value: 'BACK_SERRATUS', id: 2004 },
  { label: 'Quadríceps', value: 'LEGS_QUADRICEPS', id: 3001 },
  { label: 'Isquiotibiais', value: 'LEGS_HAMSTRINGS', id: 3002 },
  { label: 'Adutores', value: 'LEGS_ADDUCTORS', id: 3003 },
  { label: 'Abdutores', value: 'LEGS_ABDUCTORS', id: 3004 },
  { label: 'Sartório', value: 'LEGS_SARTORIUS', id: 3005 },
  { label: 'Grácil', value: 'LEGS_GRACILIS', id: 3006 },
  { label: 'Psoas Maior', value: 'LEGS_PSOAS_MAJOR', id: 3007 },
  { label: 'Deltoide', value: 'SHOULDERS_DELTOID', id: 4001 },
  { label: 'Manguito Rotador', value: 'SHOULDERS_ROTATOR_CUFF', id: 4002 },
  { label: 'Supraespinhal', value: 'SHOULDERS_SUPRASPINATUS', id: 4003 },
  { label: 'Infraespinhal', value: 'SHOULDERS_INFRASPINATUS', id: 4004 },
  { label: 'Redondo Menor', value: 'SHOULDERS_TERES_MINOR', id: 4005 },
  { label: 'Subescapular', value: 'SHOULDERS_SUBSCAPULARIS', id: 4006 },
  { label: 'Bíceps Braquial', value: 'ARMS_BICEPS_BRACHII', id: 5001 },
  { label: 'Tríceps Braquial', value: 'ARMS_TRICEPS_BRACHII', id: 5002 },
  { label: 'Braquial', value: 'ARMS_BRACHIALIS', id: 5003 },
  { label: 'Coracobraquial', value: 'ARMS_CORACOBRACHIALIS', id: 5004 },
  { label: 'Ancôneo', value: 'ARMS_ANCONEUS', id: 5005 },
  { label: 'Reto Abdominal', value: 'ABDOMEN_RECTUS_ABDOMINIS', id: 6001 },
  { label: 'Oblíquos', value: 'ABDOMEN_OBLIQUES', id: 6002 },
  {
    label: 'Transverso do Abdômen',
    value: 'ABDOMEN_TRANSVERSUS_ABDOMINIS',
    id: 6003,
  },
  { label: 'Diafragma', value: 'ABDOMEN_DIAPHRAGM', id: 6004 },
  { label: 'Intercostais', value: 'ABDOMEN_INTERCOSTALS', id: 6005 },
  { label: 'Glúteo Máximo', value: 'GLUTES_GLUTEUS_MAXIMUS', id: 7001 },
  { label: 'Glúteo Médio', value: 'GLUTES_GLUTEUS_MEDIUS', id: 7002 },
  { label: 'Glúteo Mínimo', value: 'GLUTES_GLUTEUS_MINIMUS', id: 7003 },
  { label: 'Gastrocnêmio', value: 'CALVES_GASTROCNEMIUS', id: 8001 },
  { label: 'Sóleo', value: 'CALVES_SOLEUS', id: 8002 },
  { label: 'Tibiais', value: 'CALVES_TIBIALS', id: 8003 },
  { label: 'Flexores do Punho', value: 'FOREARMS_WRIST_FLEXORS', id: 9001 },
  { label: 'Extensores do Punho', value: 'FOREARMS_WRIST_EXTENSORS', id: 9002 },
  { label: 'Pronadores', value: 'FOREARMS_PRONATORS', id: 9003 },
  { label: 'Supinadores', value: 'FOREARMS_SUPINATORS', id: 9004 },
  {
    label: 'Flexor Superficial e Longo dos Dedos',
    value: 'FOREARMS_FLEXOR_DIGITORUM',
    id: 9005,
  },
  {
    label: 'Esternocleidomastóideo',
    value: 'NECK_STERNOCLEIDOMASTOID',
    id: 10001,
  },
  { label: 'Escalenos', value: 'NECK_SCALENES', id: 10002 },
  { label: 'Esplênios', value: 'NECK_SPLENIUS', id: 10003 },
  { label: 'Iliopsoas', value: 'HIP_ILIOPSOAS', id: 11001 },
  { label: 'Adutores do Quadril', value: 'HIP_ADDUCTORS', id: 11002 },
  { label: 'Abdutores do Quadril', value: 'HIP_ABDUCTORS', id: 11003 },
]

export const MUSCLE_GROUP_LIST_TYPE_FIELD_OPTIONS = [
  { label: 'Grupo Muscular do Peito', value: 'CHEST_MUSCLE_GROUP', id: 1000 },
  { label: 'Grupo Muscular das Costas', value: 'BACK_MUSCLE_GROUP', id: 2000 },
  { label: 'Grupo Muscular das Pernas', value: 'LEGS_MUSCLE_GROUP', id: 3000 },
  {
    label: 'Grupo Muscular do Ombro',
    value: 'SHOULDERS_MUSCLE_GROUP',
    id: 4000,
  },
  { label: 'Grupo Muscular do Braço', value: 'ARMS_MUSCLE_GROUP', id: 5000 },
  {
    label: 'Grupo Muscular do Abdômen',
    value: 'ABDOMEN_MUSCLE_GROUP',
    id: 6000,
  },
  {
    label: 'Grupo Muscular dos Glúteos',
    value: 'GLUTES_MUSCLE_GROUP',
    id: 7000,
  },
  {
    label: 'Grupo Muscular do Antebraço',
    value: 'FOREARMS_MUSCLE_GROUP',
    id: 9000,
  },
  { label: 'Grupo Muscular do Pescoço', value: 'NECK_MUSCLE_GROUP', id: 10000 },
  { label: 'Grupo Muscular do Quadril', value: 'HIP_MUSCLE_GROUP', id: 11000 },
]

export const PERIODICITY_FILTERS = [
  { param: 'time_interval', value: 'monthly', label: 'Mensal' },
  { param: 'time_interval', value: 'quarterly', label: 'Trimestral' },
  { param: 'time_interval', value: 'semiannual', label: 'Semestral' },
  { param: 'time_interval', value: 'yearly', label: 'Anual' },
]

export const SUBSCRIPTION_FILTERS = [
  { param: 'status', value: 'active', label: 'Ativo' },
  { param: 'status', value: 'inactive', label: 'Desativado' },
  { param: 'status', value: 'invited', label: 'Convidado' },
  { param: 'payment_status', value: '0', label: 'Pendente' },
  { param: 'payment_status', value: '1', label: 'A Vencer' },
  { param: 'payment_status', value: '2', label: 'Pago' },
  { param: 'payment_status', value: '3', label: 'Cancelado' },
]

export const EQUIPMENTS_FILTERS = [
  { param: 'equipment', value: 'BWT', label: 'Peso do corpo' },
  { param: 'equipment', value: 'FWT', label: 'Peso livre' },
  { param: 'equipment', value: 'FTN', label: 'Treinamento de funcional' },
  { param: 'equipment', value: 'MAC', label: 'Máquinas' },
  { param: 'equipment', value: 'OTH', label: 'Outros' },
]

export const BODY_PART_FILTERS = [
  { param: 'muscle', value: '4000', label: 'Ombro' },
  { param: 'muscle', value: '1000', label: 'Peitoral' },
  { param: 'muscle', value: '2000', label: 'Costas' },
  { param: 'muscle', value: '5000', label: 'Braço' },
  { param: 'muscle', value: '9000', label: 'Antebraço' },
  { param: 'muscle', value: '6000', label: 'Abdômen' },
  { param: 'muscle', value: '10000', label: 'Pescoço' },
  {
    param: 'muscle',
    value: '4000,1000,2000,5000,9000,6000,10000',
    label: 'Superior completo',
  },
  { param: 'muscle', value: '8000', label: 'Panturrilha' },
  { param: 'muscle', value: '7000', label: 'Glúteos' },
  { param: 'muscle', value: '3000', label: 'Coxa' },
  { param: 'muscle', value: '8000,7000,3000', label: 'Inferior completo' },
]

export const EXERCISE_DIFFICULTY_FILTERS = [
  { param: 'difficulty', label: 'Iniciante', value: 'EAS' },
  { param: 'difficulty', label: 'Intermediário', value: 'INT' },
  { param: 'difficulty', label: 'Avançado', value: 'ADV' },
]

export const LIBRARY_FILTERS = [
  { param: 'library', label: 'Biblioteca', value: 'true' },
  { param: 'library', label: 'Todos', value: '' },
]

export const EXERCISES_FILTERS = [
  ...BODY_PART_FILTERS,
  ...EXERCISE_DIFFICULTY_FILTERS,
  ...LIBRARY_FILTERS,
]

export const stateObjectList = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
]

export const THEME_CUSTOM_COLORS = [
  'action',
  'background',
  'badge',
  'border',
  'focusedDefault',
  'icon',
  'interactive',
  'surface',
  'tag',
  'text',
]

export const THEME_CUSTOM_FONT_SIZES = [
  'copy1',
  'copy2',
  'copy3',
  'copy4',
  'copy5',
  'h1',
  'h2',
  'h3',
  'sh1',
  'sh2',
  'sh3',
  'small',
]

export const timeObjectList = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
  '23:59',
]

export const FIREBASE_ERRORS = {
  'auth/app-deleted': 'O banco de dados não foi localizado.',
  'auth/expired-action-code': 'O código da ação o ou link expirou.',
  'auth/invalid-action-code':
    'O código da ação é inválido. Isso pode acontecer se o código estiver malformado ou já tiver sido usado.',
  'auth/user-disabled':
    'O usuário correspondente à credencial fornecida foi desativado.',
  'auth/user-not-found': 'O usuário não correponde à nenhuma credencial.',
  'auth/weak-password': 'A senha é muito fraca.',
  'auth/email-already-in-use':
    'Já existi uma conta com o endereço de email fornecido.',
  'auth/invalid-email': 'O endereço de e-mail não é válido.',
  'auth/operation-not-allowed':
    'O tipo de conta correspondente à esta credencial, ainda não encontra-se ativada.',
  'auth/account-exists-with-different-credential':
    'E-mail já associado a outra conta.',
  'auth/auth-domain-config-required':
    'A configuração para autenticação não foi fornecida.',
  'auth/credential-already-in-use': 'Já existe uma conta para esta credencial.',
  'auth/operation-not-supported-in-this-environment':
    'Esta operação não é suportada no ambiente que está sendo executada. Verifique se deve ser http ou https.',
  'auth/timeout':
    'Excedido o tempo de resposta. O domínio pode não estar autorizado para realizar operações.',
  'auth/missing-android-pkg-name':
    'Deve ser fornecido um nome de pacote para instalação do aplicativo Android.',
  'auth/missing-continue-uri':
    'A próxima URL deve ser fornecida na solicitação.',
  'auth/missing-ios-bundle-id':
    'Deve ser fornecido um nome de pacote para instalação do aplicativo iOS.',
  'auth/invalid-continue-uri':
    'A próxima URL fornecida na solicitação é inválida.',
  'auth/unauthorized-continue-uri':
    'O domínio da próxima URL não está na lista de autorizações.',
  'auth/invalid-dynamic-link-domain':
    'O domínio de link dinâmico fornecido, não está autorizado ou configurado no projeto atual.',
  'auth/argument-error': 'Verifique a configuração de link para o aplicativo.',
  'auth/invalid-persistence-type':
    'O tipo especificado para a persistência dos dados é inválido.',
  'auth/unsupported-persistence-type':
    'O ambiente atual não suportar o tipo especificado para persistência dos dados.',
  'auth/invalid-credential': 'A credencial expirou ou está mal formada.',
  'auth/wrong-password': 'Senha incorreta.',
  'auth/invalid-verification-code':
    'O código de verificação da credencial não é válido.',
  'auth/invalid-verification-id':
    'O ID de verificação da credencial não é válido.',
  'auth/custom-token-mismatch': 'O token está diferente do padrão solicitado.',
  'auth/invalid-custom-token': 'O token fornecido não é válido.',
  'auth/captcha-check-failed':
    'O token de resposta do reCAPTCHA não é válido, expirou ou o domínio não é permitido.',
  'auth/invalid-phone-number':
    'O número de telefone está em um formato inválido (padrão E.164).',
  'auth/missing-phone-number': 'O número de telefone é requerido.',
  'auth/quota-exceeded': 'A cota de SMS foi excedida.',
  'auth/cancelled-popup-request':
    'Somente uma solicitação de janela pop-up é permitida de uma só vez.',
  'auth/popup-blocked': 'A janela pop-up foi bloqueado pelo navegador.',
  'auth/popup-closed-by-user':
    'A janela pop-up foi fechada pelo usuário sem concluir o login no provedor.',
  'auth/unauthorized-domain':
    'O domínio do aplicativo não está autorizado para realizar operações.',
  'auth/invalid-user-token': 'O usuário atual não foi identificado.',
  'auth/user-token-expired': 'O token do usuário atual expirou.',
  'auth/null-user': 'O usuário atual é nulo.',
  'auth/app-not-authorized':
    'Aplicação não autorizada para autenticar com a chave informada.',
  'auth/invalid-api-key': 'A chave da API fornecida é inválida.',
  'auth/network-request-failed': 'Falha de conexão com a rede.',
  'auth/requires-recent-login':
    'O último horário de acesso do usuário não atende ao limite de segurança.',
  'auth/too-many-requests':
    'As solicitações foram bloqueadas devido a atividades incomuns. Tente novamente depois que algum tempo.',
  'auth/web-storage-unsupported':
    'O navegador não suporta armazenamento ou se o usuário desativou este recurso.',
  'auth/invalid-claims':
    'Os atributos de cadastro personalizado são inválidos.',
  'auth/claims-too-large':
    'O tamanho da requisição excede o tamanho máximo permitido de 1 Megabyte.',
  'auth/id-token-expired': 'O token informado expirou.',
  'auth/id-token-revoked': 'O token informado perdeu a validade.',
  'auth/invalid-argument': 'Um argumento inválido foi fornecido a um método.',
  'auth/invalid-creation-time':
    'O horário da criação precisa ser uma data UTC válida.',
  'auth/invalid-disabled-field':
    'A propriedade para usuário desabilitado é inválida.',
  'auth/invalid-display-name': 'O nome do usuário é inválido.',
  'auth/invalid-email-verified': 'O e-mail é inválido.',
  'auth/invalid-hash-algorithm':
    'O algoritmo de HASH não é uma criptografia compatível.',
  'auth/invalid-hash-block-size': 'O tamanho do bloco de HASH não é válido.',
  'auth/invalid-hash-derived-key-length':
    'O tamanho da chave derivada do HASH não é válido.',
  'auth/invalid-hash-key':
    'A chave de HASH precisa ter um buffer de byte válido.',
  'auth/invalid-hash-memory-cost': 'O custo da memória HASH não é válido.',
  'auth/invalid-hash-parallelization':
    'O carregamento em paralelo do HASH não é válido.',
  'auth/invalid-hash-rounds': 'O arredondamento de HASH não é válido.',
  'auth/invalid-hash-salt-separator':
    'O campo do separador de SALT do algoritmo de geração de HASH precisa ser um buffer de byte válido.',
  'auth/invalid-id-token': 'O código do token informado não é válido.',
  'auth/invalid-last-sign-in-time':
    'O último horário de login precisa ser uma data UTC válida.',
  'auth/invalid-page-token':
    'A próxima URL fornecida na solicitação é inválida.',
  'auth/invalid-password':
    'A senha é inválida, precisa ter pelo menos 6 caracteres.',
  'auth/invalid-password-hash': 'O HASH da senha não é válida.',
  'auth/invalid-password-salt': 'O SALT da senha não é válido.',
  'auth/invalid-photo-url': 'A URL da foto de usuário é inválido.',
  'auth/invalid-provider-id': 'O identificador de provedor não é compatível.',
  'auth/invalid-session-cookie-duration':
    'A duração do COOKIE da sessão precisa ser um número válido em milissegundos, entre 5 minutos e 2 semanas.',
  'auth/invalid-uid':
    'O identificador fornecido deve ter no máximo 128 caracteres.',
  'auth/invalid-user-import':
    'O registro do usuário a ser importado não é válido.',
  'auth/invalid-provider-data': 'O provedor de dados não é válido.',
  'auth/maximum-user-count-exceeded':
    'O número máximo permitido de usuários a serem importados foi excedido.',
  'auth/missing-hash-algorithm':
    'É necessário fornecer o algoritmo de geração de HASH e seus parâmetros para importar usuários.',
  'auth/missing-uid': 'Um identificador é necessário para a operação atual.',
  'auth/reserved-claims':
    'Uma ou mais propriedades personalizadas fornecidas usaram palavras reservadas.',
  'auth/session-cookie-revoked': 'O COOKIE da sessão perdeu a validade.',
  'auth/uid-alread-exists': 'O indentificador fornecido já está em uso.',
  'auth/email-already-exists': 'O e-mail fornecido já está em uso.',
  'auth/phone-number-already-exists': 'O telefone fornecido já está em uso.',
  'auth/project-not-found': 'Nenhum projeto foi encontrado.',
  'auth/insufficient-permission':
    'A credencial utilizada não tem permissão para acessar o recurso solicitado.',
  'auth/internal-error':
    'O servidor de autenticação encontrou um erro inesperado ao tentar processar a solicitação.',
}

export const MUSCLE_CHOICES = {
  '0000': 'Sistema Cardiovascular',
  1000: 'Grupo Muscular do Peito',
  1001: 'Peitoral Maior',
  1002: 'Peitoral Menor',
  1003: 'Serrátil Anterior',
  2000: 'Grupo Muscular das Costas',
  2001: 'Latíssimo do Dorso',
  2002: 'Trapézio',
  2003: 'Romboides',
  2004: 'Serrátil',
  3000: 'Grupo Muscular das Pernas',
  3001: 'Quadríceps',
  3002: 'Isquiotibiais',
  3003: 'Adutores',
  3004: 'Abdutores',
  3005: 'Sartório',
  3006: 'Grácil',
  3007: 'Psoas Maior',
  3008: 'Psoas Menor',
  3009: 'Músculo Ilíaco',
  3010: 'Vasto Lateral',
  3011: 'Vasto Medial',
  3012: 'Vasto Intermédio',
  3013: 'Reto Femoral',
  3014: 'Bíceps Femoral',
  3015: 'Semitendíneo',
  3016: 'Semimembranoso',
  4000: 'Grupo Muscular do Ombro',
  4001: 'Deltoide',
  4002: 'Manguito Rotador',
  4003: 'Supraespinhal',
  4004: 'Infraespinhal',
  4005: 'Redondo Menor',
  4006: 'Subescapular',
  5000: 'Grupo Muscular do Braço',
  5001: 'Bíceps Braquial',
  5002: 'Tríceps Braquial',
  5003: 'Braquial',
  5004: 'Coracobraquial',
  5005: 'Ancôneo',
  6000: 'Grupo Muscular do Abdômen',
  6001: 'Reto Abdominal',
  6002: 'Oblíquos',
  6003: 'Transverso do Abdômen',
  6004: 'Diafragma',
  6005: 'Intercostais',
  6006: 'Quadrado Lombar',
  6007: 'Piramidal',
  6008: 'Músculo Ilíaco',
  6009: 'Psoas Maior',
  6010: 'Psoas Menor',
  6011: 'Linha Alba',
  7000: 'Grupo Muscular dos Glúteos',
  7001: 'Glúteo Máximo',
  7002: 'Glúteo Médio',
  7003: 'Glúteo Mínimo',
  7004: 'Piriforme',
  7005: 'Tensor da Fáscia Lata',
  7006: 'Obturador Externo',
  7007: 'Obturador Interno',
  7008: 'Quadrado Femoral',
  7009: 'Gêmeo Superior',
  7010: 'Gêmeo Inferior',
  8000: 'Grupo Muscular das Panturrilhas',
  8001: 'Gastrocnêmio',
  8002: 'Sóleo',
  8003: 'Plantar',
  8004: 'Tibial Anterior',
  8005: 'Tibial Posterior',
  8006: 'Fibular Longo',
  8007: 'Fibular Curto',
  8008: 'Flexor Longo dos Dedos',
  8009: 'Extensor Longo dos Dedos',
  9000: 'Grupo Muscular do Antebraço',
  9001: 'Flexores do Punho',
  9002: 'Extensores do Punho',
  9003: 'Pronadores',
  9004: 'Supinadores',
  9005: 'Flexor Superficial e Longo dos Dedos',
  9006: 'Braquiorradial',
  9007: 'Extensor Comum dos Dedos',
  9008: 'Extensor Ulnar do Carpo',
  9009: 'Flexor Radial do Carpo',
  9010: 'Flexor Ulnar do Carpo',
  9011: 'Abdutor Longo do Polegar',
  9012: 'Extensor Longo do Polegar',
  9013: 'Flexor Longo do Polegar',
  9014: 'Pronador Redondo',
  9015: 'Pronador Quadrado',
  10000: 'Grupo Muscular do Pescoço',
  10001: 'Esternocleidomastóideo',
  10002: 'Escalenos',
  10003: 'Esplênios',
  10004: 'Levantador da escápula',
  11000: 'Grupo Muscular do Quadril',
  11001: 'Iliopsoas',
  11002: 'Adutores do Quadril',
  11003: 'Abdutores do Quadril',
}
