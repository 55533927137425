// Libraries
import styled from 'styled-components'

export const LoadContainer = styled.div`
  width: calc(100% - 4.8rem);
  background: ${({ theme }) => theme.colors.surface.default};
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 1.5rem;
  margin: 0px 2.4rem;
  padding: 1.6rem 0;
  display: flex;
  align-items: center;
  gap: 1.6rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns:
      minmax(19.9rem, 1.7fr) minmax(12rem, 1fr) minmax(5.5rem, 1fr)
      minmax(12.4rem, 1fr) minmax(9rem, 1fr) minmax(9rem, 0.5fr);
    gap: 2.4rem;
    margin: 0;
    padding: 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    :not(:last-of-type) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.border.input};
    }
  }
`
