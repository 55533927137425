// Models
import { IAthleteInfoState } from 'storage/athleteInfo/models'
import { IWorkoutRoutineState } from 'storage/workoutRoutine/models'
import { IWorkoutModelState } from 'storage/workoutModel/models'
import { IWorkoutLog } from 'services/workoutModel/@types'
import IStore from 'lib/redux/models'

// React
import { FC, useContext, useEffect, useLayoutEffect, useState } from 'react'

// Libs
import { ThemeContext } from 'styled-components'
import { useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { cn } from 'utils/helpers/classess'
import { success, triggerLoadWorkoutRoutine } from 'storage/workoutRoutine/duck'
import { triggerLoadAthlete } from 'storage/athleteInfo/duck'
import {
  triggerGetWorkoutsLog,
  triggerLoadMoreWorkoutsLog,
  cleanup,
} from 'storage/workoutModel/duck'
import { useMediaQuery, useMenu, useModal } from 'hooks'

// Components
import { Avatar, Button, CommonList, Icon, Loading, Tooltip } from 'heeds-ds'
import { Modal, WorkoutReportItem, WorkoutReportItemLoader } from 'components'

// Constants
const page_size = 20

const headerGridTemplate = 'grid-cols-[2.3fr_2.5fr_2.5fr_1.8fr_1.8fr_0.8fr]'

const TABLE_PADDING = 'py-4 px-6'

const AthleteWorkoutsReport: FC = () => {
  const { id = '', model_id = '' } = useParams()
  const { workoutRoutine, loading } = useSelector<IStore, IWorkoutRoutineState>(
    (state) => state.workoutRoutine,
  )
  const { workoutLog, loading: workoutLogLoading } = useSelector<
    IStore,
    IWorkoutModelState
  >((state) => state.workoutModel)
  const { info, loading: infoAthleteLoading } = useSelector<
    IStore,
    IAthleteInfoState
  >((state) => state.athleteInfo)
  const { setPagename } = useMenu()
  const { openModal, closeModal, isVisible } = useModal()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const [selectedComment, setSelectedComment] = useState('')

  const columns = [
    { label: 'Treino' },
    { label: 'Data' },
    { label: 'Horário' },
    { label: 'Tempo de execução' },
    {
      label: (
        <div className="flex items-center gap-1">
          <Tooltip content="Percepção Subjetiva de Esforço" delay={0}>
            <button>
              <Icon
                iconName="info"
                size={16}
                color={theme.colors.text.subdued}
              />
            </button>
          </Tooltip>
          PSE
        </div>
      ),
    },
    { label: '' },
  ]

  const handleEndReached = () => {
    if (model_id && !loading && workoutLog?.next) {
      const query = {
        workout_routine: Number(model_id),
        page: workoutLog?.next,
        page_size,
      }
      dispatch(triggerLoadMoreWorkoutsLog(query))
    }
  }

  const renderLogItems = (item: IWorkoutLog) => {
    return (
      <WorkoutReportItem
        item={item}
        gridTemplate={headerGridTemplate}
        padding={TABLE_PADDING}
        onClickComment={(comment: string) => handleCommentModal(comment)}
      />
    )
  }

  const handleCommentModal = (comment: string) => {
    setSelectedComment(comment)
    openModal('athlete-comment')
  }

  useLayoutEffect(() => {
    if (id) {
      dispatch(triggerLoadAthlete({ id: Number(id) }))
    }
  }, [dispatch, id])

  useLayoutEffect(() => {
    if (model_id) {
      dispatch(triggerLoadWorkoutRoutine({ routine_pk: Number(model_id) }))
      dispatch(triggerGetWorkoutsLog({ workout_routine: Number(model_id) }))
    }
  }, [dispatch, model_id])

  useLayoutEffect(() => {
    setPagename('RELATÓRIOS')

    return () => {
      setPagename('DASHBOARD')
      dispatch(success({ workoutRoutines: undefined }))
    }
  }, [dispatch, setPagename])

  useEffect(() => {
    return () => {
      dispatch(cleanup())
    }
  }, [dispatch])

  if (
    infoAthleteLoading ||
    loading ||
    (workoutLog === undefined &&
      (workoutLogLoading === undefined || workoutLogLoading === true))
  ) {
    return <Loading active={true} />
  }

  return (
    <div className="flex h-screen flex-col gap-6 overflow-hidden md:px-10 md:pb-6 md:pt-8">
      <div className="mx-6 flex gap-4 rounded-2 border border-border-input bg-surface p-4 md:mx-0 md:justify-between md:gap-0">
        <div className="hidden md:flex">
          <Button
            size="xsmall"
            variation="borderless"
            onClick={() => navigate(-1)}
            track={buttonClickTracking}
            trackName="navigate_to_athletes"
          >
            <Icon
              iconName="chevronLeft"
              color={theme.colors.interactive.default}
            />
            Voltar para alunos
          </Button>
        </div>
        <div className="flex md:hidden">
          <Avatar name={info?.name || ''} scale="small" />
        </div>
        <div className="flex flex-col justify-center gap-1 md:items-center">
          <p className="text-copy2 font-medium md:text-copy3">{info?.name}</p>
          <p className="text-copy3 font-normal text-text-subdued">
            <p className="hidden md:flex">{workoutRoutine?.name}</p>
            <p className="flex md:hidden">Relatório da Rotina - Treinos</p>
          </p>
        </div>
        <div className="hidden w-[250px] md:flex" />
      </div>
      <div
        className={cn(
          'flex w-full flex-col gap-4 overflow-y-auto rounded-2 border px-6 pt-4',
          'h-full border-border-input bg-surface md:gap-0 md:px-0 md:pt-0',
        )}
      >
        <CommonList<IWorkoutLog>
          columnsFormat={headerGridTemplate}
          columns={columns}
          data={workoutLog?.results || []}
          onEndReached={handleEndReached}
          loaderComponent={
            <WorkoutReportItemLoader
              gridTemplate={headerGridTemplate}
              padding={TABLE_PADDING}
            />
          }
          refreshing={
            workoutLog === undefined &&
            (workoutLogLoading === undefined || workoutLogLoading === true)
          }
          padding={TABLE_PADDING}
          showHeader={isDesktop}
          renderItem={renderLogItems}
          hasMore={!!workoutLog?.next}
          listEmptyComponent={
            <div className="flex h-4/5 items-center justify-center">
              <p className="text-center text-copy4 text-text-subdued">
                Não há relatórios disponíveis para este aluno no momento.
              </p>
            </div>
          }
        />
      </div>
      {isVisible === 'athlete-comment' && (
        <Modal
          title="Comentário do aluno"
          primaryButton={{ name: 'Entendi', onClick: () => closeModal() }}
        >
          <div className="m-6 items-center overflow-scroll break-words rounded-1 border border-b-border-input p-4 md:w-[600px]">
            <p className="h-80 text-copy4">{selectedComment}</p>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default AthleteWorkoutsReport
