// Models
import { EWorkoutDifficulty, EWorkoutGoal } from 'models'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'
import { TMenuDropdownOption } from 'heeds-ds/src/models'

// React
import { FC } from 'react'

// Misc
import { dateFormatEnglishToPortuguese } from 'utils/functions'

// Components
import { DropdownMenu, IconButton } from 'heeds-ds'

export type Props = {
  routineTemplate: IWorkoutRoutine
  onEdit: (modelId: number) => void
  onRemove: () => void
  onShare: () => void
  onDuplicated?: (routine: IWorkoutRoutine) => void
}

const Mobile: FC<Props> = ({
  onEdit,
  onRemove,
  onShare,
  onDuplicated,
  routineTemplate,
}) => {
  const { name, personal_profile, id, start_date, goal, difficulty } =
    routineTemplate

  const options: TMenuDropdownOption[] = [
    {
      icon: 'edit',
      label: 'Editar',
      onClick: () => onEdit(id),
    },
    {
      icon: 'share',
      label: 'Compartilhar',
      onClick: onShare,
    },
    {
      color: 'critical',
      icon: 'delete',
      label: 'Excluir',
      onClick: onRemove,
    },
  ]

  const systemOptions: TMenuDropdownOption[] = [
    {
      icon: 'contentCopy',
      label: 'Duplicar',
      onClick: () => onDuplicated?.(routineTemplate),
    },
    {
      icon: 'share',
      label: 'Compartilhar',
      onClick: onShare,
    },
  ]

  return (
    <div className="mx-6 my-2 flex rounded-2 border border-border-input p-4">
      <div className="w-4/6 flex-1">
        <p className="mb-2 text-copy3 font-semibold text-text-secondary xl:truncate">
          {name}
        </p>
        <p className="mb-1 text-copy3 font-normal text-text-subdued">
          {personal_profile === 0 ? 'CADU (sistema)' : 'Você'}
        </p>
        <p className="mb-1 text-copy3 font-normal text-text-subdued">
          {dateFormatEnglishToPortuguese(start_date)}
        </p>
        <p className="mb-1 text-copy3 font-normal text-text-subdued">
          {EWorkoutGoal[goal]}
        </p>
        <p className="mb-1 text-copy3 font-normal text-text-subdued">
          {EWorkoutDifficulty[difficulty]}
        </p>
      </div>
      <div>
        <DropdownMenu items={personal_profile === 0 ? systemOptions : options}>
          <IconButton
            size="small"
            iconName="moreVertical"
            trackName="open_anamnese_model_options"
          />
        </DropdownMenu>
      </div>
    </div>
  )
}

export default Mobile
