// Models
import { FieldType } from 'components/WorkoutSetFormTag'
import { IExerciseData } from 'storage/exercise/models'
import {
  IFormInputs,
  IModelInput,
  IWorkoutSetInput,
  TFormValues,
  TExercisesGroupFilter,
  TSetSpecialSet,
  TSpecialSetInput,
} from 'models'
import { TSetStateModels } from 'hooks/useWorkoutModelsForm/@types'

// React
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

// Libraries
import { UseFormReturn } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// Misc
import { buttonClickTracking } from 'utils/tracking'
import { triggerToastError } from 'storage/general/duck'
import { useModal } from 'hooks'

// Components
import * as Blocks from 'blocks/dashboard/workout/routine'
import * as Styled from './styled'
import { ModalExerciseImage } from 'components/modals'

// Constants
const TOAST_INFO = {
  bothErrors: {
    customTitle: 'Faltam informações no treino',
    message:
      'Série é um campo obrigatório. Evite treinos vazios, adicione exercícios para revisar.',
    time: 10000,
  },
  emptyWorkout: {
    customTitle: 'Faltam informações no treino',
    message: 'Evite treinos vazios, adicione exercícios para revisar.',
  },
  fieldRequired: {
    customTitle: 'Série é um campo obrigatório!',
    message:
      'Lembre-se de adicionar essa informação no exercício para o seu aluno.',
  },
}

type Props = {
  addOrRemoveFieldFromWorkoutSetToExercise: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
    field: FieldType,
    add?: boolean,
  ) => void
  addExerciseToWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exercise: IExerciseData,
  ) => void
  addWorkoutModel: (model: IModelInput) => void
  addWorkoutSet: (modelId: string, set: IWorkoutSetInput) => void
  duplicateWorkoutModel: () => void
  filters: TExercisesGroupFilter[]
  inputValue: string
  loadMoreExercises: () => void
  methods: UseFormReturn<IFormInputs, TFormValues>
  onChangeFilters: (filters: TExercisesGroupFilter[]) => void
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  removeFilter: (filter: TExercisesGroupFilter) => void
  removeExerciseFromWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    exerciseIndex: number,
  ) => void
  removeWorkoutSet: (modelId: string, workoutSetId: string) => void
  replicateWorkoutSetFields: (modelId: string, set: IWorkoutSetInput) => void
  selectedModelIndex: number
  setSpecialSet: TSetSpecialSet
  setStateModels: TSetStateModels
  specialSet?: TSpecialSetInput
  stateModels: IModelInput[]
  updateWorkoutModel: (modelId: string, updatedModel: IModelInput) => void
  updateWorkoutSet: (
    modelId: string,
    workoutSetId: string,
    updatedWorkoutSet: IWorkoutSetInput,
  ) => void
}

const Desktop: FC<Props> = ({
  addExerciseToWorkoutSet,
  addOrRemoveFieldFromWorkoutSetToExercise,
  addWorkoutModel,
  addWorkoutSet,
  duplicateWorkoutModel,
  filters,
  inputValue,
  loadMoreExercises,
  methods,
  onChangeFilters,
  onSearchChange,
  removeExerciseFromWorkoutSet,
  removeFilter,
  removeWorkoutSet,
  replicateWorkoutSetFields,
  setSpecialSet,
  updateWorkoutSet,
  ...commonProps
}) => {
  const { specialSet, stateModels } = commonProps
  const { closeModal, isVisible, openModal } = useModal()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [selectedExercise, setSelectedExercise] = useState<IExerciseData>()

  const {
    formState: { errors, isValid },
  } = methods

  const hasEmptyModels = useMemo(
    () =>
      stateModels.some((model) =>
        Boolean(Object.values(model.workout_set ?? {}).length === 0),
      ),
    [stateModels],
  )

  const isDisabled = specialSet !== undefined

  const toastType = useMemo(() => {
    let emptyErrors = 0
    let fieldErrors = 0

    if (errors?.models?.type === 'someExercise') return 'someExercise'

    errors.models?.forEach?.((fieldModel) => {
      if (fieldModel?.name) return (emptyErrors += 1)
      return (fieldErrors += 1)
    })

    if (fieldErrors && emptyErrors) return 'bothErrors'

    if (emptyErrors) return 'emptyWorkout'

    return 'fieldRequired'
  }, [errors?.models])

  const showToast = useCallback(() => {
    if (!isValid && toastType !== 'someExercise') {
      dispatch(triggerToastError({ ...TOAST_INFO[toastType] }))
    }
  }, [dispatch, isValid, toastType])

  const goBack = () => navigate(-1)

  const openExerciseGifModal = (exercise: IExerciseData) => {
    setSelectedExercise(exercise)
    openModal('desktop-exercise-gif-modal')
  }

  const closeExerciseGifModal = () => {
    setSelectedExercise(undefined)
    closeModal()
  }

  const emptyFunction = () => undefined

  useEffect(() => {
    if (Object.keys(errors).length) showToast()
  }, [errors, showToast])

  return (
    <Styled.Container>
      <Styled.Content>
        <Blocks.WorkoutExercises
          {...commonProps}
          addExerciseToWorkoutSet={addExerciseToWorkoutSet}
          addWorkoutSet={addWorkoutSet}
          filters={filters}
          inputValue={inputValue}
          loadMoreExercises={loadMoreExercises}
          onChangeFilters={onChangeFilters}
          onClickFilter={() => openModal('exercises-filter-modal')}
          onSearchChange={onSearchChange}
          openExerciseGifModal={openExerciseGifModal}
          removeFilter={removeFilter}
          stateModels={stateModels}
        />

        <Blocks.WorkoutModels
          {...commonProps}
          addOrRemoveFieldFromWorkoutSetToExercise={
            addOrRemoveFieldFromWorkoutSetToExercise
          }
          addWorkoutModel={addWorkoutModel}
          addWorkoutSet={addWorkoutSet}
          duplicateWorkoutModel={duplicateWorkoutModel}
          headerVisible={false}
          methods={methods}
          openExerciseGifModal={openExerciseGifModal}
          removeExerciseFromWorkoutSet={removeExerciseFromWorkoutSet}
          removeWorkoutModel={emptyFunction}
          removeWorkoutSet={removeWorkoutSet}
          renameWorkoutModel={emptyFunction}
          replicateWorkoutSetFields={replicateWorkoutSetFields}
          selectWorkoutModel={emptyFunction}
          setSpecialSet={setSpecialSet}
          stateModels={stateModels}
          updateWorkoutSet={updateWorkoutSet}
        />
      </Styled.Content>

      <Styled.ButtonContainer>
        <Styled.CancelButton
          disabled={isDisabled}
          onClick={goBack}
          track={buttonClickTracking}
          trackName="navigate_to_athlete_routines"
        >
          Cancelar
        </Styled.CancelButton>

        <Styled.NextButton
          disabled={isDisabled || !isValid}
          form="sets-form"
          onClick={() =>
            openModal(hasEmptyModels ? 'empty-model' : 'go-to-review-modal')
          }
          track={buttonClickTracking}
          trackName="update_state_and_navigate_to_workout_review"
        >
          Ir para revisão
        </Styled.NextButton>
      </Styled.ButtonContainer>

      {isVisible === 'desktop-exercise-gif-modal' && (
        <ModalExerciseImage
          exercise={selectedExercise}
          handleClose={closeExerciseGifModal}
        />
      )}
    </Styled.Container>
  )
}

export default Desktop
