// React
import { useContext, useEffect, useMemo, useRef, useState } from 'react'

// Libraries
import * as Popover from '@radix-ui/react-popover'
import { ThemeContext } from 'styled-components'
import { useController, useFormContext } from 'react-hook-form'
import MaskedInput, { conformToMask } from 'react-text-mask'

// Misc
import { cn } from '../../../utils/classes'
import { formatToDateObject } from '../../../utils/helpers/date'
import { inputContainerStyles, inputStyles } from './styles'
import { maskCollection } from '../../../utils/mask'

// Components
import { Calendar, Icon, InputWrapper } from '../..'

enum EIconSizes {
  small = 20,
  medium = 21.6,
  large = 25,
}

enum EFieldStates {
  default = 'default',
  error = 'critical',
  focus = 'focused',
  success = 'success',
}

type Props = {
  className?: string
  disabled?: boolean
  displayError?: boolean
  label?: string
  mandatory?: boolean
  name: string
  placeholder?: string
  scale?: 'small' | 'medium' | 'large'
  width?: string
}

// TODO: fazer testes
const DatePicker = ({
  disabled,
  displayError = true,
  name,
  placeholder,
  scale = 'medium',
  ...restWrapperProps
}: Props) => {
  const inputRef = useRef<MaskedInput>(null)
  const divRef = useRef<HTMLDivElement>(null)
  const theme = useContext(ThemeContext)

  const [isCalendarOpen, setCalendarOpen] = useState(false)
  const [fieldState, setFieldState] = useState(EFieldStates.default)
  const [focused, setFocused] = useState(false)

  const { control } = useFormContext()
  const {
    field: { onBlur, onChange, value },
    fieldState: { isDirty, error },
  } = useController({ control, name, defaultValue: '' })

  const maskProps = maskCollection.date
  const maskedValue = useMemo(
    () =>
      maskProps.mask && value
        ? conformToMask(value, maskProps.mask).conformedValue
        : value,
    [maskProps.mask, value],
  )

  const handleBlur = () => {
    onBlur()
    setFocused(false)
  }

  const handleFocus = () => {
    setFocused(true)
    setFieldState(EFieldStates.focus)
  }

  const handleDateSelect = (date: Date | null) => {
    if (date) {
      onChange(date.toLocaleString('pt-br', { dateStyle: 'short' }))
      setCalendarOpen(false)
    }
  }

  useEffect(() => {
    if (error) {
      inputRef.current?.inputElement.focus()
    }
  }, [error])

  useEffect(() => {
    switch (true) {
      case !!error:
        setFieldState(EFieldStates.error)
        break
      case focused:
        setFieldState(EFieldStates.focus)
        break
      case isDirty:
        setFieldState(EFieldStates.success)
        break
      default:
        setFieldState(EFieldStates.default)
        break
    }
  }, [disabled, error, focused, isDirty])

  return (
    <Popover.Root open={isCalendarOpen} onOpenChange={setCalendarOpen}>
      <InputWrapper
        {...restWrapperProps}
        color={fieldState}
        disabled={disabled}
        displayError={displayError}
        error={error?.message}
        name={name}
        size={scale}
      >
        <Popover.Anchor asChild ref={divRef}>
          <div
            aria-disabled={disabled}
            className={cn(inputContainerStyles({ color: fieldState }))}
          >
            <MaskedInput
              {...maskProps}
              disabled={disabled}
              id={name}
              ref={inputRef}
              name={name}
              onBlur={handleBlur}
              onChange={onChange}
              onFocus={handleFocus}
              placeholder={placeholder}
              value={maskedValue}
              render={(ref, props) => (
                <input
                  {...props}
                  data-testid="input"
                  inputMode="numeric"
                  ref={ref as (instance: HTMLInputElement | null) => void}
                  className={cn(inputStyles({ scale }), {
                    'placeholder:text-text-disabled': disabled,
                  })}
                />
              )}
            />
            <Popover.PopoverTrigger
              data-testid="icon"
              className={cn(
                'absolute bottom-1/2 right-1 flex h-10 translate-y-1/2 cursor-pointer items-center',
                'w-10 justify-center border-none bg-transparent',
                { 'cursor-not-allowed': disabled },
              )}
            >
              <Icon
                iconName="calendar"
                color={
                  disabled
                    ? theme.colors.icon.disabled
                    : theme.colors.icon.default
                }
                size={EIconSizes[scale]}
              />
            </Popover.PopoverTrigger>
          </div>
        </Popover.Anchor>
      </InputWrapper>
      <Popover.Portal container={divRef.current}>
        <Popover.Content
          forceMount
          align="end"
          sideOffset={3}
          className={cn(
            'shadow-[rgba(23,24,24,0.05)_-1px_0px_20px,_rgba(0,0,0,0.15)_0px_1px_5px]',
            'date-picker-animation',
          )}
        >
          <Calendar
            inputDate={formatToDateObject(maskedValue)}
            setInputDate={(date) => handleDateSelect(date as Date)}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export default DatePicker
