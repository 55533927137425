// Models
import { IBaseState } from 'lib/redux/models'
import { IExerciseData } from 'storage/exercise/models'
import { IModelInput, TWorkoutSetType } from 'models'
import {
  IWorkoutListModelResponse,
  IWorWorkoutLogResponse,
} from 'services/workoutModel/@types'
import { IWorkoutRoutine } from 'storage/workoutRoutine/models'

export enum EWorkoutModelActionTypes {
  CLEANUP = 'WORKOUT_MODEL/CLEANUP',
  FAILURE = 'WORKOUT_MODEL/FAILURE',
  FULFILL = 'WORKOUT_MODEL/FULFILL',
  REQUEST = 'WORKOUT_MODEL/REQUEST',
  SUCCESS = 'WORKOUT_MODEL/SUCCESS',
  CLEAR_MODELS = 'WORKOUT_MODEL/CLEAR_MODELS',
  CREATE_WORKOUT_MODEL = 'WORKOUT_MODEL/CREATE',
  CREATE_WORKOUT_MODEL_TEMPLATE = 'WORKOUT_MODEL/CREATE_TEMPLATE',
  DELETE_WORKOUT_MODEL = 'WORKOUT_MODEL/DELETE',
  GET_WORKOUT_MODEL = 'WORKOUT_MODEL/GET',
  LIST_WORKOUT_MODEL_TEMPLATE = 'WORKOUT_MODEL/LIST_TEMPLATE',
  LIST_WORKOUT_MODEL = 'WORKOUT_MODEL/LIST',
  UPDATE_WORKOUT_MODEL = 'WORKOUT_MODEL/UPDATE',
  REMOVE_FROM_STATE = 'WORKOUT_MODEL/REMOVE_FROM_STATE',
  REMOVE_TEMPLATE_FROM_STATE = 'WORKOUT_MODEL/REMOVE_TEMPLATE_FROM_STATE',
  GET_WORKOUT_LOG = 'WORKOUT_MODEL/GET_WORKOUT_LOG',
  SEND_ROUTINE_TO_ATHLETE = 'WORKOUT_MODEL/SEND_ROUTINE_TO_ATHLETE',
  GET_MORE_WORKOUT_LOG = 'WORKOUT_MODEL/GET_MORE_WORKOUT_LOG',
  SUCCESS_LOAD_MORE_LOG = 'WORKOUT_MODEL/SUCCESS_LOAD_MORE_LOG',
}

export interface IWorkoutModelState extends IBaseState {
  models?: IWorkoutListModelResponse
  modelSuccess?: boolean
  workoutModelSuccess?: boolean
  workoutModels?: IModelInput[]
  workoutModelsTemplates?: IWorkoutListModelResponse
  workoutLog?: IWorWorkoutLogResponse
}

export interface IWorkoutModelData {
  id: string
  last_execution_datetime?: string
  name: string
  workout_routine: number
  workout_set?: IWorkoutSetData[]
  personal_profile?: number
  date?: string
  is_being_used?: boolean
  creation_date?: string
}

export interface IWorkoutSetData {
  id: string
  comments?: string
  exercises: IWorkoutSetToExerciseData[]
  interval: number
  name?: string
  quantity: number
  type: TWorkoutSetType
}

export interface IWorkoutSetToExerciseData {
  cadence: number | number[]
  exercise: IExerciseData
  isometric_time?: number | number[]
  repetitions: string
  weight: number | number[]
  comments?: string
  speed?: number | number[]
  time?: number | number[]
}

export interface IWorkoutComplete extends IWorkoutRoutine {
  workout_sets: IWorkoutSetData[]
}
