// Models
import { IAction } from 'lib/redux/models'
import { IWorkoutModelData } from '../models'
import {
  IWorkoutListModelPayload,
  IWorkoutListModelResponse,
  IWorkoutCreateModelPayload,
  IWorkoutGetModelPayload,
  IWorkoutUpdateModelPayload,
  IWorkoutDeleteModelPayload,
  IWorkoutCreateModelTemplatePayload,
  IGetWorkoutLogPayload,
  IWorWorkoutLogResponse,
  ITriggerSendToAthletePayload,
} from 'services/workoutModel/@types'
import { SagaIterator } from '@redux-saga/types'

// Libraries
import { all, call, put, takeLatest } from 'redux-saga/effects'

// Misc
import {
  createModel,
  createWorkoutModel,
  deleteWorkoutModel,
  getListModel,
  getWorkoutLog,
  getWorkoutModel,
  listWorkoutModel,
  sendRoutineToAthlete,
  updateWorkoutModel,
} from 'services/workoutModel'
import {
  failure,
  fulfill,
  removeModelFromState,
  removeModelTemplateFromState,
  request,
  success,
  successLoadMoreLog,
  Types,
} from '../duck'
import { prepareModelsToDisplay } from 'filters/workouts'
import { triggerToastError, triggerToastSuccess } from 'storage/general/duck'

export function* listWorkoutModelSaga(
  action: IAction<IWorkoutListModelPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: IWorkoutListModelResponse = yield call(
        listWorkoutModel,
        payload,
      )

      if (response) {
        yield put(success({ models: response }))
        yield put(
          success({
            workoutModels: prepareModelsToDisplay(response.results),
          }),
        )

        successCallback?.(response)
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* listWorkoutModelTemplateSaga(
  action: IAction<IWorkoutListModelPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IWorkoutListModelResponse = yield call(
        getListModel,
        action.payload,
      )

      if (response) {
        yield put(success({ workoutModelsTemplates: response }))
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* createWorkoutModelTemplateSaga(
  action: IAction<IWorkoutCreateModelTemplatePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: IWorkoutModelData = yield call(createModel, payload)

      if (response) {
        yield put(success({ modelSuccess: true }))
        successCallback?.(response)
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* createWorkoutModelSaga(
  action: IAction<IWorkoutCreateModelPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: IWorkoutModelData = yield call(
        createWorkoutModel,
        payload,
      )

      if (response) {
        yield put(success({ workoutModelSuccess: true }))
        successCallback?.(response)
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* getWorkoutModelSaga(action: IAction<IWorkoutGetModelPayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: IWorkoutModelData = yield call(getWorkoutModel, payload)

      if (response) {
        yield put(success({ workoutModelSuccess: true }))
        successCallback?.(response)
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* getWorkoutLogSaga(action: IAction<IGetWorkoutLogPayload>) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: IWorWorkoutLogResponse = yield call(
        getWorkoutLog,
        payload,
      )

      if (response) {
        yield put(success({ workoutLog: response }))
        successCallback?.(response)
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* loadMoreWorkoutLogSaga(
  action: IAction<IGetWorkoutLogPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const response: IWorWorkoutLogResponse = yield call(
        getWorkoutLog,
        action.payload,
      )
      yield put(successLoadMoreLog(response))
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* updateWorkoutModelSaga(
  action: IAction<IWorkoutUpdateModelPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      const response: IWorkoutModelData = yield call(
        updateWorkoutModel,
        payload,
      )

      if (response) {
        yield put(success({ workoutModelSuccess: true }))
        successCallback?.(response)
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* deleteWorkoutModelSaga(
  action: IAction<IWorkoutDeleteModelPayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { model_pk, isATemplate } = action.payload

      yield call(deleteWorkoutModel, action.payload)

      if (isATemplate) {
        yield put(removeModelTemplateFromState({ model_pk }))
      } else {
        yield put(removeModelFromState({ model_pk }))
      }
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export function* sendRoutineToAthleteSaga(
  action: IAction<ITriggerSendToAthletePayload>,
) {
  yield put(request())
  try {
    if (action.payload) {
      const { successCallback, ...payload } = action.payload
      yield call(sendRoutineToAthlete, payload)

      yield put(
        triggerToastSuccess({ message: 'A rotina foi enviada com sucesso.' }),
      )
      successCallback?.()
    }
  } catch (error: unknown) {
    const err = error as Error
    yield put(triggerToastError({ message: err.message }))
    yield put(failure(err.message))
  } finally {
    yield put(fulfill())
  }
}

export default function* workoutModelSaga(): SagaIterator {
  yield all([
    takeLatest(Types.CREATE_WORKOUT_MODEL, createWorkoutModelSaga),
    takeLatest(
      Types.CREATE_WORKOUT_MODEL_TEMPLATE,
      createWorkoutModelTemplateSaga,
    ),
    takeLatest(Types.GET_WORKOUT_MODEL, getWorkoutModelSaga),
    takeLatest(Types.GET_WORKOUT_LOG, getWorkoutLogSaga),
    takeLatest(Types.LIST_WORKOUT_MODEL, listWorkoutModelSaga),
    takeLatest(Types.DELETE_WORKOUT_MODEL, deleteWorkoutModelSaga),
    takeLatest(Types.UPDATE_WORKOUT_MODEL, updateWorkoutModelSaga),
    takeLatest(Types.LIST_WORKOUT_MODEL_TEMPLATE, listWorkoutModelTemplateSaga),
    takeLatest(Types.SEND_ROUTINE_TO_ATHLETE, sendRoutineToAthleteSaga),
    takeLatest(Types.GET_MORE_WORKOUT_LOG, loadMoreWorkoutLogSaga),
  ])
}
