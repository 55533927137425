// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import { Skeleton } from 'heeds-ds'
import { Cell, Row } from './desktop/styled'
import {
  Container,
  TitlesContainer,
  Title,
  Creator,
  OptionsContainer,
} from './mobile/styled'

const LoaderLibraryExerciseItem: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  return isDesktop ? (
    <Row>
      <Cell>
        <Skeleton className="h-10 w-10" />
      </Cell>
      <Cell>
        <Skeleton className="h-4 w-60" />
      </Cell>
      <Cell>
        <Skeleton className="h-4 w-60" />
      </Cell>
      <Cell>
        <Skeleton className="h-4 w-44" />
      </Cell>
      <Cell>
        <Skeleton className="h-4 w-36" />
      </Cell>
      <Cell>
        <Skeleton className="h-4 w-40" />
      </Cell>
      <Cell>
        <Skeleton className="h-9 w-9" />
      </Cell>
    </Row>
  ) : (
    <Container>
      <Skeleton className="h-[126px] w-[112px]" />
      <TitlesContainer>
        <Title>
          <Skeleton className="h-4 w-[90px]" />
        </Title>
        <Creator>
          <Skeleton className="h-4 w-20" />
        </Creator>
        <Creator>
          <Skeleton className="h-4 w-[60px]" />
        </Creator>
        <Creator>
          <Skeleton className="h-4 w-48" />
        </Creator>
        <Creator>
          <Skeleton className="h-4 w-[90px]" />
        </Creator>
      </TitlesContainer>
      <OptionsContainer>
        <Skeleton className="h-9 w-9" />
      </OptionsContainer>
    </Container>
  )
}

export default LoaderLibraryExerciseItem
