// React
import { FC } from 'react'

// Misc
import { cn } from '../../utils/classes'

type Props = {
  color?: string
  size?: 'xsmall' | 'small' | 'medium' | 'large'
}

const LoadingSpinner: FC<Props> = ({ color, size }) => {
  return (
    <div className="mx-6 my-[2px] grid items-center justify-center">
      <div
        className={cn(
          'h-6 rounded-[50%] border-[3px] border-background',
          'animation-spin-infinite w-6',
          {
            'h-4 w-4 border-[2px]': size === 'xsmall',
            'h-14 w-14 border-[7px]': size === 'medium',
            'h-32 w-32 border-[10px]': size === 'large',
          },
          color,
          'border-t-transparent',
        )}
      />
    </div>
  )
}

export default LoadingSpinner
