// Models
import { EFirebaseProviderOptions, IAuthState } from 'storage/auth/models'
import IStore from 'lib/redux/models'

// React
import { FC, ReactNode, useContext, useLayoutEffect } from 'react'

// Libraries
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Misc
import { urls } from 'routes/paths'
import { useMediaQuery, useMenu } from 'hooks'

// Components
import { Container, Content } from './styled'
import { Subtitle } from 'heeds-ds'
import { TabNavigator } from 'components'
import { ThemeContext } from 'styled-components'

type Props = {
  children?: ReactNode | ReactNode[]
}

const STEPS = [
  { label: 'DADOS GERAIS', to: urls.profileGeneral },
  { label: 'SENHA', to: urls.profilePassword },
]

const ProfileLayout: FC<Props> = ({ children }) => {
  const { userData } = useSelector<IStore, IAuthState>((state) => state.auth)
  const { setPagename } = useMenu()
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  const filteredSteps = STEPS.filter((step) => {
    if (userData?.provider === EFirebaseProviderOptions.EMAIL) return true
    return step.label !== 'SENHA'
  })

  useLayoutEffect(() => {
    setPagename('PERFIL')

    return () => setPagename('DASHBOARD')
  }, [setPagename])

  return (
    <Container>
      {isDesktop && (
        <Subtitle type="sh1" weight={700}>
          Meu Perfil
        </Subtitle>
      )}
      <Content>
        <TabNavigator items={filteredSteps} className="xl:px-6 xl:pt-4" />
        {children || <Outlet />}
      </Content>
    </Container>
  )
}

export default ProfileLayout
