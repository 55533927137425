// React
import { FC, useContext, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { cn } from 'utils/helpers/classess'

// Components
import { Icon } from 'heeds-ds'

export interface Tab {
  label: string
  to: string
}

interface Props {
  avoidNextNavigation?: boolean
  blockNavigation?: boolean
  blockedIndexes?: number[]
  className?: string
  itemClassName?: string
  items: Tab[]
  progress?: boolean
}

const TabNavigator: FC<Props> = ({
  avoidNextNavigation,
  blockNavigation,
  blockedIndexes,
  className,
  itemClassName,
  items,
  progress,
}) => {
  const { colors } = useContext(ThemeContext)
  const { pathname, state = {} } = useLocation()
  const navigate = useNavigate()
  const container = useRef<HTMLDivElement>(null)

  const [scrollAtRight, setScrollAtRight] = useState(false)

  const handleScroll = () => {
    const hiddenBlur =
      container?.current &&
      container.current.scrollLeft + container.current.offsetWidth ===
        container.current.scrollWidth

    if (hiddenBlur) {
      setScrollAtRight(true)
    } else {
      setScrollAtRight(false)
    }
  }

  useEffect(() => {
    setScrollAtRight(
      (container?.current &&
        window.innerWidth > container.current?.scrollWidth) ||
        false,
    )
  }, [])

  useEffect(() => {
    const containerRef = container.current

    containerRef?.addEventListener('scroll', handleScroll)
    return () => {
      containerRef?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const currentIndex = items.findIndex((item) => item.to === pathname)

  const handleNavigation = (index: number) => {
    if (blockedIndexes) {
      return blockedIndexes.includes(index)
    }
    if (avoidNextNavigation) {
      return index > currentIndex
    }
    if (blockNavigation) {
      return index !== currentIndex
    }
    return false
  }

  return (
    <div
      ref={container}
      className={cn(
        'no-scrollbar relative flex gap-10 overflow-x-auto px-6',
        'lg:w-full lg:gap-6 lg:pb-8 lg:pl-16 lg:pr-8 lg:pt-10',
        'after:fixed after:right-0 after:h-10 after:w-[93px] after:content-[""]',
        'after:bg-gradient-to-r after:from-transparent after:to-white after:to-60%',
        {
          'lg:gap-0 gap-0': progress ? true : undefined,
          'after:hidden': scrollAtRight,
        },
        className,
      )}
    >
      {items.map((item, index) => {
        const fill = progress && index < currentIndex ? true : undefined
        return (
          <Link
            aria-current={index === currentIndex ? 'page' : false}
            aria-disabled={handleNavigation(index)}
            key={item.label}
            onClick={() => navigate(item.to)}
            replace
            state={state}
            to={item.to}
            className={cn(
              'relative flex flex-1 select-none items-center gap-2 whitespace-nowrap',
              'p-2 pl-0 text-copy3 font-bold leading-5 text-text-subdued no-underline',
              'after:absolute after:bottom-0 after:left-0 after:h-1 after:w-full',
              'after:rounded-1 after:bg-surface-neutralDefault after:content-[""]',
              'lg:p-0 lg:pb-4 lg:text-copy4 lg:leading-4',
              'aria-disabled:pointer-events-none aria-[current="page"]:text-text-secondary',
              'aria-[current="page"]:after:bg-action-secondaryDefault',
              {
                'text-text-tertiary after:bg-action-secondaryDefault after:w-[105%] last-of-type:after:w-full':
                  fill,
              },
              itemClassName,
            )}
          >
            {blockedIndexes && blockedIndexes.includes(index) ? (
              <Icon iconName="lock" size={16} color={colors.icon.disabled} />
            ) : (
              <div className="min-w-[16px]" />
            )}
            {item.label}
          </Link>
        )
      })}
    </div>
  )
}

export default TabNavigator
