// Libs
import { cva } from 'class-variance-authority'

export const inputContainerStyles = cva(
  [
    'relative flex rounded-[3px] border transition-all duration-300 ease-in-out',
    'aria-disabled:border-icon-disabled',
  ],
  {
    variants: {
      color: {
        critical: 'text-text-critical',
        default: 'text-border-input',
        focused: 'text-text',
        success: 'text-text-success',
      },
    },
    defaultVariants: {
      color: 'default',
    },
  },
)

export const textareaStyles = cva(
  [
    'bg-transparent resize-none border-none caret-text text-text cursor-text font-normal px-4',
    'w-full outline-none transition-all duration-300 ease-linear placeholder:text-text-subdued',
    'disabled:cursor-not-allowed  disabled:bg-surface-disabled disabled:text-text-subdued',
  ],
  {
    variants: {
      scale: {
        small: 'text-copy4 py-3 h-10',
        medium: 'text-copy3 py-[15px] h-[47px]',
        large: 'text-copy2 py-[18px] h-[54px]',
      },
    },
    defaultVariants: {
      scale: 'medium',
    },
  },
)
