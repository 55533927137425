// Models
import { IAnamneseAnswer } from 'storage/assessmentAnamneseAnswer/models'
import { IAnamneseTemplate } from 'storage/assessmentAnamneseTemplate/models'

// React
import { FC, Fragment } from 'react'

// Misc
import { cn } from 'utils/helpers/classess'

// Components
import { Body, Skeleton, Subtitle, Title } from 'heeds-ds'
import QuestionItem from 'components/QuestionItem'

type Props = {
  anamnese?: IAnamneseAnswer | IAnamneseTemplate
  loading?: boolean
}

const AnamnesePreview: FC<Props> = ({ anamnese, loading }) => {
  if (loading || !anamnese) {
    return (
      <div
        className={cn(
          'w-full rounded-t-4 bg-surface px-8 pb-10 shadow-[0px_0px_15px_0px_rgba(43,42,99,0.16)]',
          'xl:mx-auto xl:my-0 xl:max-w-7xl xl:gap-6 xl:rounded-2 xl:px-6',
        )}
      >
        <div className="flex w-full flex-col gap-2 py-8">
          <Skeleton className="h-10 w-4/5" />
          <Skeleton className="h-5 w-3/5" />
        </div>

        <Skeleton className="h-[400px] w-full" />
        <Skeleton className="mt-[60px] h-[200px] w-full" />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'w-full rounded-t-4 bg-surface px-8 pb-10 shadow-[0px_0px_15px_0px_rgba(43,42,99,0.16)]',
        'xl:mx-auto xl:my-0 xl:max-w-7xl xl:gap-6 xl:rounded-2 xl:px-6',
      )}
    >
      <div className="flex w-full flex-col gap-2 py-8">
        <Title type="h3" weight={700}>
          {anamnese.title}
        </Title>
        {anamnese.description && (
          <Body type="copy3">{anamnese.description}</Body>
        )}
      </div>
      {anamnese.sections.map((section, index) => (
        <Fragment key={index}>
          {(section.name || section.description) && (
            <div className="flex w-full flex-col gap-2 py-8">
              <Subtitle type="sh2">{section.name}</Subtitle>
              {section.description && (
                <Body type="copy3">{section.description}</Body>
              )}
            </div>
          )}

          {section.questions?.map((question) => {
            return <QuestionItem key={question.question} {...question} />
          })}
        </Fragment>
      ))}
    </div>
  )
}

export default AnamnesePreview
