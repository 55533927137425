// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import { Skeleton } from 'heeds-ds'

const AthleteProfileLoader: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.desktop}px)`)

  return (
    <div className="mx-6 mb-7 md:ml-10 md:mr-12 md:mt-10">
      {isDesktop ? (
        <div>
          <div className="mb-1 flex justify-between">
            <Skeleton className="h-10 w-[91px] rounded-2" />
            <Skeleton className="h-10 w-[122px] rounded-2" />
          </div>
          <div className="flex w-full gap-4">
            <div className="flex w-[34%] flex-col gap-2">
              <Skeleton className="h-40 w-full rounded-2" />
              <Skeleton className="h-80 w-full rounded-2" />
              <Skeleton className="h-16 w-full rounded-2" />
              <Skeleton className="h-[171px] w-full rounded-2" />
            </div>
            <div className="flex w-[67%] flex-col gap-2">
              <Skeleton className="h-[260px] w-full rounded-2" />
              <Skeleton className="h-[360px] w-full rounded-2" />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full flex-col gap-4">
          <Skeleton className="h-[50px] w-full rounded-2" />
          <Skeleton className="h-[50px] w-full rounded-2" />
          <Skeleton className="h-[50px] w-full rounded-2" />
          <Skeleton className="h-[50px] w-full rounded-2" />
          <Skeleton className="h-[50px] w-full rounded-2" />
          <Skeleton className="h-80 w-full rounded-2" />
        </div>
      )}
    </div>
  )
}

export default AthleteProfileLoader
