// Models
import { IAnamneseAnswer } from 'storage/assessmentAnamneseAnswer/models'
import { IGenericOption } from 'models'

// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Custom
import * as Styled from './styled'
import { Aligner, Body, Icon, Swipe } from 'heeds-ds'
import { StatusContainer, StatusText } from '../styled'

export type Props = {
  anamneseAnswer: IAnamneseAnswer
  onClick: () => void
  onSwipeClick: () => void
}

const Mobile: FC<Props> = ({
  anamneseAnswer: { title, answer_date, personal },
  onClick,
  onSwipeClick,
}) => {
  const theme = useContext(ThemeContext)

  const isAnswered = !!answer_date

  const swipeOptions: IGenericOption[] = [
    {
      icon: 'moreVertical',
      color: theme.colors.interactive.default,
      onClick: onSwipeClick,
    },
  ]

  return (
    <Swipe actions={swipeOptions} active className="py-2">
      <Styled.Container onClick={onClick} data-testid="card">
        <Aligner flex="column" gap="0.8rem">
          <Styled.Title>{title}</Styled.Title>

          <Body type="copy3" color={theme.colors.text.subdued}>
            {`Criado ${personal !== 0 ? 'por você' : ' pelo sistema'}`}
          </Body>
        </Aligner>

        <StatusContainer answered={isAnswered}>
          <StatusText answered={isAnswered}>
            {isAnswered ? 'Respondido' : 'Não respondido'}
          </StatusText>

          {isAnswered && (
            <Icon
              iconName="circleCheck"
              color={theme.colors.badge.greenText}
              size={16}
            />
          )}
        </StatusContainer>
      </Styled.Container>
    </Swipe>
  )
}

export default Mobile
