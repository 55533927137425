// React
import { FC, useContext } from 'react'

import { ThemeContext } from 'styled-components'

import { useMediaQuery } from 'heeds-ds/src/hooks'

// Components
import { Skeleton } from 'heeds-ds'
import { Cell, Row } from './desktop/styled'
import { Container, OptionsContainer, TitlesContainer } from './mobile/styled'

const Loader: FC = () => {
  const theme = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.tablet}px)`)

  return isDesktop ? (
    <Row>
      <Cell>
        <Skeleton className="h-4 w-[240px]" />
      </Cell>
      <Cell>
        <Skeleton className="h-4 w-60" />
      </Cell>
      <Cell>
        <Skeleton className="h-4 w-40" />
      </Cell>
      <Cell>
        <Skeleton className="h-9 w-9" />
      </Cell>
    </Row>
  ) : (
    <Container>
      <TitlesContainer>
        <Skeleton className="h-[21px] w-72" />
        <Skeleton className="h-[19px] w-[60px]" />
      </TitlesContainer>
      <OptionsContainer>
        <Skeleton className="h-9 w-9" />
      </OptionsContainer>
    </Container>
  )
}

export default Loader
