// React
import { FC, useContext } from 'react'

// Libraries
import { ThemeContext } from 'styled-components'

// Misc
import { useMediaQuery } from 'hooks'

// Components
import { Skeleton } from 'heeds-ds'

const FinancialLoader: FC = () => {
  const { breakpoints } = useContext(ThemeContext)
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.tablet}px)`)

  return isDesktop ? (
    <div className="relative flex flex-col rounded-2 border border-border-input bg-surface p-4">
      <div className="mb-3 flex justify-between">
        <Skeleton className="h-[19px] w-[85px] rounded-1" />
        <Skeleton className="h-8 w-8 rounded-2" />
      </div>
      <div className="flex flex-col gap-[22px]">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <Skeleton className="h-5 w-5 rounded-full" />
            <Skeleton className="h-[38px] w-40 rounded-1" />
          </div>
          <div className="flex flex-col">
            <Skeleton className="h-4 w-32 rounded-1" />
            <Skeleton className="h-4 w-[125px] rounded-1" />
          </div>
        </div>
        <div className="flex gap-1">
          <Skeleton className="h-8 w-40 rounded-4" />
          <Skeleton className="h-8 w-40 rounded-4" />
          <Skeleton className="h-8 w-40 rounded-4" />
        </div>
      </div>
    </div>
  ) : (
    <div className="relative flex flex-col rounded-2 border border-border-input bg-surface p-4">
      <div className="flex items-center justify-between">
        <Skeleton className="h-[19px] w-[85px] rounded-1" />
        <Skeleton className="h-6 w-6 rounded-2" />
      </div>
    </div>
  )
}

export default FinancialLoader
