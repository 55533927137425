// Models
import { IAnamneseSection } from 'models/anamnese'
import {
  IAnamneseTemplate,
  IAnamneseTemplateState,
} from 'storage/assessmentAnamneseTemplate/models'
import { IAuthState } from 'storage/auth/models'
import { ICreateAnamneseTemplatePayload } from 'services/assessmentAnamneseTemplate/@types'
import { TBaseBlockStatus } from 'components/form/BaseBlock'
import { TFormBlockType } from 'models'
import IStore from 'lib/redux/models'

// React
import { FC, useCallback, useContext, useLayoutEffect, useState } from 'react'

// Libraries
import {
  FieldArrayWithId,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

// Misc
import { anamneseNewTemplateSchema } from 'schemas'
import { buttonClickTracking } from 'utils/tracking'
import {
  prepareAnamneseTemplateToDisplay,
  prepareAnamneseTemplateToPayload,
} from 'filters/assessment'
import {
  triggerCreateAnamneseTemplate,
  triggerGetAnamneseTemplate,
  triggerUpdateAnamneseTemplate,
} from 'storage/assessmentAnamneseTemplate/duck'
import {
  triggerCreateAnamneseAnswers,
  cleanup,
  triggerLoadAnamneseAnswers,
} from 'storage/assessmentAnamneseAnswer/duck'
import { useMediaQuery, useMenu, useModal } from 'hooks'
import { triggerToastSuccess } from 'storage/general/duck'

// Components
import * as Block from 'blocks/dashboard/library/anamnese'
import * as Form from 'components/form'
import * as Styled from './styled'
import { Button, DragAndDrop, Icon, Loading } from 'heeds-ds'
import { ModalConfirmation, ModalDelete, ModalNewQuestion } from 'components'

// Constants
const EMPTY_FORM: IFormInputs = {
  parts: [
    { type: 'title', title: '' },
    { type: 'section', name: '' },
  ],
}

const QUESTIONS_WITH_ALLOWED_VALUES = [
  'multiple_choice',
  'dropdown_list',
  'select_box',
]

const DEFAULT_OPTIONS = ['Opção 1', 'Opção 2']

const DEFAULT_ALLOWED: Record<TFormBlockType | string, string[]> = {
  multiple_choice: DEFAULT_OPTIONS,
  select_box: DEFAULT_OPTIONS,
  dropdown_list: DEFAULT_OPTIONS,
}

interface IFormBlock {
  allowed_values?: string[]
  description?: string
  question?: string
  name?: string
  required?: boolean
  title?: string
  type: TFormBlockType
}

interface IFormInputs {
  parts: IFormBlock[]
}

const LibraryAnamneseCreateTemplate: FC = () => {
  const { id = '', athlete_id = '' } = useParams()
  const { userData } = useSelector<IStore, IAuthState>((state) => state.auth)
  const { anamneseTemplate, loading } = useSelector<
    IStore,
    IAnamneseTemplateState
  >(({ assessmentAnamneseTemplate }) => assessmentAnamneseTemplate)
  const { breakpoints, colors } = useContext(ThemeContext)
  const { closeModal, openModal, isVisible } = useModal()
  const { setPagename } = useMenu()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop}px)`)

  const [selectedSection, setSelectedSection] = useState('')

  const methods = useForm<IFormInputs>({
    defaultValues: EMPTY_FORM,
    resolver: yupResolver(anamneseNewTemplateSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const { control, handleSubmit, setValue, watch } = methods

  const { append, fields, move, remove } = useFieldArray({
    control,
    name: 'parts',
  })

  const handleAddQuestion = (type: TFormBlockType) => {
    const commonProps = { question: '', required: false, type }

    if (QUESTIONS_WITH_ALLOWED_VALUES.includes(type)) {
      append({ ...commonProps, allowed_values: DEFAULT_ALLOWED[type] })
    } else {
      append({ ...commonProps })
    }

    closeModal()
  }

  const handleDuplicate = useCallback(
    (fieldId: string) => {
      const fieldIndex = fields.findIndex((field) => field.id === fieldId)

      append({ ...watch('parts')?.[fieldIndex] })
    },
    [append, fields, watch],
  )

  const handleAddSection = () => append({ type: 'section', name: '' })

  const handleRemove = useCallback(
    (id: string) => {
      const fieldIndex = fields.findIndex((field) => field.id === id)

      remove(fieldIndex)
    },
    [fields, remove],
  )

  const openTypeQuestionModal = () => openModal('question-type-modal')

  const onSubmit = (formData: IFormInputs) => {
    if (userData?.profileId) {
      const formatedData = prepareAnamneseTemplateToPayload(formData)

      const successCallback = (anamneseResponse?: IAnamneseTemplate) => {
        if (anamneseResponse?.id && athlete_id) {
          navigate(-1)
          dispatch(
            triggerCreateAnamneseAnswers({
              athlete_id: Number(athlete_id),
              anamnesis_template_ids: [anamneseResponse?.id],
              successCallback: () => {
                const query = {
                  athlete_pk: Number(athlete_id),
                  page_size: 20,
                }
                dispatch(triggerLoadAnamneseAnswers(query))
              },
            }),
          )
          dispatch(
            triggerToastSuccess({
              message:
                'Seu aluno será notificado que existe uma nova anamnese para responder',
            }),
          )
          dispatch(cleanup())
          return
        }
        navigate(-1)
        dispatch(
          triggerToastSuccess({
            message: 'Seu formulário foi salvo em "Modelos de Anamnese"',
          }),
        )
      }

      const payload: ICreateAnamneseTemplatePayload = {
        ...formatedData,
        title: formatedData?.title || '',
        sections: (formatedData?.sections as IAnamneseSection[]) || [],
        personal: userData.profileId,
        successCallback,
      }

      if (id && anamneseTemplate && anamneseTemplate.personal !== 0) {
        dispatch(triggerUpdateAnamneseTemplate({ id, ...payload }))
      } else {
        dispatch(triggerCreateAnamneseTemplate(payload))
      }
    }
  }

  const renderBlock = useCallback(
    (
      field: FieldArrayWithId<IFormInputs, 'parts', 'id'>,
      ref: React.RefObject<HTMLDivElement>,
      index: number,
      isDragging: boolean,
    ) => {
      const props = {
        dragRef: ref,
        key: field.id,
        name: `parts.${index}`,
        onDuplicate: () => handleDuplicate(field.id),
        onRemove:
          field.type === 'section'
            ? () => {
                setSelectedSection(field.id)
                openModal('delete-modal')
              }
            : () => handleRemove(field.id),
        status: (isDragging ? 'drag' : 'default') as TBaseBlockStatus,
      }

      const blocks: Record<TFormBlockType, JSX.Element> = {
        date: <Form.DateBlock {...props} />,
        dropdown_list: <Form.DropdownListBlock {...props} />,
        measures: <Form.MeasurementBlock {...props} />,
        multiple_choice: <Form.MultipleChoiceBlock {...props} />,
        section: (
          <Form.SectionBlock
            {...props}
            className={isDesktop ? 'mt-8' : 'm-0'}
          />
        ),
        select_box: <Form.SelectionBoxBlock {...props} />,
        text: <Form.TextBlock {...props} />,
        title: <Form.TitleBlock {...props} />,
      }

      return blocks[field.type]
    },
    [handleDuplicate, handleRemove, isDesktop, openModal],
  )

  useLayoutEffect(() => {
    if (id) {
      const successCallback = (anamneseTemplate: IAnamneseTemplate) => {
        const formatedData = prepareAnamneseTemplateToDisplay(anamneseTemplate)
        setValue('parts', formatedData)
      }
      dispatch(triggerGetAnamneseTemplate({ id, successCallback }))
    }
  }, [dispatch, id, setValue])

  useLayoutEffect(() => {
    setPagename('NOVO MODELO')

    return () => {
      dispatch(cleanup())
      setPagename('MODELOS DE ANAMNESE')
    }
  }, [dispatch, setPagename])

  if (loading) return <Loading active />

  return (
    <FormProvider {...methods}>
      <Styled.FormScrollArea>
        <Styled.FormContainer onSubmit={handleSubmit(onSubmit)}>
          {isDesktop && (
            <Styled.Header>
              <Button
                onClick={() => navigate(-1)}
                size="small"
                track={buttonClickTracking}
                trackName="go_back_to_anamnese_models"
                variation="borderless"
              >
                <Icon
                  color={colors.interactive.default}
                  iconName="chevronLeft"
                />
                Voltar para modelos de anamneses
              </Button>

              <Button
                onClick={() => openModal('confirmation-modal')}
                size="small"
                track={buttonClickTracking}
                trackName=""
              >
                Concluir
              </Button>
            </Styled.Header>
          )}

          <Styled.FormBody>
            <DragAndDrop
              items={fields}
              moveItem={(dragIndex, hoverIndex) => {
                if (hoverIndex !== 0 && dragIndex !== 0)
                  move(dragIndex, hoverIndex)
              }}
              renderItem={renderBlock}
            />
          </Styled.FormBody>

          <Block.AnamneseBottomMenu
            addQuestion={openTypeQuestionModal}
            addSection={handleAddSection}
            onFinish={() => openModal('confirmation-modal')}
            onSelect={handleAddQuestion}
          />
        </Styled.FormContainer>
      </Styled.FormScrollArea>

      {isVisible === 'confirmation-modal' && (
        <ModalConfirmation
          confirmTitle="Concluir"
          description="Após concluir você sairá da área de criação e seu formulário estará salvo na pasta de modelos de anamnese."
          onCancel={() => closeModal()}
          onConfirm={handleSubmit(onSubmit)}
          title="Concluir modelo?"
          width="47rem"
        />
      )}

      {isVisible === 'delete-modal' && (
        <ModalDelete
          confirmTitle="Excluir seção"
          description="Você está excluindo uma seção, mas todas as perguntas que foram adicionadas não serão excluídas."
          onCancel={() => closeModal()}
          onConfirm={() => selectedSection && handleRemove(selectedSection)}
          title="Tem certeza que deseja excluir?"
        />
      )}

      {isVisible === 'question-type-modal' && (
        <ModalNewQuestion onCancel={closeModal} onSelect={handleAddQuestion} />
      )}
    </FormProvider>
  )
}

export default LibraryAnamneseCreateTemplate
